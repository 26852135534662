import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Container from "./Container";
import Paper from "./Paper";

export default class OnboardingConfirmation extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object
    };

    componentDidMount() {
        const {history} = this.props;
        const {comeBackToOnboarding, subpage} = this.props.location;

        if (comeBackToOnboarding) {
            setTimeout(() => this.comeBackToConsumerOnboarding(history, subpage), 8000);
        }
    }

    comeBackToConsumerOnboarding(history, subpage) {
        if (subpage) {
            history.push(`/${subpage}/onboarding`)
        } else {
            history.push(`/onboarding`)
        }
    }

    render() {
        const isEmailPresent = this.props.location.email;
        const isPhonePresent = this.props.location.phone;

        let contactData;
        let contactType;

        const emailAddress = <FormattedMessage id="emailAddress" defaultMessage="Email address"/>;
        const phoneNumber = <FormattedMessage id="phoneNumber" defaultMessage="Phone number"/>;
        const email = <FormattedMessage id="email" defaultMessage="Email"/>;
        const phone = <FormattedMessage id="phone" defaultMessage="Phone"/>;

        if (isEmailPresent && isPhonePresent) {
            contactData = (
                <Typography variant="body1">
                    {emailAddress}
                    &nbsp;
                    <FormattedMessage
                        id="and"
                        defaultMessage="and"/>
                    &nbsp;
                    {phoneNumber}
                </Typography>
            )
        } else if (!isPhonePresent) {
            contactData = emailAddress

        } else if (!isEmailPresent) {
            contactData = phoneNumber
        }

        if (isEmailPresent && isPhonePresent) {
            contactType = (
                <Typography variant="body1">
                    {email}
                    &nbsp;
                    <FormattedMessage
                        id="or"
                        defaultMessage="or"/>
                    &nbsp;
                    {phone}
                </Typography>
            )

        } else if (!isPhonePresent) {
            contactType = email
        } else if (!isEmailPresent) {
            contactType = phone
        }

        return (
            <Container>
                <Paper padding>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="thankYouForYourRegistration"
                            defaultMessage="Thanks for your registration!"/>
                    </Typography>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="confirmationLinkText"
                            defaultMessage="A message with an activation link have been sent to the {contactData} you provided."
                            values={{
                                contactData: contactData
                            }}/>
                    </Typography>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="pleaseCheckText"
                            defaultMessage="Please check {contactType} and click the link to complete your registration."
                            values={{
                                contactType: contactType
                            }}/>
                    </Typography>
                </Paper>
            </Container>
        );
    }

}
