import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";

import {getFormattedDate} from "../../../utils/date-utils";
import ImageCard from "../ImageCard";

const styles = theme => ({
    campaignsBanners: {
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLft: '0 !important'
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            minWidth: 320,
            maxWidth: 400
        }
    },
    campaignBanner: {
        marginTop: theme.spacing(1)
    }
});

class CampaignsBannersList extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chain: PropTypes.object,
        campaigns: PropTypes.array
    };

    renderCampaignsBanners(chain, campaigns) {
        const {classes} = this.props;
        const timestamp = new Date().getTime();
        return (
            <div className={classes.campaignsBanners}>
                {campaigns.map((campaign, index) => (
                    <div key={index} className={classes.campaignBanner}>
                        <ImageCard
                            url={"/c/" + campaign.id}
                            imagePath={campaign.bannerPath + '?' + timestamp}
                            bottomTitleText={campaign.name}
                            bottomSubtitleText={!campaign.welcomeOffer && (
                                <FormattedMessage
                                    id="validToDate"
                                    defaultMessage="Valid to {date}"
                                    values={{
                                        date: getFormattedDate(campaign.endDate)
                                    }}/>
                            )}
                            expandedText={campaign.description}/>
                    </div>
                ))}
            </div>
        )
    }

    render() {
        const {chain, campaigns} = this.props;
        if (chain && campaigns) {
            return (
                this.renderCampaignsBanners(chain, campaigns)
            )
        }
        return null
    }

}

export default withStyles(styles)(CampaignsBannersList)
