import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import Clipboard from "react-clipboard.js";
import {FormattedMessage} from "react-intl";
import ReactTooltip from 'react-tooltip';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem/";
import Typography from "@material-ui/core/Typography";
import Send from '@material-ui/icons/Send';
import FileCopy from '@material-ui/icons/FileCopy';

import Button from "../../../common/components/common/Button";
import RenderTextField from "../../../common/components/common/RenderTextField";
import RenderSelect from "../../../common/components/common/RenderSelect";
import PhoneInput from "../../../common/components/common/form/PhoneInput";

import {
    emailOrPhoneRequiredValidation,
    emailValidation,
    phoneValidation
} from "../../../common/utils/karming-validators";
import {required} from "../../../common/components/common/form/FormUtils";
import {getAppConsumerUrl} from "../../../common/config/karming-links";
import {ROLE_MERCHANT} from "../../../common/components/auth/roles";
import {COUNTRY_CODES} from "../../../common/utils/country-codes";

const validate = values => {
    return Object.assign(
        emailValidation({name: "email", value: values.email}),
        phoneValidation({name: "phone", value: values.phone}),
        emailOrPhoneRequiredValidation(
            {name: "authority", value: "ROLE_CONSUMER"},
            {name: "email", value: values.email},
            {name: "phone", value: values.phone}))
};

export const INVITE_MEMBER_FORM_NAME = 'inviteMemberForm';

const POINT_OF_SALE_ID_FIELD_NAME = 'pointOfSaleId';

const styles = {
    menuItem: {
        display: 'table'
    },
    linkWrapper: {
        display: 'flex'
    },
    textFieldLink: {
        width: '100%'
    }
};

class InviteMemberForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        smsAccount: PropTypes.object,
        chain: PropTypes.object,
        isSingleLocation: PropTypes.object,
        commercialChain: PropTypes.object,
        user: PropTypes.object,
        form: PropTypes.string,
        currentPoSId: PropTypes.string,
        currentPointOfSaleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    getActiveContactPeople(availablePointsOfSale, currentPointOfSaleId) {
        return availablePointsOfSale.find(c => c.id === currentPointOfSaleId);
    }

    render() {
        const {classes, chain, handleSubmit, submitting, commercialChain, form, smsAccount, currentPointOfSaleId, isSingleLocation, currentPoSId, user} = this.props;

        const availablePointsOfSale = commercialChain.pointsOfSale.filter(pointOfSale => user.authority === ROLE_MERCHANT || user.pointsOfSaleIds.includes(pointOfSale.id));
        const currentPointOfSale = currentPointOfSaleId ? this.getActiveContactPeople(availablePointsOfSale, currentPointOfSaleId) : undefined;

        const link = getAppConsumerUrl(commercialChain.subdomain) +
            ((currentPointOfSale && currentPointOfSale.subpage) ? "/" + currentPointOfSale.subpage : "") + "/guest";

        const disabledSmsInvitation = !smsAccount || !smsAccount.credits;
        const shouldShowPhoneInput = !!smsAccount;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">

                {!isSingleLocation && (
                    <div>
                        <Typography
                            variant="body1"
                            paragraph>
                            <FormattedMessage
                                id="chooseHomeLocationForMember"
                                defaultMessage="Choose a home location for member (optional)"/>
                        </Typography>

                        <Field
                            name={POINT_OF_SALE_ID_FIELD_NAME}
                            component={RenderSelect}
                            defaultValue={Number(currentPoSId)}
                            renderValue={() => currentPointOfSale && currentPointOfSale.commercialName}>
                            <MenuItem value="">
                                <i>
                                    <FormattedMessage
                                        id="noneDefault"
                                        defaultMessage="None (default)"/>
                                </i>
                            </MenuItem>

                            {availablePointsOfSale.map((pointOfSale, index) =>
                                <MenuItem
                                    key={index}
                                    value={pointOfSale.id}>
                                    <div className={classes.menuItem}>
                                        {pointOfSale.commercialName}
                                        <Typography variant="caption">
                                            {pointOfSale.address.street}, {pointOfSale.address.city}
                                        </Typography>
                                    </div>
                                </MenuItem>
                            )}

                        </Field>
                        {currentPointOfSale && (
                            <Typography variant="caption">
                                {currentPointOfSale.address.street}, {currentPointOfSale.address.city}
                            </Typography>
                        )}
                    </div>
                )}

                <Field
                    name="email"
                    hintText={<FormattedMessage
                        id="email"
                        defaultMessage="Email"/>}
                    component={RenderTextField}
                    validate={shouldShowPhoneInput ? undefined : required}/>

                {shouldShowPhoneInput && (
                    <span data-tip data-for='buyCreditsWarning'>
                        <PhoneInput
                            defaultCountryCode={chain && chain.language && COUNTRY_CODES[chain.language].code}
                            disabled={disabledSmsInvitation}
                            form={form}/>
                    </span>
                )}

                {disabledSmsInvitation && (
                    <ReactTooltip
                        id='buyCreditsWarning'
                        type='warning'>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="youHaveToBuyCreditsToUseSmsInvitation"
                                defaultMessage="You have to buy credits to use sms invitation"/>
                        </Typography>
                    </ReactTooltip>
                )}

                <Button
                    icon={<Send/>}
                    label={<FormattedMessage
                        id="invite"
                        defaultMessage="Invite"/>}
                    type="submit"
                    disabled={submitting}/>

                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="invitationLink"
                        defaultMessage="Invitation link"/>
                </Typography>

                <div className={classes.linkWrapper}>
                    <TextField
                        className={classes.textFieldLink}
                        id="ConsumerLink"
                        value={link}
                        rows={1}
                        rowsMax={1}/>

                    <Clipboard data-clipboard-text={link}
                               button-title={<FormattedMessage
                                   id="consumer-onboarding-form.copyLinkText"
                                   defaultMessage="Copy link and paste in your browser"/>}>
                        <FileCopy/>
                    </Clipboard>
                </div>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector(INVITE_MEMBER_FORM_NAME);
    const currentPointOfSaleId = selector(state, POINT_OF_SALE_ID_FIELD_NAME);
    return {
        currentPointOfSaleId
    }
}

export default reduxForm({
    form: INVITE_MEMBER_FORM_NAME,
    validate
})(connect(mapStateToProps, null)(withStyles(styles)(InviteMemberForm)))
