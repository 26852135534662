import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Spinner from "../../../common/components/common/Spinner";
import ConsumerUnsubscribeForm from "./ConsumerUnsubscribeForm";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";


import {fetchUserByUnsubscribeId, unsubscribeEmail, unsubscribeSms} from "../../actions/unsubscribe-actions";
import {fetchCommercialChainWithoutAuthBySubdomain} from "../../actions/kundklubb-public-actions";

class ConsumerUnsubscribe extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        app: PropTypes.object,
        chain: PropTypes.object,
        match: PropTypes.object,
        profile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        unsubscribeSms: PropTypes.func,
        unsubscribeEmail: PropTypes.func,
        fetchUserByUnsubscribeId: PropTypes.func,
        fetchCommercialChainWithoutAuthBySubdomain: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchUserByUnsubscribeId(this.props.match.params.id);
        this.props.fetchCommercialChainWithoutAuthBySubdomain();
    }

    render() {
        const {profile, chain, app, history} = this.props;

        let type;

        if (this.props.match.params.id.includes("s")) {
            type = 'sms';
        } else if (this.props.match.params.id.includes("e")) {
            type = 'email';
        }

        if (!profile || !chain || !type) {
            return <Spinner app={app}/>
        }

        return (
            <Container>
                <Paper padding>
                    <ConsumerUnsubscribeForm
                        onSubmit={this.handleSubmit.bind(this, type, profile.id, history)}
                        type={type}
                        contactData={type === "sms" ? profile.phone : profile.email}
                        chain={chain}
                        moveToService={this.moveToService.bind(this, history)}/>
                </Paper>
            </Container>
        );
    }

    handleSubmit(type, memberId, history) {
        if (type === 'sms') {
            this.props.unsubscribeSms(memberId, history)
        } else {
            this.props.unsubscribeEmail(memberId, history)
        }
    }

    moveToService(history) {
        history.push("/login")
    }

}

function mapStateToProps(state) {
    return {
        profile: state.user.profile,
        chain: state.company.commercialChain
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        unsubscribeSms,
        unsubscribeEmail,
        fetchUserByUnsubscribeId,
        fetchCommercialChainWithoutAuthBySubdomain,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerUnsubscribe);
