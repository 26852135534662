import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import ProfileEditorForm from "./ProfileEditorForm";
import Spinner from "./Spinner";
import Container from "./Container";
import Paper from "./Paper";

import {updateUser} from "../../actions/user-actions";
import {ROLE_CONSUMER} from "../auth/roles";

class Profile extends React.Component {

    static propTypes = {
        user: PropTypes.object,
        app: PropTypes.object,
        auth: PropTypes.object,
        updateUser: PropTypes.func
    };

    prepareFormBeforeSubmit(values) {
        if (values.address) {
            return ({
                ...values,
                'street': values.address.street,
                'city': values.address.city,
                'zipCode': values.address.zipCode
            })
        }
        return values
    }

    updateUser(values) {
        if (this.props.auth.role === ROLE_CONSUMER && values.address) {
            values = this.prepareFormBeforeSubmit(values)
        }
        this.props.updateUser(values);
    }

    render() {
        const {app, auth, user} = this.props;

        if (!user || !user.profile) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <ProfileEditorForm
                        enableReinitialize={true}
                        auth={auth}
                        user={user}
                        initialValues={user.profile}
                        app={app}
                        onSubmit={this.updateUser.bind(this)}>
                    </ProfileEditorForm>
                </Paper>
            </Container>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateUser}, dispatch);
}

export default connect(null, mapDispatchToProps)(Profile);
