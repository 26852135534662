import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import Spinner from "../../../common/components/common/Spinner";

import {getFormattedDate} from "../../../common/utils/date-utils";
import {fetchPurchase} from "../../../common/actions/purchase-actions";

const styles = theme => ({
    itemWrapper: {
        display: 'flex',
        textAlign: 'left'
    },
    itemTitle: {
        fontWeight: 'bold',
        marginRight: theme.spacing(1)
    }
});

class ConsumerPurchaseDetails extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        purchase: PropTypes.object,
        app: PropTypes.object,
        match: PropTypes.object,
        fetchPurchase: PropTypes.func
    };

    componentDidMount() {
        const {fetchPurchase, match: {params: {id}}} = this.props;
        fetchPurchase(id);
    }

    render() {
        const {classes, purchase} = this.props;

        if (!purchase) {
            return <Spinner app={this.props.app}/>
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography
                        paragraph
                        variant="h6">
                        <FormattedMessage
                            id="purchaseDetails"
                            defaultMessage="Purchase details"/>
                    </Typography>

                    <div className={classes.itemWrapper}>
                        <div className={classes.itemTitle}>
                            <FormattedMessage
                                id="categories"
                                defaultMessage="Categories"/>
                            :
                        </div>
                        {this.renderCategories()}
                    </div>

                    <div className={classes.itemWrapper}>
                        <div className={classes.itemTitle}>
                            <FormattedMessage
                                id="campaigns"
                                defaultMessage="Campaigns"/>
                            :
                        </div>
                        {this.renderCampaigns()}
                    </div>

                    <div className={classes.itemWrapper}>
                        <div className={classes.itemTitle}>
                            <FormattedMessage
                                id="totalAmountPaid"
                                defaultMessage="Total amount paid"/>
                            :
                        </div>
                        {purchase.totalAmount
                            ? purchase.totalAmount : ""}
                    </div>

                    <div className={classes.itemWrapper}>
                        <div className={classes.itemTitle}>
                            <FormattedMessage
                                id="dateOfPurchase"
                                defaultMessage="Date of purchase"/>
                            :
                        </div>
                        {getFormattedDate(purchase.dateOfPurchase)}
                    </div>
                </Paper>
            </Container>
        );
    }

    renderCategories() {
        const {purchase: {categories}} = this.props;
        return (
            categories.map((purchase, i) => (
                <span key={i}>{i > 0 && ", "}{purchase.name}</span>
            ))
        )
    }

    renderCampaigns() {
        const {purchase: {campaigns}} = this.props;
        return (
            campaigns.map((purchase, i) => (
                <span key={i}>{i > 0 && ", "}{purchase.name}</span>
            ))
        )
    }

}

function mapStateToProps(state) {
    return {
        purchase: state.purchase.purchase
    };
}

function mapDispatchToProps(dispatch) {
    return (bindActionCreators({fetchPurchase}, dispatch))
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsumerPurchaseDetails));
