import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import {addMonthsToDate, getFormattedDate} from "../../../utils/date-utils";
import {renderStamps, STANDARD_STAMPCARD} from "./StampcardUtils";

const styles = theme => ({
    stampcardWrapper: {
        textAlign: 'center',
        "& > div": {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLft: '0 !important'
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            minWidth: 320,
            maxWidth: 400
        }
    },
    stampsWrapper: {
        position: 'relative'
    },
    stampBanner: {
        marginTop: theme.spacing(1),
    }
});

class StampcardBannersList extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        stampcardUsage: PropTypes.array,
        stampcards: PropTypes.array
    };

    renderStampcardBanners(stampcards) {
        const {classes, stampcardUsage} = this.props;
        const timestamp = new Date().getTime();

        return (
            <div className={classes.stampcardWrapper}>
                {stampcards.map((stampcard, index) => {
                        const usage = stampcardUsage && stampcardUsage
                            .find(option => option.stampcardId === stampcard.id);
                        const collectedStamps = usage && usage.collectedStamps
                            ? usage.collectedStamps
                            : 0;
                        if (collectedStamps < stampcard.stampsAmount) {
                            return (
                                <div key={index} className={classes.stampBanner}>
                                    <Card>
                                        {/*TODO link to stampcard page*/}
                                        {/*<Link to={"/s/" + stampcard.id}>*/}
                                        <div className={classes.stampsWrapper}>
                                            {renderStamps(
                                                stampcard.stampsAmount,
                                                stampcard.title,
                                                stampcard.subtitle,
                                                stampcard.stampPath,
                                                stampcard.stampUncollectedPath,
                                                STANDARD_STAMPCARD,
                                                collectedStamps,
                                                stampcard.hideTitle,
                                                stampcard.hideSubtitle,
                                                stampcard.titleColor,
                                                stampcard.titleSize,
                                                stampcard.subtitleColor,
                                                stampcard.subtitleSize
                                            )}

                                            <CardMedia
                                                component="img"
                                                image={stampcard.imagePath + '?' + timestamp}/>
                                        </div>
                                        {/*</Link>*/}

                                        <CardContent>
                                            <Typography variant="subtitle1">
                                                {usage && usage.startDate && (
                                                    <FormattedMessage
                                                        id="validToDate"
                                                        defaultMessage="Valid to {date}"
                                                        values={{
                                                            date: getFormattedDate(addMonthsToDate(usage.startDate,
                                                                stampcard.monthValidation))
                                                        }}/>
                                                )}
                                                {(!usage || !usage.startDate) && (
                                                    <FormattedMessage
                                                        id="stampCardWillBeValidFor"
                                                        defaultMessage={`Stamp card will be valid for ` +
                                                        `{stampcardValidationDate} months since collecting first stamp`}
                                                        values={{
                                                            stampcardValidationDate: stampcard.monthValidation
                                                        }}/>
                                                )}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            )
                        }
                    }
                )}
            </div>
        )
    }


    render() {
        const {stampcards} = this.props;
        if (stampcards) {
            return (
                this.renderStampcardBanners(stampcards)
            )
        }
        return <div/>
    }
}

export default withStyles(styles)(StampcardBannersList)
