export const HOME_REDIRECT_URL = '/';
export const LOGIN_REDIRECT_URL = '/login';

export const ONBOARDING_URL='/onboarding';
export const ONBOARDING_CONFIRMATION_URL='/onboarding/confirmation';
export const MERCHANTS_URL = '/merchants';
export const COMPANIES_URL = '/companies';
export const CATEGORIES_URL = '/categories';
export const CONTACT_URL = '/contact';
export const LOCATIONS_URL = '/locations';
export const USERS_URL = '/users';
export const SUPPORT_URL = '/support';
export const SMS_URL = '/sms';
export const PURCHASES_URL = '/purchases';
export const MEMBERS_URL = '/members';
export const ATTRACT_URL = '/attract';
export const CAMPAIGNS_URL = '/campaigns';
export const POINTS_URL = '/points';
export const STAMPCARDS_URL = '/stampcards';
export const SENDOUTS_URL = '/sendouts';

export const CONSUMER_PURCHASES_URL = '/consumer/purchases';
export const CONSUMER_PURCHASES_HISTORY_URL = '/consumer/purchases/history';
export const CONSUMER_CONTACT_URL = '/consumer/contact';
export const CONSUMER_ACCOUNT_URL = '/consumer/account';
export const CONSUMER_COMPLETE_INFORMATION_URL = '/consumer/complete';
export const CONSUMER_MEMBERSHIP_URL = '/consumer/membership/';
export const CONSUMER_MEMBERSHIP_TERMINATE_URL = '/consumer/membership/terminate';
export const CONSUMER_MEMBERSHIP_EXPORT_URL = '/consumer/membership/export';

export const USER_PROFILE_URL = '/user/profile';

export const TERMS_MEMBERSHIP_URL = '/terms/membership';
export const TERMS_PRIVACY_URL = '/terms/privacy';

export const CHAIN_MEMBERSHIP_TERMS_URL = '/chain/membership/terms';
export const CHAIN_MEMBERSHIP_PRIVACY_URL = '/chain/membership/privacy';

export const DATA_PROTECTION_INTRO_URL = '/dataprotection/intro';
export const DATA_PROTECTION_RECORD_LIST_URL = '/dataprotection/recordlist';
export const DATA_PROTECTION_USEFUL_LINKS_URL = '/dataprotection/usefullinks';
export const DATA_PROTECTION_DPA_URL = '/dataprotection/dpa';

export const ACCOUNT_INCIDENT_REPORT_URL = '/account/incidentreport';

export const BILLING_DETAILS_URL = '/billings/details';
