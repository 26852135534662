import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from "@material-ui/core/IconButton/IconButton";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
        flexWrap: 'inherit'
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        flexWrap: 'inherit'
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
        flexWrap: 'inherit'
    },
    warning: {
        backgroundColor: amber[700],
        flexWrap: 'inherit'
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
});

function MySnackbarContent(props) {
    const {classes, className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
    anchorOriginBottomCenter: {
        [theme.breakpoints.up("md")]: {
            margin: '0 0 0 115px !important'
        }
    },
});

class AppMessageBar extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        auth: PropTypes.object,
        status: PropTypes.object,
    };

    state = {
        open: false,
        message: undefined,
        isError: undefined,
    };

    handleClose = () => {
        this.setState({
            open: false,
            message: undefined
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {status} = nextProps;
        if (status.message && (!this.state.message && !this.props.status.message)) {
            this.setState({open: true});
            this.setState({message: status.message});
            this.setState({isError: status.isError});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.open !== nextState.open;
    }

    render() {
        const {auth, classes} = this.props;
        const {open, message, isError} = this.state;

        return (
            <Snackbar
                classes={{anchorOriginBottomCenter: auth.authenticated && classes.anchorOriginBottomCenter}}
                autoHideDuration={6000}
                open={open}
                onClose={this.handleClose.bind(this)}>
                <MySnackbarContentWrapper
                    onClose={this.handleClose.bind(this)}
                    variant={isError ? "error" : "success"}
                    message={message}/>
            </Snackbar>
        );
    }

}

export default withStyles(styles2)(AppMessageBar);
