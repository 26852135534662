import React from "react";
import browserDetect from "browser-detect";
import PropTypes from "prop-types";

import RenderSafariMessage from "./RenderSafariMessage";
import Popup from "./Popup";
import RenderSamsungMessage from "./RenderSamsungMessage";

class AddToHomeScreenPopup extends React.Component {

    static propTypes = {
        language: PropTypes.string
    };

    render() {
        const {language} = this.props;
        const result = browserDetect();
        const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
        const isSafariBrowser = result.name === "safari" && result.os.includes("OS X");
        const isInStandaloneMode = navigator.standalone;

        if (result.mobile && !isInStandaloneMode) {
            if (isSafariBrowser) {
                return (
                    <Popup
                        message={<RenderSafariMessage language={language}/>}
                        hasRepeatCheckbox
                        variant="pointing"
                        position="bottom"
                        indicatedPlace="middle"/>
                )
            } else if (isSamsungBrowser) {
                return (
                    <Popup
                        message={<RenderSamsungMessage/>}
                        hasRepeatCheckbox
                        variant="default"
                        position="bottom"/>
                )
            }
            return null
        }
        return null
    }

}

export default AddToHomeScreenPopup;
