import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";

import Spinner from "../Spinner";
import Button from "../Button";
import Container from "../Container";
import ImageCard from "../ImageCard";

const styles = theme => ({
    couponWrapper: {
        [theme.breakpoints.up('sm')]: {
            margin: '0 auto'
        }
    }
});

class CouponPage extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        coupon: PropTypes.object,
        app: PropTypes.object,
        auth: PropTypes.object,
        singlePage: PropTypes.bool
    };

    UNSAFE_componentWillMount() {
        if (this.props.coupon.imagePath) {
            window.URL.revokeObjectURL(this.props.coupon.imagePath)
        }
    }

    componentWillUnmount() {
        if (this.props.coupon.imagePath) {
            window.URL.revokeObjectURL(this.props.coupon.imagePath)
        }
    }

    render() {
        const {classes, app, coupon, auth, singlePage} = this.props;

        if (!coupon || !coupon.name) {
            return <Spinner app={app}/>
        }

        const timestamp = new Date().getTime();
        const bannerPath = coupon.image && coupon.image.newData
            ? window.URL.createObjectURL(coupon.image.newData)
            : coupon.imagePath + '?' + timestamp;

        return (
            <Container size={Container.SIZE_TINY}>
                <div className={classes.couponWrapper}>
                    <ImageCard
                        // url={"/r/" + coupon.id}
                        imagePath={bannerPath}
                        topText={
                            <React.Fragment>
                                <FormattedMessage
                                    id="tradeForPointsAmount"
                                    defaultMessage="Trade for {pointsAmount} points"
                                    values={{
                                        pointsAmount: coupon.pointsRequired
                                    }}/>
                            </React.Fragment>
                        }
                        bottomTitleText={coupon.name}
                        expandedText={coupon.description}/>

                    {(singlePage && (!auth || !auth.authenticated)) && (
                        <Button
                            label={<FormattedMessage
                                id="login"
                                defaultMessage="Login"/>}
                            onClick={this.moveToLogin.bind(this)}/>
                    )}
                </div>
            </Container>
        );
    }

}

export default withStyles(styles)(CouponPage)
