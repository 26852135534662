import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/icons/Menu";
import Add from "@material-ui/icons/Add";

const styles = theme => ({
    menuIcon: {
        verticalAlign: 'middle',
        fontSize: 30,
        fill: theme.palette.grey.main,
        padding: theme.spacing(0.5)
    },
    addIcon: {
        verticalAlign: "middle",
        fontSize: 30,
        fill: theme.palette.grey.main
    },
    content: {
        fontWeight: "bold"
    }
});

const RenderSafariMessage = ({classes}) => (
    <React.Fragment>
        <Typography
            variant="body1"
            color='textPrimary'
            align="left">
            <FormattedMessage
                id="addToHomeScreenTitle"
                defaultMessage="Add to apps on your phone!"/>
        </Typography>
        <Typography
            variant="body1"
            color='textPrimary'
            className={classes.content}
            align="left">
            <FormattedMessage
                id="addToHomeScreenSamsungContent"
                defaultMessage="Open menu {menuIcon} and then select {addIcon}"
                values={{
                    menuIcon: <Menu className={classes.menuIcon}/>,
                    addIcon: <Add className={classes.addIcon}/>
                }}/>
        </Typography>
    </React.Fragment>
);

RenderSafariMessage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RenderSafariMessage)
