import React from "react";
import {Route} from "react-router-dom";

import AuthRequiredRoute from "../../common/components/auth/AuthRequiredRoute";
import ConsumerPurchases from "./purchase/ConsumerPurchases";
import ConsumerPurchaseDetails from "./purchase/ConsumerPurchaseDetails";
import ConsumerCommercialChain from "./ConsumerCommercialChain";
import ConsumerAccount from "./account/ConsumerAccount";
import MembershipManagement from "./account/membership/MembershipManagement";
import ConsumerExportData from "./account/membership/ConsumerExportData";
import OnboardingConfirmation from "../../common/components/common/OnboardingConfirmation";
import ConsumerOnboardingVerification from "./onboarding/ConsumerOnboardingVerification";
import ConsumerOnboarding from "./onboarding/ConsumerOnboarding";
import ConsumerCompleteInformation from "./onboarding/ConsumerCompleteInformation";
import Profile from "../../common/components/common/Profile";
import ConsumerTerminate from "./account/membership/ConsumerTerminate";
import ConsumerUnsubscribe from "./account/ConsumerUnsubscribe";
import Subpage from "./Subpage";
import StampcardCouponContainer from "../../common/components/common/stampcard/StampcardCouponContainer";
import PurchaseHistory from "./purchase/ConsumerPurchaseHistory";

import {
    CONSUMER_ACCOUNT_URL,
    CONSUMER_COMPLETE_INFORMATION_URL,
    CONSUMER_CONTACT_URL,
    CONSUMER_MEMBERSHIP_EXPORT_URL,
    CONSUMER_MEMBERSHIP_TERMINATE_URL,
    CONSUMER_MEMBERSHIP_URL,
    CONSUMER_PURCHASES_HISTORY_URL,
    CONSUMER_PURCHASES_URL,
    ONBOARDING_CONFIRMATION_URL,
    ONBOARDING_URL,
    USER_PROFILE_URL
} from "../../../static/utils/redirect-const";

const ConsumerRoutes = (props) => {
    const {auth, app, user} = props;

    return [
        <Route
            key='1'
            exact path={ONBOARDING_URL}
            app={app}
            component={ConsumerOnboarding}/>,

        <Route
            key='15'
            exact path="/:subpage/onboarding"
            component={ConsumerOnboarding}/>,

        <Route
            key='2'
            exact path={ONBOARDING_CONFIRMATION_URL}
            component={OnboardingConfirmation}/>,

        <Route
            key='3'
            exact path="/onboarding/confirmation/:id"
            app={app}
            auth={auth}
            component={ConsumerOnboardingVerification}/>,

        <Route
            key='16'
            exact path="/:subpage/onboarding/confirmation/:id"
            app={app}
            auth={auth}
            component={ConsumerOnboardingVerification}/>,

        <Route
            key='4'
            exact path="/u/:id"
            app={app}
            component={ConsumerUnsubscribe}/>,

        <AuthRequiredRoute
            key='12'
            exact path={CONSUMER_COMPLETE_INFORMATION_URL}
            auth={auth}
            app={app}
            user={user}
            component={ConsumerCompleteInformation}/>,

        <AuthRequiredRoute
            key='5'
            exact path={CONSUMER_PURCHASES_URL}
            auth={auth}
            app={app}
            user={user}
            component={ConsumerPurchases}/>,

        <AuthRequiredRoute
            key='5'
            exact path={CONSUMER_PURCHASES_HISTORY_URL}
            auth={auth}
            app={app}
            user={user}
            component={PurchaseHistory}/>,

        <AuthRequiredRoute
            key='6'
            exact path="/consumer/purchases/:id"
            auth={auth}
            app={app}
            component={ConsumerPurchaseDetails}/>,

        <AuthRequiredRoute
            key='7'
            exact path={CONSUMER_CONTACT_URL}
            auth={auth}
            app={app}
            user={user}
            component={ConsumerCommercialChain}/>,

        <AuthRequiredRoute
            key='8'
            exact path={CONSUMER_ACCOUNT_URL}
            auth={auth}
            user={user}
            component={ConsumerAccount}/>,

        <AuthRequiredRoute
            key='9'
            exact path={CONSUMER_MEMBERSHIP_URL}
            auth={auth}
            component={MembershipManagement}/>,

        <AuthRequiredRoute
            key='10'
            exact path={CONSUMER_MEMBERSHIP_TERMINATE_URL}
            auth={auth}
            component={ConsumerTerminate}/>,

        <AuthRequiredRoute
            key='11'
            exact path={CONSUMER_MEMBERSHIP_EXPORT_URL}
            auth={auth}
            component={ConsumerExportData}/>,

        <AuthRequiredRoute
            key='13'
            exact path={USER_PROFILE_URL}
            auth={auth}
            app={app}
            user={user}
            component={Profile}/>,

        <AuthRequiredRoute
            key='14'
            exact path="/:subpage"
            auth={auth}
            app={app}
            user={user}
            component={Subpage}/>,

        <AuthRequiredRoute
            key='15'
            exact path="/sc/:stampcardCouponId"
            auth={auth}
            app={app}
            user={user}
            component={StampcardCouponContainer}/>
    ]
};

export default ConsumerRoutes
