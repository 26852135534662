import React from "react";

import {makeStyles} from "@material-ui/styles";
import MoreHoriz from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles({
    root: {
        verticalAlign: 'middle'
    }
});

function MoreIcon(props) {
    const classes = useStyles();
    return <MoreHoriz className={classes.root} {...props}/>
}

export default MoreIcon
