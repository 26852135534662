import {ROLE_CONSUMER} from "../components/auth/roles";
import {required} from "../components/common/form/FormUtils";
import {isBefore} from "date-fns";
import React from "react";
import {FormattedMessage} from "react-intl";

const passwordError = 'At least 6 characters';

const confirmPasswordError = 'Password does not match';

const emailError = <FormattedMessage id="invalidEmailAddress" defaultMessage="Invalid email address"/>;

const phoneError = <FormattedMessage id="invalidPhoneNumber" defaultMessage="Invalid phone number"/>;

const smsSenderNameError = '3-11 characters (only a-z, A-Z, 0-9, no spaces)';

const subdomainError = 'Invalid web address. Allow Characters , numbers , -. First letter should be character. No spaces';

const subpageError = 'Invalid web address. Allow Characters , numbers , -. First letter should be character. No spaces';

const endDateError = "End date has to be later than start date";

const companyNumberError = 'Invalid company number';

export const smsMessageEmptyError = 'SMS message cannot be empty';

export const smsMessageToLongError = 'Sms message cannot be longer that 155 characters';

export const actionButtonLabelTooLongError = 'Action button label cannot be longer that 30 characters';

export const categoryOrCampaignNameTooLongError = 'Name cannot be longer that 32 characters';

export const emailStringTooShortError = 'Provide minimum 5 characters';

export const nameStringTooShortError = 'Provide minimum 3 characters';

export const biggerThanZeroError = 'Provide amount bigger than 0';

const rowTooLong2rows = (lengthValidation) => {
    return (
        `En rad kan inte vara mer än ${lengthValidation} tecken. Tryck ”Enter” för att skapa en ny rad. (Max två rader.)`
    )
};
const rowTooLong3rows = (lengthValidation) => {
    return (
        `En rad kan inte vara mer än ${lengthValidation} tecken. Tryck ”Enter” för att skapa en ny rad. (Max tre rader.)`
    )
};

const textTooLong = (lengthValidation) => {
    return (
        `Texten får inte vara längre än ${lengthValidation} tecken.`
    )
};

export const minBiggerThanMaxError = <FormattedMessage
    id="errors.minCannotBeBiggerThanMax"
    defaultMessage={`Min cannot be bigger than max`}/>;

export const maxLessThanMinError = <FormattedMessage
    id="errors.maxCannotBeLessThanMin"
    defaultMessage={`Max cannot be less than min`}/>;

export const maxBiggerThanMaxError = <FormattedMessage
    id="errors.maxCannotBeBiggerThanDefaultValue"
    defaultMessage={`Max cannot be bigger than default value`}/>;

export const minLessThanMinError = <FormattedMessage
    id="errors.minCannotBeLessThanDefaultValue"
    defaultMessage={`'Min cannot be less than default value`}/>;

export const urlError = 'Invalid URL';

const errorEmailOrPhoneRequired = 'Email or phone required';

const atLeastOneOptionIsRequired = "At least one option is required";

export const passwordValidation = (password, confirmPassword) => {
    const errors = {};

    if (password.value && password.value.length < 6) {
        errors[password.name] = passwordError
    }
    if (password.value !== confirmPassword.value) {
        errors[confirmPassword.name] = confirmPasswordError
    }
    return errors
};

export const phoneOrEmailValidation = (phoneOrEmail) => {
    const errors = {};
    if (phoneOrEmail.value
        && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(phoneOrEmail.value)
        && !/^[0]?[0-9]{9}$/i.test(phoneOrEmail.value)) {
        errors[phoneOrEmail.name] = "Invalid phone or email";
    }
    return errors
};

export const emailValidation = (email) => {
    const errors = {};
    if (email.value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.value)) {
        errors[email.name] = emailError
    }
    return errors
};

export const phoneValidation = (phone) => {
    const errors = {};
    if (phone.value && !/^[0]?[0-9]{9}$/i.test(phone.value)) {
        errors[phone.name] = phoneError
    }

    return errors
};

export const stampcardTitleValidation = (title, lengthValidation, maxRow) => {
    const errors = {};

    if (title.value && title.value.length > 0) {
        const wholeTitle = title.value.split("\n");
        const firstRow = wholeTitle[0];
        const secondRow = wholeTitle[1];
        const thirdRow = wholeTitle[2];
        if ((firstRow && firstRow.length > lengthValidation) || (secondRow && secondRow.length > lengthValidation) || (thirdRow && thirdRow.length > lengthValidation)) {
            errors[title.name] = maxRow === 2 ? rowTooLong2rows(lengthValidation) : rowTooLong3rows(lengthValidation);
        }
    }

    return errors
};

export const locationPhoneValidation = (phone) => {
    const errors = {};
    if (phone.value) {
        if (phone.value.charAt(0) === "0" ? !/^[0]?[0-9]{7,11}$/i.test(phone.value) : !/^[1-9][0-9]{4,7}$/i.test(phone.value)) {
            errors[phone.name] = phoneError
        }
        return errors;
    }
};

export const smsSenderNameValidation = (smsSenderName) => {
    const errors = {};
    if (smsSenderName.value && !/^[A-Za-z0-9]{3,11}$/i.test(smsSenderName.value)) {
        errors[smsSenderName.name] = smsSenderNameError
    }

    return errors
};

//TODO
export const emailOrPhoneRequiredValidation = (authority, email, phone) => {
    const errors = {};

    if (authority.value !== ROLE_CONSUMER && !email.value) {
        errors[email.name] = required();
    }

    if (authority.value === ROLE_CONSUMER && !email.value && !phone.value) {
        errors[email.name] = errorEmailOrPhoneRequired;
        errors[phone.name] = errorEmailOrPhoneRequired;
    }

    return errors
};

export const URLValidation = (url) => {
    const errors = {};
    if (url.value && !/[-a-zA-Z0-9@:%_.~#?&/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi.test(url.value)) {
        errors[url.name] = urlError
    }

    return errors
};

export const subdomainValidation = (subdomain) => {
    const errors = {};
    if (subdomain.value && !/^[a-zA-Z]+[a-zA-Z\d]*$/i.test(subdomain.value)) {
        errors[subdomain.name] = subdomainError
    }

    return errors
};

export const categoryOrCampaignNameValidation = (name) => {
    const errors = {};

    if (name.value && name.value.length > 32) {
        errors[name.name] = categoryOrCampaignNameTooLongError
    }

    return errors
};

export const textLengthValidation = (name, lengthValidation) => {
    const errors = {};

    if (name.value && name.value.length > lengthValidation) {
        errors[name.name] = textTooLong(lengthValidation)
    }

    return errors
};

export const emailStringValidation = (emailString) => {
    const errors = {};

    if (emailString.value && emailString.value.length < 5) {
        errors[emailString.name] = emailStringTooShortError
    }

    return errors
};

export const nameStringValidation = (name) => {
    const errors = {};

    if (name.value && name.value.length < 3) {
        errors[name.name] = nameStringTooShortError
    }

    return errors
};

export const biggerThanZero = (number) => {
    const errors = {};

    if (number.value && number.value < 1) {
        errors[number.name] = biggerThanZeroError
    }

    return errors
};

export const actionButtonLabelValidation = (actionButtonLabel, actionButtonTarget) => {
    const errors = {};

    if (actionButtonLabel.value && actionButtonLabel.value.length > 24) {
        errors[actionButtonLabel.name] = actionButtonLabelTooLongError
    } else if (actionButtonTarget.value && !actionButtonLabel.value) {
        errors[actionButtonLabel.name] = required();
    }

    return errors
};

export const subpageValidation = (subpage) => {
    const errors = {};
    if (subpage.value && !/^[a-zA-Z]+[a-zA-Z\d]*$/i.test(subpage.value)) {
        errors[subpage.name] = subpageError
    }

    return errors
};

export const dateRangeValidation = (startDate, endDate) => {
    const errors = {};
    if (startDate.value && endDate.value && isBefore(endDate.value, startDate.value)) {
        errors[endDate.name] = endDateError
    }

    return errors
};

export const dateRangeNestedValidation = (startDate, endDate, objectName) => {
    const errors = {};

    if (startDate.value && endDate.value && isBefore(endDate.value, startDate.value)) {
        errors[objectName] = {[endDate.name]: endDateError}
    }

    return errors
};

export const atLeastOneOfTwoCheckboxRequired = (oneOption, secondOption, objectName) => {
    const errors = {};

    if (!oneOption.value && !secondOption.value) {
        errors[objectName] = {
            [oneOption.name]: atLeastOneOptionIsRequired,
            [secondOption.name]: atLeastOneOptionIsRequired
        }
    }

    return errors
};

export const atLeastOneOfThreeCheckboxRequired = (oneOption, secondOption, thirdOption, objectName) => {
    const errors = {};

    if (!oneOption.value && !secondOption.value && !thirdOption.value) {
        errors[objectName] = {
            [oneOption.name]: atLeastOneOptionIsRequired,
            [secondOption.name]: atLeastOneOptionIsRequired,
            [thirdOption.name]: atLeastOneOptionIsRequired
        }
    }

    return errors
};

export const minMaxFieldsValidation = (objectName, firstOption, firstOptionMin, firstOptionMax, secondOption, secondOptionMin, secondOptionMax,
                                       thirdOption, thirdOptionMin, thirdOptionMax) => {
    const errors = {};

    let firstOptionMinError;
    let firstOptionMaxError;
    let secondOptionMinError;
    let secondOptionMaxError;
    let thirdOptionMinError;
    let thirdOptionMaxError;

    if (firstOptionMin !== undefined && firstOption) {
        firstOptionMinError = (firstOptionMin.value !== null && Number(firstOptionMin.value) < Number(firstOptionMin.min))
            ? minLessThanMinError : Number(firstOptionMin.value) > Number(firstOptionMax.value) ? minBiggerThanMaxError : undefined;

        firstOptionMaxError = (firstOptionMax.value !== null && Number(firstOptionMax.value) > Number(firstOptionMax.max))
            ? maxBiggerThanMaxError : Number(firstOptionMin.value) > Number(firstOptionMax.value) ? maxLessThanMinError : undefined;
    }

    if (secondOptionMin !== undefined && secondOption) {
        secondOptionMinError = (secondOptionMax.value !== null && Number(secondOptionMin.value) < Number(secondOptionMin.min))
            ? minLessThanMinError : Number(secondOptionMin.value) > Number(secondOptionMax.value) ? minBiggerThanMaxError : undefined;

        secondOptionMaxError = (secondOptionMax.value !== null && Number(secondOptionMax.value) > Number(secondOptionMax.max))
            ? maxBiggerThanMaxError : Number(secondOptionMin.value) > Number(secondOptionMax.value) ? maxLessThanMinError : undefined;
    }

    if (thirdOptionMin !== undefined && thirdOption) {
        thirdOptionMinError = (thirdOptionMin.value !== null && Number(thirdOptionMin.value) < Number(thirdOptionMin.min))
            ? minLessThanMinError : Number(thirdOptionMin.value) > Number(thirdOptionMax.value) ? minBiggerThanMaxError : undefined;

        thirdOptionMaxError = (thirdOptionMax.value !== null && Number(thirdOptionMax.value) > Number(thirdOptionMax.max))
            ? maxBiggerThanMaxError : Number(thirdOptionMin.value) > Number(thirdOptionMax.value) ? maxLessThanMinError : undefined;
    }

    errors[objectName] = {
        [firstOptionMin ? firstOptionMin.name : '']: firstOptionMinError,

        [secondOptionMin ? secondOptionMin.name : '']: secondOptionMinError,

        [thirdOptionMin ? thirdOptionMin.name : '']: thirdOptionMinError,

        [firstOptionMax ? firstOptionMax.name : '']: firstOptionMaxError,

        [secondOptionMax ? secondOptionMax.name : '']: secondOptionMaxError,

        [thirdOptionMax ? thirdOptionMax.name : '']: thirdOptionMaxError
    };

    return errors
};

export const companyNumberValidation = (companyNumber) => {
    const errors = {};
    if (!/^[0-9]+[0-9\-?]+[0-9]+$/i.test(companyNumber.value) || companyNumber.value.length !== 10) {
        errors[companyNumber.name] = companyNumberError
    }

    return errors
};

export const campaignMediumsSelectionAndMessageValidation = (email, sms, smsMessage) => {
    const errors = {};
    if (!sms.value && !email.value) {
        errors[sms.name] = 'At least one medium required!';
        errors[email.name] = 'At least one medium required!'
    }

    if (sms.value && (!smsMessage.value || (smsMessage.value && smsMessage.value.length < 1))) {
        errors[smsMessage.name] = smsMessageEmptyError
    }

    if (smsMessage.value.length > 155) {
        errors[smsMessage.name] = smsMessageToLongError
    }

    return errors
};

export const privacyPolicyValidation = (privacyPolicy) => {
    const errors = {};
    if (!privacyPolicy.value) {
        errors[privacyPolicy.name] = 'Privacy Policy is required to register'
    }

    return errors
};

export const termsOfServiceValidation = (termsOfService) => {
    const errors = {};
    if (!termsOfService.value) {
        errors[termsOfService.name] = 'You have to accept Terms Of service'
    }

    return errors
};

export const dataProcessingAgreementValidation = (dataProcessingAgreement) => {
    const errors = {};
    if (!dataProcessingAgreement.value) {
        errors[dataProcessingAgreement.name] = 'You have to accept Data Processing Agreement'
    }

    return errors
};

export const termsOfMembershipValidation = (termsOfMembership) => {
    const errors = {};
    if (!termsOfMembership.value) {
        errors[termsOfMembership.name] = 'Terms of the Membership are required to register'
    }

    return errors
};
