import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import Clear from '@material-ui/icons/Clear';

import {ROLE_CONSUMER} from "../auth/roles";

import Button from "../common/Button";
import RenderDatePicker from "./form/RenderDatePicker";
import Checkbox from "./form/RenderCheckbox";
import RenderTextField from "../common/RenderTextField";
import PhoneInput from "./form/PhoneInput";
import Dialog from "./Dialog";
import RenderRadioButtonGroup from "./form/RenderRadioButtonGroup";

import {emailOrPhoneRequiredValidation, emailValidation, phoneValidation} from "../../utils/karming-validators";
import {COUNTRY_CODES} from "../../utils/country-codes";

const validate = values => {
    return Object.assign(
        emailValidation({name: "email", value: values.email}),
        phoneValidation({name: "phone", value: values.phone}),
        emailOrPhoneRequiredValidation(
            {name: "authority", value: values.authority},
            {name: "email", value: values.email},
            {name: "phone", value: values.phone}
        )
    )
};

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        "& > :first-child": {
            marginRight: theme.spacing(2)
        },
        "& > div": {
            width: '50%'
        }
    },
    buttonsWrapper: {
        display: 'inline-flex'
    },
    subtitle: {
        marginTop: theme.spacing(1)
    }
});

class ProfileEditorForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        auth: PropTypes.object,
        initialValues: PropTypes.object,
        phone: PropTypes.string,
        email: PropTypes.string,
        form: PropTypes.string,
        submitting: PropTypes.bool,
        merchantEditMember: PropTypes.bool,
        cancelButton: PropTypes.bool,
        change: PropTypes.func,
        handleSubmit: PropTypes.func,
        removeMember: PropTypes.func,
        cancelDialog: PropTypes.func
    };

    state = {
        openRemoveMember: false
    };

    handleOpenRemoveMember = () => {
        this.setState({openRemoveMember: true});
    };

    handleCancelRemoveMember = () => {
        this.setState({openRemoveMember: false});
    };

    handleConfirmRemoveMember = (removeMember) => {
        this.setState({openRemoveMember: false});
        removeMember();
    };


    renderBirthdayField(role) {

        if (role === ROLE_CONSUMER) {
            return (
                <Field
                    name="birthday"
                    component={RenderDatePicker}
                    hintText={<FormattedMessage
                        id="dateOfBirth"
                        defaultMessage="Date of birth"/>}
                    maxDate={new Date()}
                    clearable={true}/>
            )
        }
    }

    renderGenderField(role) {
        const {classes} = this.props;

        if (role === ROLE_CONSUMER) {
            return (
                <React.Fragment>
                    <Typography
                        variant="subtitle1"
                        className={classes.subtitle}>
                        <FormattedMessage
                            id="gender"
                            defaultMessage="Gender"/>
                    </Typography>

                    <Field
                        name="gender"
                        component={RenderRadioButtonGroup}
                        clearable="true">
                        <FormControlLabel
                            value="FEMALE"
                            control={<Radio/>}
                            label={<FormattedMessage
                                id="female"
                                defaultMessage="Female"/>}/>

                        <FormControlLabel
                            value="MALE"
                            control={<Radio/>}
                            label={<FormattedMessage
                                id="male"
                                defaultMessage="Male"/>}/>

                        <FormControlLabel
                            value="OTHER"
                            control={<Radio/>}
                            label={<FormattedMessage
                                id="other"
                                defaultMessage="Other"/>}/>
                    </Field>
                </React.Fragment>
            )
        }
    }

    renderAddressFields(role) {
        const {classes} = this.props;

        if (role === ROLE_CONSUMER) {

            return (
                <React.Fragment>
                    <Field
                        name="address.street"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="address"
                            defaultMessage="Address"/>}/>

                    <div className={classes.doubleFieldsWrapper}>
                        <Field
                            name="address.city"
                            component={RenderTextField}
                            type="text"
                            hintText={<FormattedMessage
                                id="city"
                                defaultMessage="City"/>}/>

                        <Field
                            name="address.zipCode"
                            component={RenderTextField}
                            type="text"
                            hintText={<FormattedMessage
                                id="zipCode"
                                defaultMessage="Zip code"/>}/>
                    </div>
                </React.Fragment>
            );
        }
    }

    renderNotificationsFields(role, phone, email) {
        const {classes} = this.props;

        if (role === ROLE_CONSUMER && (phone || email)) {
            return (
                <React.Fragment>
                    <Typography
                        variant="subtitle1"
                        className={classes.subtitle}>
                        <FormattedMessage
                            id="marketingMessages"
                            defaultMessage="Marketing messages"/>
                    </Typography>

                    {email && (
                        <Field
                            name="emailNotification"
                            label={<FormattedMessage
                                id="email"
                                defaultMessage="Email"/>}
                            type="checkbox"
                            component={Checkbox}/>
                    )}

                    {phone && (
                        <Field
                            name="phoneNotification"
                            label={<FormattedMessage
                                id="phone"
                                defaultMessage="Phone"/>}
                            type="checkbox"
                            component={Checkbox}/>
                    )}
                </React.Fragment>
            )
        }
    }

    render() {
        const {
            classes, handleSubmit, submitting, phone, merchantEditMember, email, auth, initialValues, removeMember,
            form, cancelDialog, cancelButton
        } = this.props;

        const editedRole = merchantEditMember ? initialValues.authority : auth.role;

        const removeMemberDialogActions = [
            <Button
                key="close"
                label={<FormattedMessage
                    id="close"
                    defaultMessage="Close"/>}
                color="secondary"
                onClick={this.handleCancelRemoveMember}/>,
            <Button
                key="yes"
                label={<FormattedMessage
                    id="yes"
                    defaultMessage="Yes"/>}
                customColor="red"
                onClick={this.handleConfirmRemoveMember.bind(this, removeMember)}/>,
        ];

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {this.renderHeader(merchantEditMember)}
                </Typography>

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="firstName"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="firstName"
                            defaultMessage="First name"/>}/>

                    <Field
                        name="lastName"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="lastName"
                            defaultMessage="Last name"/>}/>
                </div>

                <Field
                    name="email"
                    component={RenderTextField}
                    type="email"
                    onChange={(field, value) => {
                        this.props.change("emailNotification",
                            !!value)
                    }}
                    hintText={<FormattedMessage
                        id="email"
                        defaultMessage="Email"/>}/>

                <PhoneInput
                    defaultCountry={initialValues.language && COUNTRY_CODES[initialValues.language].code}
                    form={form}
                    initialValues={initialValues}/>

                {this.renderAddressFields(editedRole)}

                {this.renderNotificationsFields(editedRole, phone, email)}

                {this.renderBirthdayField(editedRole)}

                {this.renderGenderField(editedRole)}

                <div className={classes.buttonsWrapper}>
                    {this.renderRemoveButton(editedRole, merchantEditMember, removeMemberDialogActions)}

                    <Button
                        icon={<Save/>}
                        label={<FormattedMessage
                            id="save"
                            defaultMessage="Save"/>}
                        onClick={handleSubmit.bind(this)}
                        disabled={submitting}/>
                </div>

                {cancelButton && (
                    <Button
                        icon={<Clear/>}
                        label={<FormattedMessage
                            id="cancel"
                            defaultMessage="Cancel"/>}
                        color="secondary"
                        onClick={cancelDialog}/>
                )}
            </form>
        )
    }

    renderHeader(merchantEditMember) {
        if (merchantEditMember) {
            return (
                <React.Fragment>
                     <span style={{textTransform: 'lowercase'}}>
                    <FormattedMessage
                        id="edit"
                        defaultMessage="Edit"/>
                     </span>
                    &nbsp;
                    <FormattedMessage
                        id="member"
                        defaultMessage="member"/>
                </React.Fragment>
            )
        }
        return <FormattedMessage
            id="profile"
            defaultMessage="Profile"/>
    }

    renderRemoveButton(editedRole, merchantEditMember, removeMemberDialogActions) {
        if (merchantEditMember) {
            return (
                <div>
                    <Button
                        icon={<Delete/>}
                        label={<FormattedMessage
                            id="remove"
                            defaultMessage="Remove"/>}
                        customColor="red"
                        onClick={this.handleOpenRemoveMember}/>

                    <Dialog
                        onClose={this.handleCancelRemoveMember}
                        open={this.state.openRemoveMember}
                        title={
                            <React.Fragment>
                                <FormattedMessage
                                    id="remove"
                                    defaultMessage="Remove"/>
                                &nbsp;
                                <FormattedMessage
                                    id="member"
                                    defaultMessage="member"/>
                            </React.Fragment>
                        }
                        actions={removeMemberDialogActions}
                        content={<FormattedMessage
                            id="areYouSureRemoveMemberText"
                            defaultMessage="Are you sure you want to remove member?"/>}/>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('profileEditorForm');
    const phone = selector(state, 'phone');
    const email = selector(state, 'email');
    return {
        phone,
        email
    };
}

export default reduxForm({
    form: 'profileEditorForm',
    validate
})(connect(mapStateToProps, null)(withStyles(styles)(ProfileEditorForm)))