import axios from "axios";
import {

    ONBOARDING_VERIFICATION
} from "../../common/actions/types";
import {apiError} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";
import {loginMember} from "./kundklubb-auth-actions";

const ENDPOINT_URL = `${ROOT_URL}/onboarding`;

export function onboardingVerificationMember(confirmationId, values, history, redirectAfterLoginUrl) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/confirmation/${confirmationId}`, values)
            .then(response => {
                dispatch({
                    type: ONBOARDING_VERIFICATION,
                    payload: response.data
                });
                //FIXME refactor completeInformation
                dispatch(loginMember(values, history, redirectAfterLoginUrl));
            })
            .catch(response => {
                dispatch(apiError('Unable to verify registration', response));
            });
    }
}

