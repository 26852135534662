import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    }
});

const CenterContainer = ({classes, className, ...rest}) => (
    <div
        {...rest}
        className={classNames(classes.root, className)}/>
);

CenterContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default withStyles(styles)(CenterContainer);
