import React from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {addMonths, isAfter, subDays} from 'date-fns'

import {makeStyles} from '@material-ui/core/styles';
import Pageview from "@material-ui/icons/Pageview";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import Dialog from "../../../common/components/common/Dialog";
import {getTranslatedDate} from "../../../common/utils/date-utils";

import {SWEDISH} from "../../../common/utils/enums/languages";

const useStyles = makeStyles({
    textBold: {
        fontWeight: 'bold'
    },
    dialog: {
        width: '92%',
        maxWidth: 400,
        maxHeight: 310,
        margin: 0
    },
    table: {
        width: '100%',
        textAlign: 'left',
        '& > tr, th': {
            width: '50%'
        }
    },
    balanceText: {
        display: 'flex',
        alignItems: 'baseline',
        alignSelf: 'center'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const ConsumerPointsList = props => {
    const {totalPoints, purchases, pointsValidation, language, pointsStartDate} = props;
    const classes = useStyles();
    const [openDialog, setDialogOpen] = React.useState(false);

    const dateLanguage = language || SWEDISH;

    const handleToggle = () => {
        setDialogOpen(prevOpen => !prevOpen);
    };

    const validPoints = getPointsWithExpirationDate();

    return (
        <React.Fragment>
            <IconButton
                className={classes.iconButton}
                disableRipple
                disableFocusRipple
                onClick={handleToggle}>
                <Pageview
                    color="secondary"
                    fontSize="large"/>
            </IconButton>

            <Dialog
                onCancel={handleToggle}
                open={openDialog}
                rootClasses={{paper: classes.dialog}}
                hasCloseButton
                title={
                    <div className={classes.balanceText}>
                        <Typography
                            variant="body1"
                            className={classes.textBold}>
                            <FormattedMessage
                                id="balance"
                                defaultMessage="Balance"/>
                            :&nbsp;
                        </Typography>

                        <Typography
                            className={classes.textBold}
                            variant="h6">
                            {totalPoints}
                            &nbsp;
                            <FormattedMessage
                                id="pointsAmount"
                                defaultMessage="points"/>
                        </Typography>
                    </div>
                }
                content={
                    <table className={classes.table}>
                        <tbody>
                        <Typography component="tr">
                            <th>
                                <FormattedMessage
                                    id="sum"
                                    defaultMessage="sum"/>
                            </th>
                            <th>
                                <FormattedMessage
                                    id="validUntil"
                                    defaultMessage="Valid until"/>
                            </th>
                        </Typography>

                        {validPoints.map((data, dataIndex) => {
                            if (data.totalPoints > 0) {
                                return (
                                    <tr key={dataIndex}>
                                        <Typography
                                            variant="body1"
                                            component="td">
                                            {data.totalPoints}p
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            component="td">
                                            {data.expirationDate}
                                        </Typography>
                                    </tr>
                                )
                            }
                            return null
                        })}
                        </tbody>
                    </table>
                }/>
        </React.Fragment>
    );

    function getPointsWithExpirationDate() {
        const groupedPurchasesByExpirationDate = purchases
            .filter(purchase => isAfter(purchase.dateOfPurchase, pointsStartDate))
            .map(purchase => {
                const expirationDate = getTranslatedDate(subDays(addMonths(purchase.dateOfPurchase, pointsValidation), 1), dateLanguage);
                return ({
                    ...purchase,
                    ["expirationDate"]: expirationDate
                })
            }).reduce((purchases, purchase) => ({
                ...purchases,
                [purchase["expirationDate"]]: (purchases[purchase["expirationDate"]] || []).concat(purchase)
            }), {});

        let earnedPointsDates = [];
        Object.values(groupedPurchasesByExpirationDate).map((date) => date
            .reduce((previousPoints, nextUsage) => previousPoints + nextUsage.remainingPoints, 0))
            .map((countedPoints, pointsIndex) =>
                earnedPointsDates.push({
                    expirationDate: Object.keys(groupedPurchasesByExpirationDate)[pointsIndex],
                    totalPoints: countedPoints
                })
            );

        return earnedPointsDates
    }

};

ConsumerPointsList.propTypes = {
    purchases: PropTypes.array,
    language: PropTypes.string,
    pointsValidation: PropTypes.number,
    pointsStartDate: PropTypes.number,
    totalPoints: PropTypes.number
};

export default ConsumerPointsList;
