import {FETCH_CLUB_POINTS, FETCH_CONSUMER_POINTS, FETCH_COUPON, FETCH_COUPONS} from "../actions/types";

export default function (state = {}, action) {

    switch (action.type) {
        case FETCH_CLUB_POINTS:
            if (!action.payload) {
                return {...state, clubPoints: {name: 'Non Existing'}};
            }
            return {...state, clubPoints: action.payload};
        case FETCH_COUPON:
            return {...state, coupon: action.payload};
        case FETCH_COUPONS:
            return {...state, coupons: action.payload};
        case FETCH_CONSUMER_POINTS:
            return {...state, consumerPoints: action.payload};
    }

    return state;
}
