import {
    API_ERROR,
    API_SUCCESS,
    SET_ACCOUNT_MENU_VISIBILITY,
    SET_LANGUAGE,
    SET_SIDEBAR_VISIBILITY,
    SET_START_GUIDE_VISIBILITY
} from "./types";


export function hasToken() {
    return localStorage.getItem('token') !== null;
}

export function headerConfig() {
    const token = localStorage.getItem('token')
    if (!token) {
        console.error('Empty token')
    }

    return {
        headers: {'AUTHORIZATION': `Bearer ${token}`}
    }
}

export function apiSuccessMessage(message, actionId) {
    return {
        actionId: actionId,
        type: API_SUCCESS,
        payload: {
            message: message
        }
    };
}

export function apiError(message, error) {
    console.error("Response: ", error)
    let displayMessage = message

    if (error && error.response && error.response.data && error.response.data.errors) {
        displayMessage = displayMessage.concat(". ", error.response.data.errors)
    } else if (error && error.response && error.response.data && error.response.data.message) {
        displayMessage = displayMessage.concat(". ", error.response.data.message)
    } else if (error && error.response) {
        displayMessage = displayMessage.concat(". ", error.response.data)
    }

    return {
        type: API_ERROR,
        payload: {
            message: displayMessage
        }
    };
}

export function setSidebarVisibility(isOpen) {
    return {
        type: SET_SIDEBAR_VISIBILITY,
        payload: isOpen,
    }
}

export function setStartGuideVisibility(isOpen) {
    return {
        type: SET_START_GUIDE_VISIBILITY,
        payload: isOpen,
    }
}

export function setLanguage(language) {
    return {
        type: SET_LANGUAGE,
        payload: language,
    }
}

export function setAccountMenuVisibility(isOpen) {
    return {
        type: SET_ACCOUNT_MENU_VISIBILITY,
        payload: isOpen,
    }
}

