import React from "react";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Container from "./Container";

class NotFoundPage extends React.Component {
    render() {
        return (
            <Container size={Container.SIZE_TINY}>
                <Typography variant="h6">
                    <FormattedMessage
                        id="pageNotFound"
                        defaultMessage="Page not found"/>
                    .
                </Typography>
            </Container>
        )
    }
}

export default NotFoundPage
