import React from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from '@material-ui/core/MenuItem'
import Home from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from '@material-ui/icons/List';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import Phone from '@material-ui/icons/Phone';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';

import RenderOnDesktop from "../../../common/components/common/RenderOnDesktop";

import {
    CONSUMER_CONTACT_URL,
    CONSUMER_PURCHASES_URL,
    HOME_REDIRECT_URL, LOGIN_REDIRECT_URL, TERMS_MEMBERSHIP_URL, TERMS_PRIVACY_URL,
    USER_PROFILE_URL
} from "../../../../static/utils/redirect-const";

const styles = theme => ({
    paper: {
        backgroundColor: theme.palette.secondary.main
    },
    leftDrawer: {
        "& > div": {
            marginTop: 55,
            paddingTop: 15
        }
    },
    menuItemIconMain: {
        paddingRight: theme.spacing(1)
    },
    menuItemMain: {
        fontSize: 16,
        color: theme.palette.common.white
    }
});

class SidebarMenu extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        auth: PropTypes.object,
        clubPoints: PropTypes.object,
        logout: PropTypes.func
    };

    render() {
        const {classes, auth} = this.props;

        if (!auth.authenticated) {
            return null
        }

        return (
            <React.Fragment>
                <RenderOnDesktop>
                    <Drawer
                        open
                        className={classes.leftDrawer}
                        classes={{paper: classes.paper}}
                        variant="persistent">
                        {this.renderItems()}
                    </Drawer>
                </RenderOnDesktop>
            </React.Fragment>
        )
    }

    renderItems() {
        const {classes, clubPoints} = this.props;

        const clubPointsActive = clubPoints && clubPoints.active;
        return (
            <React.Fragment>
                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={HOME_REDIRECT_URL}>
                    <Home className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="home"
                            defaultMessage="Home"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={CONSUMER_PURCHASES_URL}>
                    <ShoppingBasket className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        {clubPointsActive
                            ? <FormattedMessage
                                id="points"
                                defaultMessage="Points"/>
                            : <FormattedMessage
                                id="purchases"
                                defaultMessage="Purchases"/>}
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={CONSUMER_CONTACT_URL}>
                    <Phone className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="contact"
                            defaultMessage="Contact"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={USER_PROFILE_URL}>
                    <AccountCircle className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="editProfile"
                            defaultMessage="Edit profile"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={TERMS_MEMBERSHIP_URL}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="membershipConditions"
                            defaultMessage="Membership Conditions"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={TERMS_PRIVACY_URL}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="privacyPolicy"
                            defaultMessage="Privacy Policy"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={LOGIN_REDIRECT_URL}
                    onClick={this.logout.bind(this)}>
                    <ExitToApp className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="logout"
                            defaultMessage="Logout"/>
                    </Typography>
                </MenuItem>
            </React.Fragment>
        );
    }

    logout() {
        this.props.logout();
    }
}

export default withRouter(withStyles(styles)(SidebarMenu))
