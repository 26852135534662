import axios from "axios";
import {
    CONSUMER_FETCH_CAMPAIGNS,
    CONSUMER_FETCH_COMMERCIAL_CHAIN,
    CONSUMER_FETCH_POINTS_OF_SALE,
    CONSUMER_FETCH_STAMPCARD_COUPON,
    CONSUMER_FETCH_STAMPCARDS,
    CONSUMER_FETCH_STAMPCARDS_COUPONS,
    FETCH_CONSUMER_POINTS,
    FETCH_COUPONS,
    FETCH_HOME_LOCATION,
    FETCH_POINTS_VALIDATION_DATE,
    FETCH_VALID_PURCHASES,
    SET_POS_AS_HOME_LOCATION
} from "../../common/actions/types";
import {apiError, headerConfig} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";

const ENDPOINT_URL = `${ROOT_URL}/consumer`;

export function fetchCommercialChain() {
    return dispatch => {

        axios.get(`${ENDPOINT_URL}/chain`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: CONSUMER_FETCH_COMMERCIAL_CHAIN,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Commercial chain', response));
        });
    }
}

export function fetchPointsOfSale() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/pos`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: CONSUMER_FETCH_POINTS_OF_SALE,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Points of sale', response));
        });
    }
}

export function fetchHomeLocation() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/homelocation`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_HOME_LOCATION,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Home location', response));
        });
    }
}

export function fetchPointsValidationDate() {
    return dispatch => {

        axios.get(`${ENDPOINT_URL}/points/validation`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_POINTS_VALIDATION_DATE,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch points validation date', response));
        });
    }
}

export function fetchPurchases() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/purchases`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_VALID_PURCHASES,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch purchases', response));
        });
    }
}

export function fetchCoupons(activeStatusValidation) {

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/coupons`, {activeStatusValidation: activeStatusValidation}, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_COUPONS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch coupons', response));
            });
    }
}

export function fetchConsumerPoints() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/points`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CONSUMER_POINTS,
                    payload: response.data
                })
            }).catch(response => {
            dispatch(apiError('Unable to fetch consumer points', response));
        });
    }
}

export function fetchCampaigns() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/campaigns/`, headerConfig())
            .then(response => {
                dispatch({
                    type: CONSUMER_FETCH_CAMPAIGNS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch campaigns', response));
            });
    }
}

export function fetchStampcards() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/stampcards`, headerConfig())
            .then(response => {
                dispatch({
                    type: CONSUMER_FETCH_STAMPCARDS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch stampcards', response));
            });
    }
}

export function fetchStampcardCoupons() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/stampcards/coupons`, headerConfig())
            .then(response => {
                dispatch({
                    type: CONSUMER_FETCH_STAMPCARDS_COUPONS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch stampcard coupons', response));
            });
    }
}

export function fetchStampcardCoupon(stampcardCouponId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/stampcards/coupons/${stampcardCouponId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: CONSUMER_FETCH_STAMPCARD_COUPON,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch stampcard coupon', response));
            });
    }
}


export function setPOSAsHomeLocation(pointOfSaleId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/pos/${pointOfSaleId}/home`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: SET_POS_AS_HOME_LOCATION,
                        payload: response.data
                    });
                }
                dispatch(fetchHomeLocation())
            }).catch(response => {
            dispatch(apiError('Unable to set pos as home location', response));
        });
    }
}
