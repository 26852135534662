import {UPDATE_USER, FETCH_USER} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case UPDATE_USER:
            return {...state, update: action.payload};
        case FETCH_USER:
            return {...state, profile: action.payload};
    }

    return state;
}
