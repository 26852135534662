class FontSize {

    constructor(label, size) {
        this.label = label;
        this.value = size;
    }

}

export default FontSize;
