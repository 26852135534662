import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import RenderTextField from "../common/RenderTextField";
import Button from "../common/Button";
import Container from "../common/Container";
import Paper from "../common/Paper";
import {required, RequiredInfo} from "../common/form/FormUtils";
import {passwordValidation} from "../../utils/karming-validators";
import LanguageSelect from "../common/language/LanguageSelect";

const validate = values => {
    return passwordValidation(
        {name: "password", value: values.password},
        {name: "confirmPassword", value: values.confirmPassword}
    )
};

class PasswordResetForm extends React.Component {

    static propTypes = {
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
    };

    render() {
        const {handleSubmit, submitting} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Container size={Container.SIZE_TINY}>
                    <Paper padding>
                        <Typography variant="h6">
                            <FormattedMessage
                                id="password-reset-form.typeYourNewPasswordTwice"
                                defaultMessage="Type your new password twice"/>
                        </Typography>

                        <Field
                            name="password"
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="password"
                                        defaultMessage="Password"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            component={RenderTextField}
                            type="password"
                            validate={required}/>

                        <Field
                            name="confirmPassword"
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="confirmPassword"
                                        defaultMessage="Confirm password"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            component={RenderTextField}
                            type="password"
                            validate={required}/>

                        <Button
                            label={<FormattedMessage
                                id="submit"
                                defaultMessage="Submit"/>}
                            type="submit"
                            disabled={submitting}/>
                        <RequiredInfo/>

                        <LanguageSelect/>
                    </Paper>
                </Container>
            </form>
        );
    }
}

export default reduxForm({
    form: 'passwordResetForm',
    validate
})(PasswordResetForm)
