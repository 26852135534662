import React from "react";
import Button from '@material-ui/core/Button';
import classnames from "classnames";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        margin: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    },
    label: {
        textTransform: 'initial',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    iconButton: {
        margin: 0,
        display: 'grid',
        minWidth: 30
    },
    greyColorContained: {
        background: theme.palette.grey[500],
        "&:hover": {
            background: theme.palette.grey[600]
        }
    },
    greenColorContained: {
        background: theme.palette.green.main,
        "&:hover": {
            background: theme.palette.green.dark
        }
    },
    yellowColorContained: {
        background: theme.palette.yellow.main,
        "&:hover": {
            background: theme.palette.yellow.dark
        }
    },
    redColorContained: {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        }
    },
    redColorOutlined: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.light,
            borderColor: theme.palette.error.main
        }
    }
});

const KarmingButton = (props) => {
    const {customColor, className, classes, label, color, style, size, onClick, icon, type, disabled, variant, ...rest} = props;

    const buttonSize = size || "medium";
    const buttonColor = color || 'primary';
    const buttonVariant = variant ? variant : "contained";
    const buttonClass = classnames(
        classes.root,
        className,
        buttonVariant === "contained" && classes[customColor + "ColorContained"],
        variant === "outlined" && classes[customColor + "ColorOutlined"],
        !label && classes.iconButton
    );

    return (
        <Button
            {...rest}
            variant={buttonVariant}
            style={style}
            className={buttonClass}
            size={buttonSize}
            color={buttonColor}
            type={type}
            disabled={disabled}
            onClick={onClick}>

            {icon}

            <span className={classes.label}>
                  {label}
               </span>
        </Button>
    )
};

export default withStyles(styles)(KarmingButton)