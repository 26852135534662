import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import PersonAdd from '@material-ui/icons/PersonAdd';

import Button from "../../../common/components/common/Button";
import RenderTextField from "../../../common/components/common/RenderTextField";

import {
    emailOrPhoneRequiredValidation,
    emailValidation,
    phoneValidation
} from "../../../common/utils/karming-validators";

import PhoneInput from "../../../common/components/common/form/PhoneInput";
import {required} from "../../../common/components/common/form/FormUtils";
import Typography from "@material-ui/core/Typography";
import {COUNTRY_CODES} from "../../../common/utils/country-codes";

export const CONSUMER_ONBOARDING_FORM_NAME = 'consumerOnboardingForm';

const validate = values => {
    return Object.assign(
        emailValidation({name: "email", value: values.email}),
        phoneValidation({name: "phone", value: values.phone}),
        emailOrPhoneRequiredValidation(
            {name: "authority", value: "ROLE_CONSUMER"},
            {name: "email", value: values.email},
            {name: "phone", value: values.phone}))
};

const styles = theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(2)
    }
});

class ConsumerOnboardingForm extends Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chain: PropTypes.object,
        smsAccount: PropTypes.object,
        form: PropTypes.string,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {classes, chain, handleSubmit, submitting, form, smsAccount} = this.props;

        const disabledSmsInvitation = !smsAccount || !smsAccount.hasCredits;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">

                {this.renderMessage(disabledSmsInvitation)}

                <Field
                    name="email"
                    hintText={<FormattedMessage
                        id="email"
                        defaultMessage="Email"/>}
                    component={RenderTextField}
                    validate={!disabledSmsInvitation ? undefined : required}/>

                {!disabledSmsInvitation && (
                    <PhoneInput
                        defaultCountryCode={chain && chain.language && COUNTRY_CODES[chain.language].code}
                        disabled={disabledSmsInvitation}
                        form={form}/>
                )}

                <div className={classes.buttonWrapper}>
                    <Button
                        icon={<PersonAdd/>}
                        label={<FormattedMessage
                            id="signUp"
                            defaultMessage="Sign up"/>}
                        type="submit"
                        disabled={submitting}/>
                </div>
            </form>
        );
    }

    renderMessage(disabledSmsInvitation) {
        if (!disabledSmsInvitation) {
            return (
                <Typography variant="body1">
                    <FormattedMessage
                        id="typeEmailOrPhone"
                        defaultMessage="Type email address or phone number"/>.
                </Typography>
            );
        }
        return (
            <Typography variant="body1">
                <FormattedMessage
                    id="typeEmail"
                    defaultMessage="Type email address"/>
                .
            </Typography>
        )
    }
}

export default reduxForm({
    form: CONSUMER_ONBOARDING_FORM_NAME,
    validate
})(withStyles(styles)(ConsumerOnboardingForm));
