import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";

import AuthRequiredRoute from "../../common/components/auth/AuthRequiredRoute";
import ConsumerRoutes from "../../kundklubb/components/ConsumerRoutes";
import GuestRoutes from "../../kundklubb/components/guest/GuestRoutes";

import Dialog from '../../common/components/common/Dialog'
import Button from "../../common/components/common/Button";
import AppMessageBar from "../../common/components/AppMessageBar";
import MainPageContainer from "../../common/components/common/MainPageContainer";
import NotFoundPage from "../../common/components/common/NotFoundPage";
import ConsumerHome from "./ConsumerHome";
import MemberPasswordResetRequest from "./auth/MemberPasswordResetRequest";
import PasswordReset from "../../common/components/auth/PasswordReset";
import MemberLogin from "./auth/MemberLogin";
import ChainHome from "./guest/ChainHome";
import ConsumerAppMenu from "./menu/ConsumerAppMenu";

import {logout} from "../../common/actions/auth-actions";
import {
    setAccountMenuVisibility,
    setLanguage,
    setSidebarVisibility,
    setStartGuideVisibility
} from "../../common/actions/utils-actions";
import {
    fetchClubPointsWithoutAuthBySubdomain,
    fetchCommercialChainWithoutAuthBySubdomain
} from "../actions/kundklubb-public-actions";
import {getCurrentSubdomain} from "../actions/kundklubb-utils-actions";
import {fetchUserDetails} from "../../common/actions/user-actions";

import "../../../static/style/main.scss";
import AddToHomeScreenPopup from "../../common/components/common/pwa/AddToHomeScreenPopup";

class KundKlubbApp extends Component {

    static propTypes = {
        app: PropTypes.object,
        auth: PropTypes.object,
        user: PropTypes.object,
    };

    state = {
        open: false,
        text: ""
    };

    handleAppOpenDialog = (text) => {
        this.setState({open: true, text: text});
    };

    handleAppCloseDialog = () => {
        this.setState({open: false, text: ""});
    };

    componentDidUpdate(prevProps) {
        const {chain, auth, setLanguage, language} = this.props;

        if (!language && (!auth || !auth.authenticated) && chain && chain.language && (!prevProps.chain || prevProps.chain.language !== chain.language)) {
            setLanguage(chain.language)
        }
    }

    componentDidMount() {
        const {fetchCommercialChainWithoutAuthBySubdomain} = this.props;
        const subdomain = getCurrentSubdomain();
        if (subdomain) {
            fetchCommercialChainWithoutAuthBySubdomain();
        }
        this.props.fetchUserDetails();
    }

    render() {
        const {auth, app, user, chain} = this.props;

        const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);

        if (chain) {
            document.title = chain.commercialName
        }

        return (
            <Router>
                <div>
                    {isSamsungBrowser && <AddToHomeScreenPopup/>}

                    <ConsumerAppMenu
                        {...this.props}
                        chain={chain}
                        auth={auth}
                        app={app}
                        user={user}
                        logout={this.props.logout}
                        setSidebarVisibility={this.props.setSidebarVisibility}
                        setAccountMenuVisibility={this.props.setAccountMenuVisibility}/>

                    <AppMessageBar
                        auth={auth}
                        status={app.status}/>

                    <MainPageContainer userRole={auth.role}>
                        <Switch>
                            <AuthRequiredRoute
                                {...this.props}
                                exact path="/"
                                auth={auth}
                                app={app}
                                user={user}
                                clubPointsActive={chain && chain.clubPointActive}
                                redirectPathIfNoAuth="/guest"
                                component={ConsumerHome}/>

                            <Route
                                key='2'
                                path="/guest"
                                component={ChainHome}/>,

                            <Route
                                key='6'
                                path="/:subpage/guest"
                                component={ChainHome}/>,

                            <Route
                                key='3'
                                exact path="/auth/password/reset/:id"
                                component={PasswordReset}/>,

                            <Route
                                key='4'
                                exact path="/login"
                                component={MemberLogin}/>,

                            <Route
                                key='5'
                                exact path="/auth/password/request"
                                component={MemberPasswordResetRequest}/>

                            {ConsumerRoutes(this.props)}
                            {GuestRoutes(this.props)}

                            <Route component={NotFoundPage}/>
                        </Switch>

                        <Dialog
                            onClose={this.handleAppCloseDialog}
                            title={<FormattedMessage
                                id="congrats"
                                defaultMessage="Congrats!"/>}
                            actions={[
                                <Button
                                    key="close"
                                    label={<FormattedMessage
                                        id="close"
                                        defaultMessage="Close"/>}
                                    onClick={this.handleAppCloseDialog}/>
                            ]}
                            open={this.state.open}
                            content={
                                <div>
                                    {this.state.text}
                                </div>
                            }/>
                    </MainPageContainer>
                </div>
            </Router>
        );
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        app: state.app,
        user: state.user,
        chain: state.company.commercialChain,
        language: state.app.language,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout, setSidebarVisibility, setStartGuideVisibility, setAccountMenuVisibility, fetchUserDetails,
        fetchCommercialChainWithoutAuthBySubdomain, fetchClubPointsWithoutAuthBySubdomain, setLanguage
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KundKlubbApp)
