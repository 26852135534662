import React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

class ConsumerTerminate extends React.Component {

    render() {
        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography
                        paragraph
                        variant="h6">
                        <FormattedMessage
                            id="terminateAccount"
                            defaultMessage="Terminate Account"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="consumer-terminate.terminateAccountText"
                            defaultMessage="To terminate your account please send an mail."/>
                    </Typography>

                    <Typography variant="body1">
                        <Link to="/consumer/contact">
                            <FormattedMessage
                                id="contact"
                                defaultMessage="Contact"/>
                        </Link>
                    </Typography>
                </Paper>
            </Container>
        );
    }

}

export default ConsumerTerminate
