import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import {MenuItem} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from '@material-ui/icons/AccountCircle';
import ImportExport from '@material-ui/icons/List';

const styles = theme => ({
    item: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
});

class MembershipManagement extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
    };

    render() {
        const {history, classes} = this.props;

        return (
            <React.Fragment>
                <MenuItem
                    className={classes.item}
                    onClick={this.pushToTerminateAccount.bind(this, history)}>
                    <ListItemIcon>
                        <AccountCircle/>
                    </ListItemIcon>

                    <ListItemText>
                        <FormattedMessage
                            id="terminateAccount"
                            defaultMessage="Terminate Account"/>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    className={classes.item}
                    onClick={this.pushToExportAccount.bind(this, history)}>
                    <ListItemIcon>
                        <ImportExport/>
                    </ListItemIcon>

                    <ListItemText>
                        <FormattedMessage
                            id="exportData"
                            defaultMessage="Export Data"/>
                    </ListItemText>
                </MenuItem>
            </React.Fragment>
        )
    }

    pushToTerminateAccount(history) {
        history.push(`/consumer/membership/terminate`);
    }

    pushToExportAccount(history) {
        history.push(`/consumer/membership/export`);
    }

}

export default withStyles(styles)(MembershipManagement);
