import React, {useState} from "react";
import PropTypes from "prop-types";

import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import CardMedia from "@material-ui/core/CardMedia";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme => ({
    titleWrapper: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
        color: theme.palette.grey[600],
        "& :not(:first-child)": {
            marginLeft: theme.spacing(0.5),
            fontWeight: 'bold'
        }
    },
    couponName: {
        width: '75%',
        lineHeight: theme.spacing(0.15),
        wordBreak: 'break-word',
        textAlign: 'left'
    },
    arrowIcon: {
        position: 'absolute',
        top: props => (
            props.bottomSubtitleText
                ? theme.spacing(2)
                : theme.spacing(0.5)
        ),
        right: theme.spacing(2)
    },
    cardContent: {
        textAlign: 'left',
        position: 'relative'
    },
    cardDescription: {
        textAlign: 'left',
        padding: theme.spacing(0, 2, 2, 2)
    }
})));

const ImageCard = props => {
    const {url, imagePath, topText, expandedText, bottomTitleText, bottomSubtitleText, mediaContent} = props;
    const classes = useStyles(props);
    const [isExpanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(prevOpen => !prevOpen);
    };

    return (
        <Card>
            {topText && (
                <CardContent className={classes.cardContent}>
                    <Typography variant="body1">
                        {topText}
                    </Typography>
                </CardContent>
            )}

            {mediaContent}
            {imagePath &&
            <React.Fragment>
                {!url &&
                <CardMedia
                    component={'img'}
                    image={imagePath}/>
                }
                {url &&
                <Link to={url}>
                    <CardMedia
                        component={'img'}
                        image={imagePath}/>
                </Link>
                }
            </React.Fragment>
            }

            <CardContent className={classes.cardContent}>
                <Typography
                    variant="subtitle1"
                    className={classes.couponName}>
                    {bottomTitleText}
                </Typography>
                {bottomSubtitleText && (
                    <Typography variant="body1">
                        {bottomSubtitleText}
                    </Typography>
                )}

                {expandedText && (
                    <IconButton
                        className={classes.arrowIcon}
                        onClick={handleToggle}>
                        {isExpanded ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                )}
            </CardContent>

            {expandedText && (
                <Collapse
                    in={isExpanded}
                    timeout="auto"
                    unmountOnExit>
                    <CardContent className={classes.cardDescription}>
                        <Typography
                            variant="body1">
                            {expandedText}
                        </Typography>
                    </CardContent>
                </Collapse>
            )}
        </Card>
    )
};

ImageCard.propTypes = {
    imagePath: PropTypes.string,
    mediaContent: PropTypes.node,
    topText: PropTypes.node,
    expandedText: PropTypes.node,
    bottomTitleText: PropTypes.node,
    bottomSubtitleText: PropTypes.node
};

export default ImageCard
