import React from "react";
import styled from "styled-components";

import sv from 'date-fns/locale/sv';
import fr from 'date-fns/locale/fr';

import {ONE_MINUS_GOLDEN_RATIO} from "../../constants";
import {createSelectRender} from "./enums";

const CountryWrapper = styled.div`
    text-align: left;
    
    img {
        width: 1.6em;
        height: 1em;
        vertical-align: text-bottom;
    }
    
    span {
        margin-left: ${ONE_MINUS_GOLDEN_RATIO}em;
        vertical-align: middle;
    }
`;

export const ENGLISH = 'ENGLISH';
export const FRENCH = 'FRENCH';
export const SWEDISH = 'SWEDISH';

const COUNTRIES_CONFIGS = {
    [ENGLISH]: {name: "gb", message: "English"},
    [SWEDISH]: {name: "se", message: "Svenska"},
    [FRENCH]: {name: "fr", message: "Français"}
};

export const DATE_LOCALES = {
    [ENGLISH]: "en",
    [SWEDISH]: sv,
    [FRENCH]: fr
};

export const parseAddHomeScreenSafariIconUrl = (language) => `../../../../static/images/${COUNTRIES_CONFIGS[language].name}-add-to-home-screen-iphone.png`;

const parseCountryFlagUrl = (country) => `../../../../static/images/flags/${COUNTRIES_CONFIGS[country].name}.svg`;
const createCountrySelectRender = (enumValue) => createSelectRender(enumValue, LANGUAGES_RENDERS);
const createCountryRender = (language) => {
    return (
        <CountryWrapper>
            <img src={parseCountryFlagUrl(language)}/> <span>{COUNTRIES_CONFIGS[language].message}</span>
        </CountryWrapper>
    )
};

export const LANGUAGES_RENDERS = {
    [ENGLISH]: createCountryRender(ENGLISH),
    [SWEDISH]: createCountryRender(SWEDISH),
    [FRENCH]: createCountryRender(FRENCH),
};


export const LANGUAGES = [
    // createCountrySelectRender(ENGLISH),
    createCountrySelectRender(SWEDISH),
    createCountrySelectRender(FRENCH),
];