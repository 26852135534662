import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Done from "@material-ui/icons/Done";

const styles = theme => ({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        textAlign: 'left',
    },
    icon: {
        position: 'absolute',
        textAlign: 'left',
        zIndex: 99,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    }
});

const ImageCheckedIcon = ({classes}) => {
    return (
        <div className={classes.root}>
            <Done className={classes.icon}/>
        </div>
    )
};

ImageCheckedIcon.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageCheckedIcon)
