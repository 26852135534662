import FontSize from "./utils/FontSize";

export const GOLDEN_RATIO = 0.618;
export const GOLDEN_RATIO_PERCENTAGE = '61.8%';

export const ONE_MINUS_GOLDEN_RATIO = 0.382;
export const ONE_MINUS_GOLDEN_RATIO_PERCENTAGE = '38.2%';

export const STANDARD_FONT_SIZE = 16;

// exact same size is (and must be) defined under server side
export const BANNER_MAX_OUTPUT_SIZE = 4 * 1024 * 1024;

export const BANNER_MAX_TEXTS_COUNT = 4;

export const BANNER_RATIO = 4 / 5; // width to height
export const BANNER_WIDTH_PX = 540; // 0.5 * FULL_HD_HEIGHT
export const BANNER_HEIGHT_PX = BANNER_WIDTH_PX / BANNER_RATIO;

export const DEFAULT_FONT_FAMILIES = [
    'Arial',
    'Arial Black',
    'Courier New',
    'Impact',
    'Times New Roman',
    /* TODO */
];

export const DEFAULT_FONT_SIZES = [
    new FontSize('tinny', 20),
    new FontSize('very small', 22),
    new FontSize('small', 26),
    new FontSize('medium', 32),
    new FontSize('large', 40),
    new FontSize('very large', 50),
    new FontSize('huge', 62),
];
export const DEFAULT_FONT_SIZE = DEFAULT_FONT_SIZES[Math.floor(DEFAULT_FONT_SIZES.length / 2)];

export const showDefaultCursor = () => {
    document.body.style.cursor = 'default';
};

export const showGrabCursor = () => {
    document.body.style.cursor = 'pointer';
};

export const showGrabbingCursor = () => {
    document.body.style.cursor = 'move';
};
