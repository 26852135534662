import React from "react";
import {Redirect, Route} from "react-router-dom";

export default function AuthRequiredRoute({component: Component, auth, redirectPathIfNoAuth, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => auth.authenticated === true
                ? <Component {...props}
                             {...rest}
                             auth={auth}
                             user={rest.user}
                             app={rest.app}/>
                : <Redirect to={{
                    pathname: redirectPathIfNoAuth ? redirectPathIfNoAuth : '/login',
                    state: {from: props.location}}}/>
            }/>
    )
}
