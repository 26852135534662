export const DENMARK = 'DENMARK';
export const FINLAND = 'FINLAND';
export const GERMANY = 'GERMANY';
export const NORWAY = 'NORWAY';
export const POLAND = 'POLAND';
export const SWEDISH = 'SWEDISH';
export const FRENCH = 'FRENCH';

export const DEFAULT_COUNTRY_CODE = '46';

export const COUNTRY_CODES =
    {
        DENMARK: {name: "Denmark", code: '45'},
        FINLAND: {name: "Finland", code: '358'},
        GERMANY: {name: "Germany", code: '49'},
        NORWAY: {name: "Norway", code: '47'},
        POLAND: {name: "Poland", code: '48'},
        SWEDISH: {name: "Sweden", code: '46'},
        FRENCH: {name: "France", code: '33'},
    };