import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Pager from "../../pager/Pager";

const styles = theme => ({
    itemWrapper: {
        textAlign: 'left',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2)
    }
});

class MobileKarmingTable extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        baseSearchConfig: PropTypes.object,
        data: PropTypes.array,
        columns: PropTypes.array,
        pageSize: PropTypes.number,
        totalCount: PropTypes.number,
        showPagination: PropTypes.bool,
        pager: PropTypes.bool,
        sortByDateOfPurchase: PropTypes.bool,
        onSearchConfigChanged: PropTypes.func,
        onClick: PropTypes.func
    };

    render() {
        //TODO support pagination
        const {data, columns, onClick, sortByDateOfPurchase, onSearchConfigChanged, pager, totalCount, baseSearchConfig} = this.props

        // FIXME works only for purchases
        if (sortByDateOfPurchase) {
            data.sort((a, b) => new Date(b.dateOfPurchase) - new Date(a.dateOfPurchase))
        }

        if (pager) {
            return (
                <Pager
                    totalCount={totalCount}
                    onSearchConfigChanged={onSearchConfigChanged}
                    baseSearchConfig={baseSearchConfig}>
                    {this.getContent(data, columns, onClick)}
                </Pager>
            )
        }
        return (
            this.getContent(data, columns, onClick)
        )
    }

    getContent(data, columns, onClick) {
        return (
            <React.Fragment>
                {data.map((row, index) => (
                    <React.Fragment key={index}>
                        {this.renderRow(columns, row, onClick, data.length === index + 1)}
                    </React.Fragment>
                ))}
            </React.Fragment>
        )
    }

    renderRow(columns, row, onClick) {
        const {classes} = this.props;

        return (
            <Card
                className={classes.itemWrapper}
                onClick={(e) => onClick(e, row)}>
                <span>
                    {columns.map((column, index) => (
                        <React.Fragment key={index}>
                            {this.renderCell(column.Header, column.accessor(row))}
                        </React.Fragment>
                    ))}
                </span>
            </Card>
        )
    }

    renderCell(header, value) {
        return (
            <Typography
                variant="body1"
                component="div">
                <b>{header}:</b>
                <span> {value}</span>
            </Typography>
        )
    }

}

export default withStyles(styles)(MobileKarmingTable)