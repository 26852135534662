import React from 'react'
import PropTypes from "prop-types"

import IconButton from "@material-ui/core/IconButton"
import BackIcon from '@material-ui/icons/NavigateBefore'
import NextIcon from '@material-ui/icons/NavigateNext'

import {DEFAULT_PAGE_SIZE} from "../common/table/KarmingTable";
import MoreIcon from "./MoreIcon";
import PageButton from "./PageButton";

class Pager extends React.Component {

    static propTypes = {
        children: PropTypes.node,
        initialSearchConfig: PropTypes.shape({
            query: PropTypes.string,
            sortType: PropTypes.string,
            page: PropTypes.number,
            entriesPerPage: PropTypes.number
        }),
        sortTypes: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            render: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
        })),
        hasPager: PropTypes.bool,
        totalCount: PropTypes.number,
        pagesMargin: PropTypes.number,
        onSearchConfigChanged: PropTypes.func.isRequired,
    };

    static defaultProps = {
        initialSearchConfig: {},
        hasPager: true,
        pagesMargin: 2,
        filterProps: {}
    };

    // TODO will it work when initialSearchConfig is the same under different contexts?
    static getDerivedStateFromProps(nextProps, prevState) {
        const {initialSearchConfig: propsSearchConfig} = nextProps;
        const {initialSearchConfig: stateSearchConfig} = prevState;

        const {query, sortType, page, entriesPerPage, ...rest} = propsSearchConfig;
        const parsedSearchConfig = {
            query: query || "",
            sortType: sortType,
            page: page || 0,
            entriesPerPage: entriesPerPage || DEFAULT_PAGE_SIZE,
            ...rest
        };

        if (JSON.stringify(stateSearchConfig) !== JSON.stringify(parsedSearchConfig)) {
            const {onSearchConfigChanged, filterComponent: FilterComponent} = nextProps;
            const defaultFilterValue = FilterComponent && FilterComponent.getDefaultValue() || {};
            onSearchConfigChanged({...parsedSearchConfig, ...defaultFilterValue});
            return ({
                initialSearchConfig: parsedSearchConfig,
                searchConfig: parsedSearchConfig,
                filterSearchConfig: defaultFilterValue
            });
        }

        return null;
    }

    state = {
        initialSearchConfig: null,
        searchConfig: null,
    };

    componentWillUnmount() {
        this.clearOnQueryChangeTimer()
    }

    render() {
        const {hasPager, children} = this.props;

        return (
            <React.Fragment>
                {children}
                {hasPager && this.renderPager()}
            </React.Fragment>
        )
    }

    renderPager() {
        const {totalCount} = this.props;
        const {searchConfig: {page, entriesPerPage}} = this.state;
        const totalPagesCount = Math.ceil(totalCount / entriesPerPage);

        if (totalPagesCount > 0) {
            return (
                <React.Fragment>
                    <IconButton
                        disabled={page <= 0}
                        onClick={this.handlePageChange.bind(this, page - 1)}>
                        <BackIcon/>
                    </IconButton>

                    {this.createPagerButtons(totalPagesCount)}

                    <IconButton
                        disabled={page >= totalPagesCount - 1}
                        onClick={this.handlePageChange.bind(this, page + 1)}>
                        <NextIcon/>
                    </IconButton>
                </React.Fragment>
            )
        }
    }

    createPagerButtons(totalPagesCount) {
        const {pagesMargin} = this.props;
        const {searchConfig: {page}} = this.state;

        const margin = Math.max(pagesMargin, 1);
        const buttons = [];

        const middleSectionStart = Math.max(page - margin, 0);
        const middleSectionEnd = Math.min(page + margin + 1, totalPagesCount);

        for (let i = 0; i < Math.min(margin, middleSectionStart); i++) {
            buttons.push(this.createPagerButton(i))
        }
        if (page - margin > margin) {
            buttons.push(<MoreIcon key="firstPlaceholder"/>)
        }
        for (let i = middleSectionStart; i < middleSectionEnd; i++) {
            buttons.push(this.createPagerButton(i))
        }
        if (page + margin + 1 < totalPagesCount - margin) {
            buttons.push(<MoreIcon key="secondPlaceholder"/>)
        }
        for (let i = Math.max(totalPagesCount - margin, middleSectionEnd); i < totalPagesCount; i++) {
            buttons.push(this.createPagerButton(i))
        }

        return buttons;
    }

    createPagerButton(buttonPage) {
        const {searchConfig: {page}} = this.state;
        return (
            <PageButton
                key={buttonPage}
                disabled={buttonPage === page}
                onClick={this.handlePageChange.bind(this, buttonPage)}
                text={{textAlign: 'center', verticalAlign: 'middle'}}>
                {buttonPage + 1}
            </PageButton>
        )
    }

    handlePageChange(newPage) {
        const {searchConfig, filterSearchConfig} = this.state;
        const newSearchConfig = {...searchConfig, page: newPage};
        this.notifySearchConfigChanged({...newSearchConfig, ...filterSearchConfig});
        this.setState({searchConfig: newSearchConfig})
    }

    notifySearchConfigChanged(newSearchConfig) {
        const {onSearchConfigChanged} = this.props;
        onSearchConfigChanged(newSearchConfig)
    }

    clearOnQueryChangeTimer() {
        if (this.queryTimer) {
            clearTimeout(this.queryTimer)
        }
    }

}

export default Pager
