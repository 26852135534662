import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Spinner from "../../../common/components/common/Spinner";
import PasswordResetRequestForm from "../../../common/components/auth/PasswordResetRequestForm";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {sendPasswordResetMessage} from "../../../common/actions/auth-actions";
import {
    fetchCommercialChainWithoutAuthBySubdomain,
    fetchCompanySmsAccountBySubdomain
} from "../../actions/kundklubb-public-actions";
import {getCurrentSubdomain} from "../../actions/kundklubb-utils-actions";
import LanguageSelect from "../../../common/components/common/language/LanguageSelect";

class MemberPasswordResetRequest extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        chain: PropTypes.object,
        smsAccount: PropTypes.object,
        sendPasswordResetMessage: PropTypes.func,
        fetchCommercialChainWithoutAuthBySubdomain: PropTypes.func,
        fetchCompanySmsAccountBySubdomain: PropTypes.func,
    };

    componentDidMount() {
        const {fetchCommercialChainWithoutAuthBySubdomain, fetchCompanySmsAccountBySubdomain} = this.props;
        fetchCommercialChainWithoutAuthBySubdomain();
        fetchCompanySmsAccountBySubdomain();
    }

    render() {
        const {app, chain, smsAccount} = this.props;

        if (!chain || !smsAccount) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="forgotPassword"
                            defaultMessage={`Forgot password`}/>
                        ?
                    </Typography>
                    <PasswordResetRequestForm
                        defaultCountry={chain.language}
                        smsAccount={smsAccount}
                        onSubmit={this.props.sendPasswordResetMessage.bind(this, getCurrentSubdomain())}/>

                    <LanguageSelect/>
                </Paper>
            </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        chain: state.company.commercialChain,
        smsAccount: state.company.smsAccount,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendPasswordResetMessage,
        fetchCommercialChainWithoutAuthBySubdomain,
        fetchCompanySmsAccountBySubdomain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberPasswordResetRequest);
