import React from "react";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import PersonAdd from '@material-ui/icons/PersonAdd';
import ExitToApp from '@material-ui/icons/ExitToApp';
import LockOpen from '@material-ui/icons/LockOpen';
import Person from '@material-ui/icons/Person';
import Typography from "@material-ui/core/Typography";

import RenderTextField from "../common/RenderTextField";
import {required} from "../common/form/FormUtils";
import Button from "../common/Button";
import Container from "../common/Container";
import Paper from "../common/Paper";
import LanguageSelect from "../common/language/LanguageSelect";

const styles = theme => ({
    loginIcon: {
        marginRight: theme.spacing(1),
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        "& > :first-child": {
            marginTop: theme.spacing(1),
        },
        "& > :last-child": {
            marginLeft: theme.spacing(1),
            width: '100%'
        }
    },
    forgotPasswordLink: {
        textAlign: 'left',
        marginTop: theme.spacing(1),
    },
    register: {
        marginTop: theme.spacing(1),
    }
});

class LoginForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        submitting: PropTypes.bool,
        consumer: PropTypes.bool,
        passwordRequestUrl: PropTypes.string,
        handleSubmit: PropTypes.func
    };

    render() {
        const {classes, handleSubmit, submitting, passwordRequestUrl, consumer, history} = this.props;
        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Container size={Container.SIZE_TINY}>
                    <Paper padding>
                        <img
                            width="50%"
                            src='../../../../static/images/mobile-gif.svg'/>

                        <Typography variant="h5">
                            <FormattedMessage
                                id="signIn"
                                defaultMessage="Sign in"/>
                        </Typography>

                        <div className={classes.field}>
                            <Person/>
                            <Field
                                name="username"
                                hintText={<FormattedMessage
                                    id="login"
                                    defaultMessage="Login"/>}
                                component={RenderTextField}
                                helperText={<FormattedMessage
                                    id="enterYourEmailOrPhoneNumber"
                                    defaultMessage="Enter your email or phone number"/>}
                                type="text"
                                validate={[required]}/>
                        </div>

                        <div className={classes.field}>
                            <LockOpen/>
                            <Field
                                name="password"
                                hintText={<FormattedMessage
                                    id="password"
                                    defaultMessage="Password"/>}
                                helperText={<FormattedMessage
                                    id="enterYourPassword"
                                    defaultMessage="Enter your password"/>}
                                component={RenderTextField}
                                type="password"
                                validate={[required]}/>
                        </div>

                        <Button
                            icon={<ExitToApp/>}
                            label={<FormattedMessage
                                id="signIn"
                                defaultMessage="Sign in"/>}
                            type="submit"
                            disabled={submitting}/>

                        <Link
                            to={passwordRequestUrl}
                            className={classes.forgotPasswordLink}>
                            <Typography color="inherit">
                                <FormattedMessage
                                    id="forgotPassword"
                                    defaultMessage="Forgot password"/>
                                ?
                            </Typography>
                        </Link>

                        {consumer && (
                            <React.Fragment>
                                <div className={classes.register}>
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="dontHaveAnAccountYet"
                                            defaultMessage="Don't have an account yet?"/>
                                    </Typography>
                                    <Button
                                        icon={<PersonAdd/>}
                                        label={<FormattedMessage
                                            id="register"
                                            defaultMessage="Register"/>}
                                        color="secondary"
                                        onClick={() => history.push("/onboarding")}/>
                                </div>
                                <LanguageSelect/>
                            </React.Fragment>
                        )}
                    </Paper>
                </Container>
            </form>
        );
    }

}

export default reduxForm({form: 'loginForm'})(withStyles(styles)(LoginForm))
