import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import {required} from "../common/form/FormUtils";
import RenderTextField from "../common/RenderTextField";
import Button from "../common/Button";
import PhoneInput from "../common/form/PhoneInput";
import RenderRadioButtonGroup from "../common/form/RenderRadioButtonGroup";

import {emailValidation, phoneValidation} from "../../utils/karming-validators";
import {COUNTRY_CODES} from "../../utils/country-codes";

const validate = values => {
    return Object.assign(
        emailValidation({name: "email", value: values.email}),
        phoneValidation({name: "phone", value: values.phone}),
    )
};

const styles = theme => ({
    radioButtons: {
        flexDirection: 'row',
        marginTop: theme.spacing(1),
    },
    textWrapper: {
        "& > :not(:first-child)": {
            marginLeft: theme.spacing(0.5)
        }
    }
});

class PasswordResetRequestForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        smsAccount: PropTypes.object,
        email: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        phone: PropTypes.object,
        form: PropTypes.string,
        medium: PropTypes.string,
        defaultCountry: PropTypes.string,
        submitting: PropTypes.bool,
        pristine: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {classes, handleSubmit, submitting, smsAccount, form, defaultCountry, email, phone} = this.props;

        const shouldShowPhoneInput = Boolean(smsAccount && smsAccount.hasCredits);

        let medium = this.props.medium;
        if (!this.props.medium) {
            medium = "EMAIL";
        }
        return (

            <form onSubmit={handleSubmit}>
                {this.renderMessage(shouldShowPhoneInput)}

                {shouldShowPhoneInput && (
                    <Field
                        name="medium"
                        component={RenderRadioButtonGroup}
                        className={classes.radioButtons}
                        defaultValue="EMAIL">
                        <FormControlLabel
                            value="EMAIL"
                            control={<Radio/>}
                            label={<FormattedMessage
                                id="email"
                                defaultMessage="Email"/>}/>
                        <FormControlLabel
                            value="PHONE"
                            control={<Radio/>}
                            label={<FormattedMessage
                                id="phone"
                                defaultMessage="Phone"/>}/>
                    </Field>
                )}

                {((shouldShowPhoneInput && medium === "EMAIL") || !shouldShowPhoneInput) && (
                    <Field
                        name="email"
                        hintText={<FormattedMessage
                            id="email"
                            defaultMessage="Email"/>}
                        component={RenderTextField}
                        validate={shouldShowPhoneInput ? undefined : required}/>
                )}

                {shouldShowPhoneInput && medium === "PHONE" && (
                    <PhoneInput
                        defaultCountryCode={defaultCountry && COUNTRY_CODES[defaultCountry].code}
                        form={form}/>
                )}

                <Button
                    label={<FormattedMessage
                        id="submit"
                        defaultMessage="Submit"/>}
                    type="submit"
                    disabled={submitting || (medium === "EMAIL" && !email) || (medium === "PHONE" && !phone)}/>
            </form>
        );
    }

    // TODO TRANSLATION
    renderMessage(shouldShowPhoneInput) {
        const {classes} = this.props;

        if (shouldShowPhoneInput) {
            return (
                <div className={classes.textWrapper}>
                    <FormattedMessage
                        id="typeEmailOrPhone"
                        defaultMessage="Type email address or phone number"/>
                    <FormattedMessage
                        id="password-reset-request.sendMessageWithFurtherInstructions"
                        defaultMessage="and we‘ll send you a message with further instructions."/>
                </div>
            );
        }
        return (
            <div className={classes.textWrapper}>
                <FormattedMessage
                    id="typeEmail"
                    defaultMessage="Type email address"/>
                <FormattedMessage
                    id="password-reset-request.sendMessageWithFurtherInstructions"
                    defaultMessage="and we‘ll send you a message with further instructions."/>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('passwordResetRequestForm');
    const {medium, email, phone} = selector(state, 'medium', 'email', 'phone');
    return {
        medium,
        email,
        phone
    };
}

export default reduxForm({
    form: 'passwordResetRequestForm',
    validate
})(connect(mapStateToProps, null)(withStyles(styles)(PasswordResetRequestForm)))
