export function getCurrentSubdomain() {
    const subdomain = window.location.hostname.split(".", 1)[0];

    if (subdomain === 'lab' || subdomain === 'kundklubb' || subdomain === 'localhost') {
        throw 'Missing valid subdomain'
    }

    console.log('Chain current subdomain:', subdomain);

    return subdomain;
}