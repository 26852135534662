import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Save from '@material-ui/icons/Save';
import FastForward from '@material-ui/icons/FastForward';
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import RenderTextField from "../../../common/components/common/RenderTextField";
import RenderDatePicker from "../../../common/components/common/form/RenderDatePicker";
import RenderRadioButtonGroup from "../../../common/components/common/form/RenderRadioButtonGroup";

import {RequiredInfo} from "../../../common/components/common/form/FormUtils";

import {HOME_REDIRECT_URL} from "../../../../static/utils/redirect-const";

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
    genderTitle: {
        marginTop: theme.spacing(2)
    }
});

class ConsumerCompleteInformationForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        pristine: PropTypes.bool,
        history: PropTypes.object
    };

    render() {
        const {classes, handleSubmit, submitting, history, pristine} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5">
                    <FormattedMessage
                        id="completeRegistration"
                        defaultMessage="Complete registration"/>
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        id="pleaseProvideAdditionalData"
                        defaultMessage="Complementary details ease tailored offerings and account recovering"/>
                </Typography>

                <Field
                    name="street"
                    component={RenderTextField}
                    type="text"
                    hintText={<FormattedMessage
                        id="address"
                        defaultMessage="Address"/>}/>

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="city"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="city"
                            defaultMessage="City"/>}/>
                    <Field
                        name="zipCode"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="zipCode"
                            defaultMessage="Zip code"/>}/>
                </div>

                <Field
                    name="birthday"
                    component={RenderDatePicker}
                    hintText={<FormattedMessage
                        id="dateOfBirth"
                        defaultMessage="Date of birth"/>}
                    maxDate={new Date()}
                    clearable={true}/>

                <Typography
                    variant="subtitle1"
                    className={classes.genderTitle}>
                    <FormattedMessage
                        id="gender"
                        defaultMessage="Gender"/>
                </Typography>

                <Field
                    name="gender"
                    component={RenderRadioButtonGroup}
                    clearable="true">
                    <FormControlLabel
                        value="FEMALE"
                        control={<Radio/>}
                        label={<FormattedMessage
                            id="female"
                            defaultMessage="Female"/>}/>

                    <FormControlLabel
                        value="MALE"
                        control={<Radio/>}
                        label={<FormattedMessage
                            id="male"
                            defaultMessage="Male"/>}/>

                    <FormControlLabel
                        value="OTHER"
                        control={<Radio/>}
                        label={<FormattedMessage
                            id="other"
                            defaultMessage="Other"/>}/>
                </Field>

                <Button
                    icon={<FastForward/>}
                    label={<FormattedMessage
                        id="later" ompone
                        defaultMessage="Later"/>}
                    color={'secondary'}
                    onClick={() => history.push(HOME_REDIRECT_URL)}/>

                <Button
                    icon={<Save/>}
                    label={<FormattedMessage
                        id="save"
                        defaultMessage="Save"/>}
                    type="submit"
                    disabled={submitting || pristine}/>
                <RequiredInfo/>
            </form>
        );
    }

}

export default reduxForm({
    form: 'consumerCompleteInformation',
})(withStyles(styles)(ConsumerCompleteInformationForm));
