import axios from "axios";
import {AUTH_USER, LOADING} from "../../common/actions/types";
import {apiError} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";
import {ROLE_CONSUMER} from "../../common/components/auth/roles";
import {fetchCommercialChain} from "../../kundklubb/actions/consumer-actions";
import {getCurrentSubdomain} from "./kundklubb-utils-actions";
import {fetchHomeLocation} from "./consumer-actions";
import {FormattedMessage} from "react-intl";
import React from "react";

export function loginMember(values, history, redirectAfterLoginUrl) {
    return dispatch => {
        dispatch({type: LOADING});

        const {username, password} = values

        axios.post(`${ROOT_URL}/auth/${getCurrentSubdomain()}`, {username, password})
            .then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', response.data.role);

                dispatch({
                    type: AUTH_USER,
                    payload: response.data.role
                });

                if (response.data.role === ROLE_CONSUMER) {
                    dispatch(fetchHomeLocation())
                    dispatch(fetchCommercialChain())
                }

                history.push(redirectAfterLoginUrl || '');
            })
            .catch(() => dispatch(apiError(
                <FormattedMessage
                    id='incorrectUsernameOrPassword'
                    defaultMessage='Incorrect username or password'
                />
            )));
    };
}

