export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const SEND_PASSWORD_RESET_MAIL = 'SEND_PASSWORD_RESET_MAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = "UPDATE_USER";
export const ADMIN_UPDATE_USER = "ADMIN_UPDATE_USER";
export const FETCH_MAX_POINTS_BALANCE = "FETCH_MAX_POINTS_BALANCE";
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_STATUS = 'UPDATE_CAMPAIGN_STATUS';
export const CONSUMER_FETCH_CAMPAIGNS = 'CONSUMER_FETCH_CAMPAIGNS';
export const FETCH_CAMPAIGN = 'FETCH_CAMPAIGN';
export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
export const FETCH_COUPON = 'FETCH_COUPON';
export const FETCH_COUPONS = 'FETCH_COUPONS';
export const CREATE_COUPON = 'CREATE_COUPON';
export const CONSUMER_FETCH_STAMPCARDS = 'CONSUMER_FETCH_STAMPCARDS';
export const FETCH_STAMPCARDS_WITHOUT_AUTH = 'FETCH_STAMPCARDS_WITHOUT_AUTH';
export const CONSUMER_FETCH_STAMPCARDS_COUPONS = 'CONSUMER_FETCH_STAMPCARDS_COUPONS';
export const CONSUMER_FETCH_STAMPCARD_COUPON = 'CONSUMER_FETCH_STAMPCARD_COUPON';
export const FETCH_CONSUMER_STAMPCARD_COUPONS = 'FETCH_CONSUMER_STAMPCARD_COUPONS';
export const FETCH_CONSUMER_STAMPCARD_COUPON_USAGES = 'FETCH_CONSUMER_STAMPCARD_COUPON_USAGES';
export const FETCH_CONSUMER_POINTS = 'FETCH_CONSUMER_POINTS';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const FETCH_SENDOUT_HISTORY = 'FETCH_SENDOUT_HISTORY';
export const FETCH_COUPON_WITHOUT_AUTH = 'FETCH_COUPON_WITHOUT_AUTH';
export const UPDATE_COUPON_STATUS = 'UPDATE_COUPON_STATUS';
export const FETCH_POINTS_VALIDATION_DATE = 'FETCH_POINTS_VALIDATION_DATE';
export const FETCH_VALID_PURCHASES = 'FETCH_VALID_PURCHASES';
export const FETCH_CONSUMER_CAMPAIGNS = 'FETCH_CONSUMER_CAMPAIGNS';
export const FETCH_SMART_SELECTION = 'FETCH_SMART_SELECTION';
export const FETCH_STAMPCARD = 'FETCH_STAMPCARD';
export const FETCH_STAMPCARDS = 'FETCH_STAMPCARDS';
export const FETCH_STAMPCARD_USAGES = 'FETCH_STAMPCARD_USAGES';
export const CREATE_STAMPCARD = 'CREATE_STAMPCARD';
export const UPDATE_STAMPCARD = 'UPDATE_STAMPCARD';
export const CREATE_STAMPCARD_COUPON = 'CREATE_STAMPCARD_COUPON';
export const UPDATE_STAMPCARD_COUPON = 'UPDATE_STAMPCARD_COUPON';
export const SET_CURRENT_SMART_SELECTION = 'SET_CURRENT_SMART_SELECTION';
export const FILTER_MEMBERS_FOR_CAMPAIGNS = 'FILTER_MEMBERS_FOR_CAMPAIGNS';
export const PUBLISH_CAMPAIGN = 'PUBLISH_CAMPAIGN';
export const FETCH_HOME_LOCATION = 'FETCH_HOME_LOCATION';
export const MEMBERSHIP_CONDITIONS = "MEMBERSHIP_CONDITIONS";
export const PRIVACY_POLICY = "PRIVACY_POLICY";
export const FETCH_PRIVACY_POLICY = 'FETCH_PRIVACY_POLICY';
export const FETCH_MEMBERSHIP_CONDITIONS = 'FETCH_MEMBERSHIP_CONDITIONS';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const REACTIVE_CATEGORY = 'REACTIVE_CATEGORY';
export const FETCH_SMS_HISTORY = 'FETCH_SMS_HISTORY';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_MERCHANTS = 'FETCH_MERCHANTS';
export const FETCH_MERCHANT = 'FETCH_MERCHANT';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_POINT_OF_SALE_WITHOUT_AUTH = 'FETCH_POINT_OF_SALE_WITHOUT_AUTH';
export const FETCH_CLUB_POINTS_WITHOUT_AUTH = 'FETCH_CLUB_POINTS_WITHOUT_AUTH';
export const FETCH_BILLING_DETAILS = 'FETCH_BILLING_DETAILS';
export const UPDATE_BILLING_DETAILS = 'UPDATE_BILLING_DETAILS';
export const COMPLETE_GUIDE = 'COMPLETE_GUIDE';
export const ONBOARDING_MERCHANT = 'ONBOARDING_MERCHANT';
export const ONBOARDING_FIND_COMPANY = "ONBOARDING_FIND_COMPANY";
export const ONBOARDING_CONSUMER = 'ONBOARDING_CONSUMER';
export const ONBOARDING_VERIFICATION = 'ONBOARDING_VERIFICATION';
export const REGISTER_PURCHASE = 'REGISTER_PURCHASE';
export const SET_POS_AS_HOME_LOCATION = 'SET_POS_AS_HOME_LOCATION';
export const CREATE_CLUB_POINTS = 'CREATE_CLUB_POINTS';
export const FETCH_CLUB_POINTS = 'FETCH_CLUB_POINTS';
export const ACTIVATE_CLUB_POINTS = 'ACTIVATE_CLUB_POINTS';
export const UPDATE_CLUB_POINTS = 'UPDATE_CLUB_POINTS';
export const DEACTIVATE_CLUB_POINTS = 'DEACTIVATE_CLUB_POINTS';
export const FETCH_CONSUMER = "FETCH_CONSUMER";
export const FETCH_CONSUMERS = "FETCH_CONSUMERS";
export const FETCH_PURCHASE = "FETCH_PURCHASE";
export const FETCH_PURCHASES = "FETCH_PURCHASES";
export const FETCH_CONSUMERS_NUMBER = "FETCH_CONSUMERS_NUMBER";
export const MERCHANT_FETCH_MERCHANTS = "MERCHANT_FETCH_MERCHANTS";
export const MERCHANT_FETCH_MERCHANT = "MERCHANT_FETCH_MERCHANT";
export const MERCHANT_REMOVE_MERCHANT = "MERCHANT_REMOVE_MERCHANT";
export const SENDOUT_CAMPAIGN = "SENDOUT_CAMPAIGN";
export const MERCHANT_UPDATE_MEMBER = "MERCHANT_UPDATE_MEMBER";
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const REMOVE_PURCHASE = 'REMOVE_PURCHASE';
export const REMOVE_CONSUMER = 'REMOVE_CONSUMER';
export const REMOVE_MERCHANT = 'REMOVE_MERCHANT';
export const FETCH_COMPANY = "FETCH_COMPANY";
export const INVITE_MERCHANT = "INVITE_MERCHANT";
export const CONSUMER_FETCH_COMMERCIAL_CHAIN = "CONSUMER_FETCH_COMMERCIAL_CHAIN";
export const FETCH_COMMERCIAL_CHAIN_PURCHASES = "FETCH_POS_PURCHASES";
export const CREATE_POINT_OF_SALE = 'CREATE_POINT_OF_SALE';
export const UPDATE_POINT_OF_SALE = 'UPDATE_POINT_OF_SALE';
export const FETCH_MERCHANT_POINT_OF_SALE = 'FETCH_MERCHANT_POINT_OF_SALE';
export const FETCH_MERCHANT_CURRENT_POINT_OF_SALE = 'FETCH_MERCHANT_CURRENT_POINT_OF_SALE';
export const FETCH_MERCHANT_POINTS_OF_SALE = 'FETCH_MERCHANT_POINTS_OF_SALE';
export const UPDATE_COMMERCIAL_CHAIN = "UPDATE_COMMERCIAL_CHAIN";
export const CREATE_COMMERCIAL_CHAIN = "CREATE_COMMERCIAL_CHAIN";
export const FETCH_MERCHANT_COMMERCIAL_CHAINS = "FETCH_MERCHANT_COMMERCIAL_CHAINS";
export const FETCH_MERCHANT_COMMERCIAL_CHAIN = "FETCH_MERCHANT_COMMERCIAL_CHAIN";
export const SET_SIDEBAR_VISIBILITY = "SET_SIDEBAR_VISIBILITY";
export const SET_START_GUIDE_VISIBILITY = "SET_START_GUIDE_VISIBILITY";
export const SET_ACCOUNT_MENU_VISIBILITY = "SET_ACCOUNT_MENU_VISIBILITY";
export const LOADING = 'LOADING';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const API_ERROR = 'API_ERROR';
export const FETCH_ADMIN_COMPANY = "FETCH_ADMIN_COMPANY";
export const FETCH_ADMIN_COMMERCIAL_CHAINS = "FETCH_ADMIN_COMMERCIAL_CHAINS";
export const FETCH_COMPANY_WITHOUT_AUTH = 'FETCH_COMPANY_WITHOUT_AUTH';
export const FETCH_COMMERCIAL_CHAIN_WITHOUT_AUTH = "FETCH_COMMERCIAL_CHAIN_WITHOUT_AUTH";
export const FETCH_SMS_ACCOUNT_WITHOUT_AUTH = "FETCH_SMS_ACCOUNT_WITHOUT_AUTH";
export const FETCH_CAMPAIGN_WITHOUT_AUTH = 'FETCH_CAMPAIGN_WITHOUT_AUTH';
export const FETCH_CAMPAIGNS_WITHOUT_AUTH = 'FETCH_CAMPAIGNS_WITHOUT_AUTH';
export const REGISTER_CREDIT_CARD = "REGISTER_CREDIT_CARD";
export const REMOVE_CREDIT_CARD = "REMOVE_CREDIT_CARD";
export const REGISTER_NUMBER = "REGISTER_NUMBER";
export const API_SUCCESS = "API_SUCCESS";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const CONSUMER_FETCH_POINTS_OF_SALE = "CONSUMER_FETCH_POINTS_OF_SALE";
