export default {
    "account": "Compte",
    "add": "Ajouter",
    "address": "Adresse",
    "and": "et",
    "balance": "Balance",
    "campaigns": "Campagnes",
    "cancel": "Annuler",
    "categories": "Catégories",
    "city": "Ville",
    "close": "Proche",
    "completeRegistration": "Terminez votre inscription",
    "confirm": "Confirmer",
    "confirmAndBecomeMember": "Confirmez et devenez membre",
    "confirmPassword": "Confirmez le mot de passe",
    "confirmationLinkText": "Un message avec un lien d'activation a été envoyé au {contactData} que vous avez fourni.",
    "congrats": "Félicitations!",
    "consumer-export-data.exportDataText": "Pour exporter les données, envoyez un e-mail.",
    "consumer-onboarding-verification-form.chooseYourPasswordText": "Choisissez votre mot de passe et remplissez vos données.",
    "consumer-terminate.terminateAccountText": "Pour mettre fin à votre compte s'il vous plaît envoyez un e-mail.",
    "contact": "Contact",
    "dateFromOrDateToIsRequired": "Start date or end date is required",
    "dateOfBirth": "Date de naissance",
    "dateOfPurchase": "Date d'achat",
    "dontHaveAnAccountYet": "Vous n'avez pas encore de compte?",
    "earnedPoints": "Points Gagnés",
    "edit": "Éditer",
    "editProfile": "Éditer le profil",
    "email": "E-mail",
    "emailAddress": "Adresse électronique",
    "emailOrPhoneRequired": "Email ou téléphone est requis",
    "enterYourEmailOrPhoneNumber": "Entrez votre e-mail ou votre numéro de téléphone.",
    "enterYourPassword": "Tapez votre mot de passe",
    "errors.maxCannotBeBiggerThanDefaultValue": "Ne doit pas être supérieur à la valeur maximale disponible",
    "errors.maxCannotBeLessThanMin": "Ne doit pas être inférieur au minimum.",
    "errors.minCannotBeBiggerThanMax": "Ne doit pas être supérieur à la valeur maximale.",
    "errors.minCannotBeLessThanDefaultValue": "Ne doit pas être inférieur à la valeur minimale disponible.",
    "exportData": "Exporter des données",
    "female": "Femelle",
    "friday": "Vendredi",
    "firstName": "Prénom",
    "forgotPassword": "Mot de passe oublié",
    "from": "De",
    "gender": "Le sexe",
    "getOut": "Afficher",
    "guide": "GIUDE",
    "haveReadAndAgreeText": "J'ai lu et accepte les",
    "hide": "Cache",
    "home": "Accueil",
    "homeLocationSelection": "Choisissez votre emplacement standard",
    "incorrectUsernameOrPassword": "Identifiant ou mot de passe incorrect.",
    "invalidEmailAddress": "Adresse e-mail invalide",
    "invalidPhoneNumber": "Numéro de téléphone invalide",
    "lastName": "Nom de famille",
    "later": "Plus tard",
    "login": "Connectez-vous",
    "loginToAccessAccount": "Connectez-vous ou inscrivez-vous pour accéder à votre compte et utiliser les offres!",
    "logout": "Déconnexion",
    "male": "Mâle",
    "manageMembership": "Gérer l'adhésion",
    "marketingMessages": "Messages de marketing",
    "max": "Max",
    "monday": "Lundi",
    "member": "Membre",
    "membershipCard": "Carte de Membre",
    "membershipConditions": "Conditions d'adhésion",
    "min": "Min",
    "mobileNumber": "Numéro de portable",
    "myHomeLocation": "Mon emplacement standard",
    "no": "Non",
    "passwordResetFailNoUser": "Aucun message de réinitialisation de mot de passe n'a été envoyé. Il n'y a pas d'utilisateur avec les informations de contact correspondantes.",
    "openingHours": "Heures d'ouverture",
    "or": "ou",
    "other": "Autre",
    "pageNotFound": "Page non trouvée.",
    "password": "Mot de passe",
    "passwordChanged": "Mot de passe changé",
    "password-reset-form.typeYourNewPasswordTwice": "Tapez votre nouveau mot de passe deux fois",
    "password-reset-request.sendMessageWithFurtherInstructions": " et nous vous enverrons un message avec des instructions supplémentaires.",
    "passwordResetSuccess": "Un message de réinitialisation du mot de passe a été envoyé. Ouvrez le message et cliquez sur le lien pour réinitialiser votre mot de passe.",
    "phone": "Téléphone",
    "phoneNumber": "Numéro de téléphone",
    "pleaseCheckText": "Veuillez vérifier votre  {contactType}  et cliquez sur le lien pour compléter votre inscription.",
    "pleaseProvideAdditionalData": "Détails supplémentaires pour des offres personnalisées et pour faciliter la récupération de compte.",
    "points": "Points",
    "privacyPolicy": "Politique de confidentialité",
    "profile": "Profil",
    "providedShopNotExists": "Le lieu de vente sélectionné n'existe pas.",
    "purchaseDetails": "Les détails d'achat",
    "purchases": "Achats",
    "redeemedCoupons": "Coupons échangés.",
    "register": " S'inscrire",
    "remove": "Supprimer",
    "required": "Obligatoire",
    "save": "Enregistrer",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "setAsHomeLocation": "Définir comme emplacement standard",
    "showHistory": "Afficher l'histoire",
    "signIn": "Connexion",
    "signUp": "S'inscrire",
    "stampCardWillBeValidFor": "La carte de timbre sera valable 12 mois à compter de la première collecte.",
    "start": "DÉBUT",
    "submit": "Confirmer",
    "terminateAccount": "Mettre fin à l'adhésion",
    "termsOfTheMembership": "Conditions d'adhésion",
    "thankYouForYourRegistration": "Merci pour votre inscription!",
    "till": "à",
    "tuesday": "Mardi",
    "thursday": "Jeudi",
    "totalAmountOfSale": "Les ventes",
    "totalAmountPaid": "Montant total payé",
    "tradeInFor": "Échangez contre",
    "tradedPoints": "Points échangés",
    "typeEmail": "Entrer l'adresse e-mail",
    "typeEmailOrPhone": "Entrez votre adresse e-mail ou votre numéro de téléphone",
    "unsubscribe": "Se désabonner",
    "usages": "Coutumes",
    "wednesday": "Mercredi",
    "valid": "(valable X mois",
    "validTo": "Valide pour",
    "validToDate": "Valide pour {date}",
    "whenVisible": "Lorsque visible",
    "yes": "Oui",
    "zipCode": "Code postal",
}