import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import PasswordResetForm from "./PasswordResetForm";

import {resetPassword} from "../../actions/auth-actions";

class PasswordReset extends React.Component {

    static propTypes = {
        match: PropTypes.object,
        history: PropTypes.object,
        resetPassword: PropTypes.func
    };

    passValues(values) {
        this.props.resetPassword(values, this.props.match.params.id, this.props.history)
    }

    render() {
        return (
            <PasswordResetForm onSubmit={this.passValues.bind(this)}/>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({resetPassword}, dispatch);
}

export default connect(null, mapDispatchToProps)(PasswordReset);
