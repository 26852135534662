import React from "react";
import classnames from "classnames";

import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles/index";

const useStyles = makeStyles((theme => ({
    root: {
        marginTop: 36
    },
    title: {
        padding: theme.spacing(2),
    },
    content: {
        display: '-webkit-box',
        justifyContent: 'center'
    },
    contentText: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    actions: {
        justifyContent: 'center'
    },
    button: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1)
    }
})));

const KarmingDialog = (props) => {

    const {
        actions, headerActions, content, title, open, rootClassName, rootClasses, dialogContentClassName,
        actionsClassName, onCancel, hasCloseButton, ...rest
    } = props;
    const classes = useStyles();

    return (
        <Dialog
            {...rest}
            classes={rootClasses}
            className={rootClassName ? rootClassName : classes.root}
            open={open}>

            <div className={classes.content}>
                {hasCloseButton && (
                    <IconButton
                        className={classes.button}
                        onClick={() => onCancel()}>
                        <Close color="secondary"/>
                    </IconButton>
                )}
                {headerActions}
                {title && (
                    <DialogTitle className={classes.title}>
                        {title}
                    </DialogTitle>
                )}
            </div>

            {content && (
                <DialogContent className={classnames(dialogContentClassName, classes.contentText)}>
                    {content}
                </DialogContent>
            )}

            {actions && (
                <DialogActions className={classnames(classes.actions, actionsClassName)}>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    )

};

export default KarmingDialog
