import React from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {addMonths, isBefore, subDays} from 'date-fns'

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

import {getTranslatedDate} from "../../../common/utils/date-utils";
import {SWEDISH} from "../../../common/utils/enums/languages";

const useStyles = makeStyles(theme => ({
    textBold: {
        fontWeight: 'bold'
    },
    table: {
        marginTop: theme.spacing(1),
        width: '100%',
        '& > tr, th': {
            width: '50%'
        }
    }
}));

const PurchasePointsDetails = props => {
    const {purchase, language, monthsValidation} = props;
    const classes = useStyles();
    const dateLanguage = language || SWEDISH;

    const notReturnedUsages = purchase.pointsUsages
        .filter(pointsUsage => pointsUsage.returnPointsDate === null);
    const notReturnedUsagesPoints = notReturnedUsages
        .reduce((previousPoints, nextUsage) => previousPoints + nextUsage.usedPoints, 0);

    const pointsValidDate = subDays(addMonths(purchase.dateOfPurchase, monthsValidation), 1);
    const pointsAvailable = purchase.earnedPoints - notReturnedUsagesPoints;
    const isPointsOverdue = isBefore(pointsValidDate, new Date());

    return (
        <div>
            <Typography>
                <FormattedMessage
                    id="whereofUsed"
                    defaultMessage="whereof used"/>: -
                {notReturnedUsagesPoints || 0}p
            </Typography>
            <Typography>
                <FormattedMessage
                    id="whereofFallenDue"
                    defaultMessage="whereof fallen due"/>: -
                {isPointsOverdue ? pointsAvailable : 0}p
            </Typography>
            {pointsAvailable > 0 && (
                <table className={classes.table}>
                    <tbody>
                    <Typography component="tr">
                        <th>
                            <FormattedMessage
                                id="sum"
                                defaultMessage="Sum"/>
                        </th>
                        <th>
                            <FormattedMessage
                                id="validUntil"
                                defaultMessage="Valid until"/>
                        </th>
                    </Typography>

                    <Typography component="tr">
                        <td>
                            {pointsAvailable}p
                        </td>
                        <td>
                            {getTranslatedDate(pointsValidDate, dateLanguage)}
                        </td>
                    </Typography>
                    </tbody>
                </table>
            )}
            {notReturnedUsages.length > 0 && (
                <table className={classes.table}>
                    <tbody>
                    <Typography component="tr">
                        <th>
                            <FormattedMessage
                                id="sum"
                                defaultMessage="Sum"/>
                        </th>
                        <th>
                            <FormattedMessage
                                id="usedBy"
                                defaultMessage="Used by"/>
                        </th>
                    </Typography>

                    {notReturnedUsages.map((usage, usageIndex) => {
                        return (
                            <Typography
                                key={usageIndex}
                                component="tr">
                                <td>
                                    {-usage.usedPoints}p
                                </td>
                                <td>
                                    {getTranslatedDate(usage.usedPointsDate, dateLanguage)}
                                </td>
                            </Typography>
                        )
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

PurchasePointsDetails.propTypes = {
    purchase: PropTypes.object,
    monthsValidation: PropTypes.number,
    language: PropTypes.string
};

export default PurchasePointsDetails;
