import {
    ADMIN_UPDATE_USER,
    FETCH_ADMIN_COMMERCIAL_CHAINS,
    FETCH_ADMIN_COMPANY,
    FETCH_COMPANIES,
    FETCH_MERCHANT,
    FETCH_MERCHANTS
} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_MERCHANTS:
            return {...state, merchants: action.payload};
        case FETCH_COMPANIES:
            return {...state, companies: action.payload};
        case FETCH_MERCHANT:
            return {...state, merchant: action.payload};
        case ADMIN_UPDATE_USER:
            return {...state, user: action.payload};
        case FETCH_ADMIN_COMPANY:
            if (!action.payload) {
                return {...state, company: {name: 'Non Existing'}};
            }
            return {...state, company: action.payload};
        case FETCH_ADMIN_COMMERCIAL_CHAINS:
            if (!action.payload) {
                return {...state, commercialChains: {name: 'Non Existing'}};
            }
            return {...state, commercialChains: action.payload};
    }

    return state;
}
