import {
    addDays,
    addMonths,
    endOfDay,
    format,
    getYear,
    setMilliseconds,
    startOfDay,
    startOfMonth,
    subDays
} from "date-fns";
import {DATE_LOCALES, ENGLISH} from "./enums/languages";

export const DATE_FORMAT = 'yyyy/MM/dd';
export const DATE_FORMAT_TRANSLATED = 'd MMMM yyy';
export const TIME_FORMAT = 'hh:mm';

export function getFormattedDate(date) {
    return date && format(date, DATE_FORMAT);
}

export function getTranslatedDate(date, language) {
    return date && format(date, DATE_FORMAT_TRANSLATED, {locale: DATE_LOCALES[language || ENGLISH]});
}

export function getTime(date) {
    return date && format(date, TIME_FORMAT);
}

export function addMonthsToDate(date, numberOfMonths) {
    return addMonths(date, numberOfMonths)
}

export function addDaysToDate(date, numberOfDays) {
    return addDays(date, numberOfDays)
}

export function subtractDaysFromDate(date, numberOfDays) {
    return subDays(date, numberOfDays)
}

export function startOfDayForDate(date) {
    return startOfDay(date)
}

export function endOfDayForDate(date) {
    return setMilliseconds(endOfDay(date), 0)
}

export function getCurrentYear() {
    return getYear(new Date())
}

export function getStartOfMonth() {
    return startOfMonth(new Date())
}

export function getDefaultStartAndEndDateForSendoutHistory() {
    const now = new Date();
    const fourteenDaysBefore = subDays(now, 14);
    return {startDate: fourteenDaysBefore, endDate: now};
}

export function now() {
    return new Date();
}

export function nowTimestamp() {
    return now().getTime();
}
