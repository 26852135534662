import React from 'react'
import PropTypes from "prop-types"

import withStyles from "@material-ui/core/styles/withStyles";

const SIZE_TINY = "38.2%";
const SIZE_SMALL = "50.0%";
const SIZE_MEDIUM = "61.8%";
const SIZE_LARGE = "73.6%";
const SIZE_HUGE = "85.4%";
const SIZE_FULL = "97.2%";

const styles = theme => ({
    root: {
        margin: "auto",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100% !important",
            margin: 0
        }
    },
    rootSizeTiny: {
        width: SIZE_TINY,
        [theme.breakpoints.down("lg")]: {width: SIZE_SMALL},
        [theme.breakpoints.down("md")]: {width: SIZE_MEDIUM},
        [theme.breakpoints.down("sm")]: {width: SIZE_LARGE},
    },
    rootSizeSmall: {
        width: SIZE_SMALL,
        [theme.breakpoints.down("lg")]: {width: SIZE_MEDIUM},
        [theme.breakpoints.down("md")]: {width: SIZE_LARGE},
        [theme.breakpoints.down("sm")]: {width: SIZE_HUGE},
    },
    rootSizeMedium: {
        width: SIZE_MEDIUM,
        [theme.breakpoints.down("lg")]: {width: SIZE_LARGE},
        [theme.breakpoints.down("md")]: {width: SIZE_HUGE},
        [theme.breakpoints.down("sm")]: {width: SIZE_FULL},
    },
    rootSizeLarge: {
        width: SIZE_LARGE,
        [theme.breakpoints.down("lg")]: {width: SIZE_HUGE},
        [theme.breakpoints.down("md")]: {width: SIZE_FULL},
    },
    rootSizeHuge: {
        width: SIZE_HUGE,
        [theme.breakpoints.down("lg")]: {width: SIZE_FULL},
    },
    rootSizeFull: {
        width: SIZE_FULL
    },
    rootAlignTop: {
        alignSelf: "flex-start"
    },
    rootAlignMiddle: {
        alignSelf: "center"
    },
    rootAlignBottom: {
        alignSelf: "flex-end"
    },
    rootAlignLeft: {
        textAlign: "left"
    },
    rootAlignRight: {
        alignSelf: "right"
    },
    rootMarginSmall: {
        [theme.breakpoints.up("sm")]: {
            margin: theme.spacing(1),
        }
    },
    rootMarginMedium: {
        [theme.breakpoints.up("sm")]: {
            margin: theme.spacing(2)
        }
    },
    rootMarginBig: {
        [theme.breakpoints.up("sm")]: {
            margin: theme.spacing(3)
        }
    },
});

class KarmingContainer extends React.Component {

    static SIZE_TINY = "tiny";
    static SIZE_SMALL = "small";
    static SIZE_MEDIUM = "medium";
    static SIZE_LARGE = "large";
    static SIZE_HUGE = "huge";
    static SIZE_FULL = "full";

    static ALIGN_TOP = "top";
    static ALIGN_MIDDLE = "middle";
    static ALIGN_BOTTOM = "bottom";
    static ALIGN_RIGHT = "right";
    static ALIGN_LEFT = "left";

    static MARGIN_SMALL = "small";
    static MARGIN_MEDIUM = "medium";
    static MARGIN_BIG = "big";

    render() {
        const {children} = this.props;

        return (
            <div className={this.determineClassName()}>
                {children}
            </div>
        )
    }

    determineClassName() {
        const {classes, size, align, margin} = this.props;
        return [classes.root, classes[SIZE_CLASSES[size]], classes[ALIGN_CLASSES[align]], classes[MARGIN_CLASSES[margin]]].join(" ");
    }

}

const SIZE_CLASSES = {
    [KarmingContainer.SIZE_TINY]: "rootSizeTiny",
    [KarmingContainer.SIZE_SMALL]: "rootSizeSmall",
    [KarmingContainer.SIZE_MEDIUM]: "rootSizeMedium",
    [KarmingContainer.SIZE_LARGE]: "rootSizeLarge",
    [KarmingContainer.SIZE_HUGE]: "rootSizeHuge",
    [KarmingContainer.SIZE_FULL]: "rootSizeFull"
};

const ALIGN_CLASSES = {
    [KarmingContainer.ALIGN_TOP]: "rootAlignTop",
    [KarmingContainer.ALIGN_MIDDLE]: "rootAlignMiddle",
    [KarmingContainer.ALIGN_BOTTOM]: "rootAlignBottom",
    [KarmingContainer.ALIGN_RIGHT]: "rootAlignRight",
    [KarmingContainer.ALIGN_LEFT]: "rootAlignLeft"
};

const MARGIN_CLASSES = {
    [KarmingContainer.MARGIN_SMALL]: "rootMarginSmall",
    [KarmingContainer.MARGIN_MEDIUM]: "rootMarginMedium",
    [KarmingContainer.MARGIN_BIG]: "rootMarginBig",
};

KarmingContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOf(Object.keys(SIZE_CLASSES)),
    align: PropTypes.oneOf(Object.keys(ALIGN_CLASSES)),
    margin: PropTypes.oneOf(Object.keys(MARGIN_CLASSES)),
};

KarmingContainer.defaultProps = {
    size: KarmingContainer.SIZE_MEDIUM,
    align: KarmingContainer.ALIGN_TOP
};

export default withStyles(styles)(KarmingContainer);
