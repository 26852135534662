import React from "react";
import PropTypes from "prop-types"

import withStyles from "@material-ui/core/styles/withStyles";

import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(4)
        },
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none'
        },
    },
    padding: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2)
        }
    }
});

class KarmingPaper extends React.Component {
    render() {
        const {children, classes, padding} = this.props;

        const paperClass = [classes.root, padding && classes.padding].join(" ");

        return (
            <Paper
                elevation={1}
                classes={{root: paperClass}}>
                {children}
            </Paper>
        )
    }
}

KarmingPaper.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    padding: PropTypes.bool
};

KarmingPaper.defaultProps = {
    padding: false
};

export default withStyles(styles)(KarmingPaper)
