import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const styles = theme => ({
    root: {
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        color: theme.palette.secondary.main,
        fontSize: 36,
        [theme.breakpoints.down('xs')]: {
            left: `-${theme.spacing(2)}px`
        },
        "&:hover": {
            opacity: 0.6,
            cursor: 'pointer'
        }
    }
});

class TitleWithBackButton extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.object.isRequired,
        onBackClick: PropTypes.func
    };

    render() {
        const {title, onBackClick, classes} = this.props;
        return (
            <div className={classes.root}>
                {onBackClick && <ChevronLeft className={classes.icon} onClick={onBackClick}/>}

                <Typography
                    component="span"
                    paragraph
                    variant="h5">
                    {title}
                </Typography>
            </div>
        )
    }

}

export default withStyles(styles)(TitleWithBackButton)
