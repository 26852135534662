import React from "react";
import {Field} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@material-ui/styles";
import Add from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton/IconButton";

import Button from "../Button";
import Combobox from "./Combobox";

import {DAYS} from "../../../utils/enums/days";

const hours = ["0:00", "0:30", "1:00", "1:30", "2:00", "2:30", "3:00", "3:30", "4:00", "4:30", "5:00", "5:30", "6:00",
    "6:30", "7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30",
    "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30",
    "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"];

function prepareHours(hours) {
    return hours.map(key =>
        ({
            label: key,
            value: key
        })
    )
}

const useStyles = makeStyles((theme => ({
    wrapper: {
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(1),
        }
    },
    fields: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& > *:not(:first-child)": {
            marginLeft: theme.spacing(0.5)
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 13
        }
    },
    deleteIcon: {
        padding: theme.spacing(0.5),
        "& svg": {
            fontSize: theme.spacing(2)
        }
    }
})));

const OpeningHoursFields = ({fields}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                {fields.map((openingHours, index) => (
                    <div
                        key={index}
                        className={classes.fields}>
                        <Field
                            inputSize="big"
                            name={`${openingHours}.dayFrom`}
                            placeholder=""
                            options={DAYS}
                            component={Combobox}/>
                        <Field
                            inputSize="big"
                            clearable
                            name={`${openingHours}.dayTo`}
                            placeholder=""
                            options={DAYS}
                            component={Combobox}/>
                        <Field
                            inputSize="small"
                            name={`${openingHours}.timeFrom`}
                            placeholder=""
                            options={prepareHours(hours)}
                            component={Combobox}/>
                        <Field
                            inputSize="small"
                            name={`${openingHours}.timeTo`}
                            placeholder=""
                            options={prepareHours(hours)}
                            component={Combobox}/>

                        <IconButton
                            key="deleteOpeningHours"
                            className={classes.deleteIcon}
                            onClick={() => fields.remove(index)}>
                            <CloseIcon className={classes.icon}/>
                        </IconButton>
                    </div>
                ))}
            </div>
            <Button
                icon={<Add/>}
                color="secondary"
                onClick={() => fields.push({})}
                label={
                    <React.Fragment>
                        <FormattedMessage
                            id="add"
                            defaultMessage="Add"/>
                        &nbsp;
                        <span style={{textTransform: 'lowercase'}}>
                    <FormattedMessage
                        id="openingHours"
                        defaultMessage="Opening hours"/>
                    </span>
                    </React.Fragment>
                }/>
        </div>
    )
};

OpeningHoursFields.propTypes = {
    fields: PropTypes.object.isRequired
};

export default OpeningHoursFields
