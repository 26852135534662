import React from "react";
import {Field, reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Send from '@material-ui/icons/Send';
import PersonAdd from '@material-ui/icons/PersonAdd';

import RenderTextField from "../../../common/components/common/RenderTextField";
import Button from "../../../common/components/common/Button";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import PhoneInput from "../../../common/components/common/form/PhoneInput";

import {required, RequiredInfo} from "../../../common/components/common/form/FormUtils";
import {findCompany} from "../../../common/actions/onboarding-actions";
import {KARMING_PRIVACY_POLICY_ADDRESS} from "../../../common/config/karming-links";
import {
    companyNumberValidation,
    emailValidation,
    phoneValidation,
    privacyPolicyValidation
} from "../../../common/utils/karming-validators";
import {renderAgreementCheckbox} from "./MerchantOnboarding";

export const validate = (values) => {
    return Object.assign(
        companyNumberValidation({name: "companyNumber", value: values.companyNumber}),
        emailValidation({name: "email", value: values.email}),
        phoneValidation({name: "phone", value: values.phone}),
        privacyPolicyValidation({name: "privacyPolicy", value: values.privacyPolicy})
    )
};

export const MERCHANT_ONBOARDING_FORM_NAME = 'merchantOnboardingForm';

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
    companyDetailsWrapper: {
        marginTop: theme.spacing(2)
    }
});

// TODO used also in admin - remove coupling
class MerchantOnboardingForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        admin: PropTypes.bool,
        company: PropTypes.object,
        comapnyName: PropTypes.object,
        source: PropTypes.string,
        form: PropTypes.string,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        findCompany: PropTypes.func
    };


    render() {
        const {classes, handleSubmit, submitting, source, form} = this.props;

        const heading = !this.props.admin
            ? <FormattedMessage
                id="onboardingMerchant"
                defaultMessage="Onboarding Merchant"/>
            : <FormattedMessage
                id="onboarding"
                defaultMessage="Onboarding"/>;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Container size={Container.SIZE_SMALL}>
                    <Paper padding>
                        <Typography
                            variant="h5"
                            paragraph>
                            {heading}
                        </Typography>

                        {/*TODO REFACTOR */}
                        {source && source === "svenskhandel" && "Svensk Handel"}

                        <div className={classes.doubleFieldsWrapper}>
                            <Field
                                name="firstName"
                                hintText={
                                    <React.Fragment>
                                        <FormattedMessage
                                            id="firstName"
                                            defaultMessage="First name"/>
                                        &nbsp;*
                                    </React.Fragment>
                                }
                                component={RenderTextField}
                                type="text"
                                validate={[required]}/>

                            <Field
                                name="lastName"
                                hintText={
                                    <React.Fragment>
                                        <FormattedMessage
                                            id="lastName"
                                            defaultMessage="Last name"/>
                                        &nbsp;*
                                    </React.Fragment>
                                }
                                component={RenderTextField}
                                type="text"
                                validate={[required]}/>
                        </div>

                        <Field
                            name="email"
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="email"
                                        defaultMessage="Email"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            component={RenderTextField}
                            type="email"
                            validate={[required]}/>

                        <PhoneInput form={form}/>

                        <Field
                            name="website"
                            hintText={<FormattedMessage
                                id="website"
                                defaultMessage="Website"/>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="companyNumber"
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="companyNumber"
                                        defaultMessage="Company number"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            component={RenderTextField}
                            type="text"
                            validate={[required]}
                            onChange={this.handleChange.bind(this)}
                            onFocus={this.handleChange.bind(this)}
                            onBlur={this.handleChange.bind(this)}/>

                        {this.renderCompany()}
                        {this.renderToS()}

                        <Button
                            icon={this.props.admin
                                ? <Send/>
                                : <PersonAdd/>}
                            label={this.props.admin
                                ? <FormattedMessage
                                    id="invite"
                                    defaultMessage="Invite"/>
                                : <FormattedMessage
                                    id="signUp"
                                    defaultMessage="Sign up"/>}
                            type="submit"
                            disabled={submitting}/>

                        <RequiredInfo/>
                    </Paper>
                </Container>
            </form>
        );
    }

    renderCompany() {
        const {classes} = this.props;

        if (!this.props.company || !this.props.company.companyName) {
            return (
                <React.Fragment>
                    <Field
                        name="companyName"
                        hintText={
                            <React.Fragment>
                                <FormattedMessage
                                    id="companyName"
                                    defaultMessage="Company Name"/>
                                &nbsp;*
                            </React.Fragment>
                        }
                        component={RenderTextField}
                        type="text"
                        value={this.props.company && this.props.company.companyName}
                        validate={[required]}/>

                    <Field
                        name="postalAddress"
                        hintText={<FormattedMessage
                            id="address"
                            defaultMessage="Address"/>}
                        component={RenderTextField}
                        type="text"/>

                    <div className={classes.doubleFieldsWrapper}>
                        <Field
                            name="postalCity"
                            hintText={<FormattedMessage
                                id="city"
                                defaultMessage="City"/>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="postalZipCode"
                            hintText={<FormattedMessage
                                id="zipCode"
                                defaultMessage="Zip code"/>}
                            component={RenderTextField}
                            type="text"/>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <div className={classes.companyDetailsWrapper}>
                <Typography variant="body1">
                    {this.props.company.companyName}
                </Typography>
                <Typography variant="body1">
                    {this.props.company.postalAddress} {this.props.company.postalCity} {this.props.company.postalZipCode}
                </Typography>
            </div>
        )
    }

    renderToS() {
        if (!this.props.admin) {
            return (renderAgreementCheckbox("privacyPolicy", this.privacyPolicyLabel))
        }
    }

    privacyPolicyLabel = (
        <Typography variant="caption">
            <FormattedMessage
                id="haveReadAndAgreeText"
                defaultMessage="I have read and agree to the"/>
            &nbsp;
            <a
                href={KARMING_PRIVACY_POLICY_ADDRESS}
                target="_blank"
                rel="noopener noreferrer">
                <FormattedMessage
                    id="privacyPolicy"
                    defaultMessage="Privacy Policy"/>
            </a>
        </Typography>
    );


    handleChange(field, value) {
        const companyNumber = value;

        if (companyNumber.length > 9 || this.props.company) {
            this.props.findCompany(companyNumber)
        }
    }

}

function mapStateToProps(state) {
    return {
        company: state.onboarding.company
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({findCompany}, dispatch);
}

export default reduxForm({
    form: MERCHANT_ONBOARDING_FORM_NAME,
    validate
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MerchantOnboardingForm)))
