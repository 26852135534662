import React from "react";
import PropTypes from "prop-types"
import classnames from "classnames"

import withStyles from "@material-ui/core/styles/withStyles";

import {leftMenuWidth} from "../../utils/style-const";

const styles = theme => ({
    root: {
        transition: 'margin-left 450ms cubic-bezier(0.17, 0.70, 0.17, 0.70)',
        height: "100%",
    },
    loggedUser: {
        [theme.breakpoints.up('sm')]: {
            marginTop: 100,
            marginLeft: leftMenuWidth
        }
    },
    loggedUserWithStaticMenu: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginLeft: 0,
            marginBottom: 110
        }
    },
    loggedUserWithFixedMenu: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 80,
            marginLeft: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 55,
            marginLeft: 0
        }
    }
});

class MainPageContainer extends React.Component {

    render() {
        const {children, classes, userRole} = this.props;

        const classNames = classnames(classes.root, userRole && classes.loggedUser, classes[ROLE_CLASSES[userRole]]);

        return (
            <div className={classNames}>
                {children}
            </div>
        );
    }

}

const ROLE_CLASSES = {
    "ROLE_CONSUMER": "loggedUserWithStaticMenu",
    "ROLE_ADMIN": "loggedUserWithFixedMenu",
    "ROLE_MERCHANT": "loggedUserWithFixedMenu",
    "ROLE_LOCAL_MERCHANT": "loggedUserWithFixedMenu",
    "ROLE_CASHIER": "loggedUserWithFixedMenu",
};

MainPageContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    userRole: PropTypes.string
};

export default withStyles(styles)(MainPageContainer)
