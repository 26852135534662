import React from "react";
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";

export const required = value => (
    value
        ? undefined
        : <FormattedMessage
            id="required"
            defaultMessage="Required"/>
);

export const RequiredInfo = () => {
    return (
        <Typography
            variant="caption"
            display="block"
            align="left">
            * <FormattedMessage
            id="required"
            defaultMessage="Required"/>
        </Typography>
    )
};
