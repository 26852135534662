import axios from "axios";
import {
    FETCH_USER,
    ONBOARDING_CONSUMER,
    ONBOARDING_FIND_COMPANY,
    ONBOARDING_MERCHANT,
    ONBOARDING_VERIFICATION
} from "./types";
import {apiError, apiSuccessMessage} from "./utils-actions";
import {login} from './auth-actions';
import {ROOT_URL} from "../config/karming-links";
import {clearFields, startSubmit, stopSubmit} from "redux-form";
import {MERCHANT_ONBOARDING_FORM_NAME} from "../../merchant/components/onboarding/MerchantOnboardingForm";
import {INVITE_MEMBER_FORM_NAME} from "../../merchant/components/member/InviteMemberForm";

const ENDPOINT_URL = `${ROOT_URL}/onboarding`;

export function fetchUserByConfirmationId(confirmationId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/profile/${confirmationId}`)
            .then(response => {
                dispatch({
                    type: FETCH_USER,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch profile', response));
            });
    }
}

export function merchantOnboarding(values, history) {
    return dispatch => {
        dispatch(startSubmit(MERCHANT_ONBOARDING_FORM_NAME))
        axios.post(`${ENDPOINT_URL}/merchant`, values)
            .then(response => {
                dispatch(stopSubmit(MERCHANT_ONBOARDING_FORM_NAME));
                dispatch({
                    type: ONBOARDING_MERCHANT,
                    payload: response.data
                });
                if (history) {
                    history.push({
                            pathname: "/onboarding/confirmation",
                            email: values.email
                        }
                    );
                } else {
                    dispatch(apiSuccessMessage("Invitation email sent"))
                }
            })
            .catch(response => {
                dispatch(stopSubmit(MERCHANT_ONBOARDING_FORM_NAME));
                dispatch(apiError('Unable to register merchant', response));
            });
    }
}

export function consumerOnboarding(values, history, formName, subpage) {
    return dispatch => {
        dispatch(startSubmit(formName));
        axios.post(`${ENDPOINT_URL}/consumer`, values)
            .then(response => {
                dispatch(stopSubmit(formName));
                dispatch({
                    type: ONBOARDING_CONSUMER,
                    payload: response.data
                });
                if (history) {
                    history.push({
                            pathname: `/onboarding/confirmation`,
                            comeBackToOnboarding: true,
                            email: values.email,
                            phone: values.phone,
                            subpage: subpage
                        }
                    );
                } else {
                    dispatch(clearFields(INVITE_MEMBER_FORM_NAME, false, false, "email", "phone"));
                    dispatch(apiSuccessMessage("Invitation sent"))
                }
            })
            .catch(response => {
                dispatch(stopSubmit(formName));
                dispatch(apiError('Unable to register', response));
            })
    }
}

export function findCompany(companyNumber) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/company/${companyNumber}`)
            .then(response => {
                dispatch({
                    type: ONBOARDING_FIND_COMPANY,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to find company', response));
                dispatch({
                    type: ONBOARDING_FIND_COMPANY,
                    payload: {}
                });
            });
    }
}

export function onboardingVerification(confirmationId, values, history, redirectAfterLoginUrl) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/confirmation/${confirmationId}`, values)
            .then(response => {
                dispatch({
                    type: ONBOARDING_VERIFICATION,
                    payload: response.data
                });
                //FIXME refactor completeInformation
                dispatch(login(values, history, redirectAfterLoginUrl));
            })
            .catch(response => {
                dispatch(apiError('Unable to verify registration', response));
            });
    }
}
