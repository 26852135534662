import React from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import {KarmingTable} from "../../../common/components/common/table/KarmingTable";

import {getFormattedDate} from "../../../common/utils/date-utils";

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: theme.spacing(2)
    }
}));

const ConsumerPurchaseList = props => {
    const {history, purchases} = props;
    const classes = useStyles();

    const columns = [
        {
            Header: <FormattedMessage
                id="categories"
                defaultMessage="Categories"/>,
            id: "Categories",
            accessor: p => renderList(p.categories),
            sortable: false
        },
        {
            Header: <FormattedMessage
                id="campaigns"
                defaultMessage="Campaigns"/>,
            id: "Campaigns",
            accessor: p => renderList(p.campaigns),
            sortable: false
        },
        {
            Header: <FormattedMessage
                id="totalAmountPaid"
                defaultMessage="Total amount paid"/>,
            id: "totalAmount",
            accessor: p => p.totalAmount ? p.totalAmount : ""
        },
        {
            Header: <FormattedMessage
                id="dateOfPurchase"
                defaultMessage="Date of purchase"/>,
            id: "dateOfPurchase",
            accessor: p => getFormattedDate(p.dateOfPurchase)
        }
    ];

    return (
        <Container size={Container.SIZE_HUGE}>
            <Paper>
                <Typography
                    paragraph
                    variant="h5"
                    className={classes.title}>
                    <FormattedMessage
                        id="purchases"
                        defaultMessage="Purchases"/>
                </Typography>
                <KarmingTable
                    history={history}
                    data={purchases}
                    columns={columns}
                    sortByDateOfPurchase
                    onRowClickHistoryPush={(state, row) => {
                        return ({
                            pathname: `/consumer/purchases/` + row.id
                        })
                    }}/>
            </Paper>
        </Container>
    );

    function renderList(field) {
        return (
            field.map((c, i) => <span key={i}>{i > 0 && ", "}{c.name.charAt(0).toUpperCase()}{c.name.slice(1)}</span>)
        )
    }

};

ConsumerPurchaseList.propTypes = {
    history: PropTypes.object,
    purchases: PropTypes.array
};

export default ConsumerPurchaseList;
