import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {addMonths, isAfter} from 'date-fns';
import {makeStyles} from '@material-ui/core/styles';
import ImageCard from "../ImageCard";

import {getTranslatedDate} from "../../../../common/utils/date-utils";

const useStyles = makeStyles(theme => ({
    couponsWrapper: {
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLft: '0 !important'
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            minWidth: 320,
            maxWidth: 400
        }
    },
    couponBanner: {
        marginTop: theme.spacing(1)
    },
    pointsText: {
        textTransform: 'lowercase'
    }
}));

const CouponBannerList = (props) => {
    const {chain, coupons, consumerPoints, purchases, clubPoints, language} = props;
    const availableCoupons = coupons.filter(coupon => coupon.pointsRequired <= consumerPoints);
    const timestamp = new Date().getTime();
    const classes = useStyles();

    if (chain && coupons && consumerPoints) {
        return (
            <div className={classes.couponsWrapper}>
                {availableCoupons.map((coupon, index) => (
                    <div key={index} className={classes.couponBanner}>
                        <ImageCard
                            // url={"/r/" + coupon.id} maybe to remove later
                            imagePath={coupon.imagePath + '?' + timestamp}
                            topText={
                                <FormattedMessage
                                    id="tradeForPointsAmount"
                                    defaultMessage="Trade for {pointsAmount} points"
                                    values={{
                                        pointsAmount: coupon.pointsRequired
                                    }}/>
                            }
                            bottomTitleText={coupon.name}
                            bottomSubtitleText={
                                <FormattedMessage
                                    id="pointsFallDueDate"
                                    defaultMessage="Points fall due {date}"
                                    values={{
                                        date: getTranslatedDate(getCouponPointsExpiration(), language)
                                    }}/>
                            }
                            expandedText={coupon.description}/>
                    </div>
                ))}
            </div>
        )
    }
    return null;

    function getCouponPointsExpiration() {
        const expirationDate = (purchase) => addMonths(purchase.dateOfPurchase, clubPoints.monthValidation);

        return purchases
            .filter(purchase => isAfter(purchase.dateOfPurchase, clubPoints.countedPointsFrom)
                && isAfter(expirationDate(purchase), new Date())
                && purchase.earnedPoints > 0)
            .map((purchase) => expirationDate(purchase))
            .reduce((previous, current) => current < previous ? current : previous)
    }
};

CouponBannerList.propTypes = {
    chain: PropTypes.object,
    clubPoints: PropTypes.object,
    language: PropTypes.string,
    campaigns: PropTypes.array,
    purchases: PropTypes.array,
    coupons: PropTypes.array,
    consumerPoints: PropTypes.number
};

export default CouponBannerList
