import React from "react";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {addMonths, isAfter, subDays} from 'date-fns'

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Launch from "@material-ui/icons/Launch";

import Spinner from "../../../common/components/common/Spinner";
import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import DashedWrapper from "../../../common/components/common/DashedWrapper";

import ConsumerPointsList from "./ConsumerPointsList";

import {getFormattedDate} from "../../../common/utils/date-utils";
import {CONSUMER_PURCHASES_HISTORY_URL} from "../../../../static/utils/redirect-const";

import {fetchConsumerPoints, fetchCoupons, fetchPointsValidationDate} from "../../actions/consumer-actions";

const styles = theme => ({
    pointsDetails: {
        textAlign: 'left'
    },
    balanceText: {
        display: 'flex',
        alignItems: 'baseline',
        alignSelf: 'center'
    },
    balanceDetails: {
        display: 'flex'
    },
    textBold: {
        fontWeight: 'bold'
    },
    dialogPaper: {
        width: '100%'
    },
    dialogContent: {
        padding: 0,
        overflow: 'hidden'
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    }
});

class ConsumerPoints extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        clubPoints: PropTypes.object,
        app: PropTypes.object,
        points: PropTypes.object,
        chainId: PropTypes.object,
        language: PropTypes.string,
        purchases: PropTypes.array,
        coupons: PropTypes.array,
        consumerPoints: PropTypes.number,
        pointsValidationDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fetchConsumerPoints: PropTypes.func,
        fetchCoupons: PropTypes.func,
        fetchPointsValidationDate: PropTypes.func
    };

    componentDidMount() {
        const {fetchConsumerPoints, fetchCoupons, fetchPointsValidationDate} = this.props;
        fetchConsumerPoints();
        fetchCoupons(true);
        fetchPointsValidationDate();
    }

    render() {
        const {
            classes, clubPoints, history, consumerPoints, pointsValidationDate, app, language, purchases,
            coupons
        } = this.props;

        if (consumerPoints === undefined || pointsValidationDate === undefined || !coupons) {
            return <Spinner app={app}/>
        }

        const validPurchases = purchases.filter(purchase =>
            isAfter(
                subDays(addMonths(purchase.dateOfPurchase, clubPoints.monthValidation), 1),
                new Date())
        );

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography
                        paragraph
                        variant="h6">
                        <FormattedMessage
                            id="points"
                            defaultMessage="Points"/>
                    </Typography>

                    <DashedWrapper>
                        <Typography variant="body1">
                            {clubPoints.description}
                        </Typography>
                    </DashedWrapper>

                    <div className={classes.pointsDetails}>
                        <Typography
                            variant="body1"
                            display="inline">
                            <FormattedMessage
                                id="balance"
                                defaultMessage="Balance"/>
                            :&nbsp;
                        </Typography>

                        <Typography
                            className={classes.textBold}
                            variant="h6"
                            display="inline">
                            {consumerPoints}

                            <React.Fragment>
                                &nbsp;
                                <FormattedMessage
                                    id="pointsAmount"
                                    defaultMessage="points"/>
                            </React.Fragment>
                        </Typography>

                        {pointsValidationDate && (
                            <Typography
                                variant="caption"
                                display="inline">
                                &nbsp;
                                (<FormattedMessage
                                id={"validToDate"}
                                defaultMessage={`Valid to {date}`}
                                values={{
                                    date: getFormattedDate(pointsValidationDate)
                                }}/>)
                            </Typography>
                        )}

                        {consumerPoints > 0 && (
                            <ConsumerPointsList
                                totalPoints={consumerPoints}
                                pointsValidation={clubPoints.monthValidation}
                                pointsStartDate={clubPoints.countedPointsFrom}
                                langauge={language}
                                purchases={validPurchases}/>
                        )}

                        <Link
                            to={CONSUMER_PURCHASES_HISTORY_URL}
                            color="secondary"
                            className={classes.link}>
                            <FormattedMessage
                                id="showPurchaseHistory"
                                defaultMessage="Show purchase history"/>
                            <Launch/>
                        </Link>

                        <ImageBoxRow
                            coupon
                            readOnly
                            remainingPoints={consumerPoints}
                            options={coupons}
                            history={history}/>
                    </div>
                </Paper>
            </Container>
        )
    }

}

function mapStateToProps(state) {
    return {
        consumerPoints: state.consumer.consumerPoints,
        pointsValidationDate: state.consumer.pointsValidationDate,
        coupons: state.consumer.coupons
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchConsumerPoints,
        fetchPointsValidationDate,
        fetchCoupons
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsumerPoints));
