import React, {useEffect, useRef} from "react";
import classnames from "classnames";

import {makeStyles} from "@material-ui/core/styles";

import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import Close from "@material-ui/icons/Close";
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles({
    inlineRadioGroup: {
        display: 'inline-table'
    },
    columnWrapper: {
        textAlign: 'left'
    },
    radioGroup: {
        flexDirection: 'row'
    },
    radioButton: {
        flexDirection: props => (
            props.direction ? props.direction : 'row'
        )
    }
});

// TODO migrate usages to RenderRadioButtonsGroup and remove this class
const RenderRadioGroup = props => {
    const {input: {value, onChange}, direction, clearable, ...rest} = props;
    const classes = useStyles(props);
    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current && props.defaultValue !== undefined) {
            props.input.onChange(props.defaultValue);
            mounted.current = true
        }
    });

    return (
        <div className={classnames(direction === "column" && classes.columnWrapper)}>
            <FormControl component="fieldset">
                <RadioGroup
                    {...rest}
                    className={classes.radioButton}
                    value={value !== undefined ? value : undefined}
                    onChange={(event, value) => {
                        onChange(value)
                    }}>
                </RadioGroup>
            </FormControl>

            {clearable && (
                <IconButton onClick={() => {
                    onChange(null)
                }}>
                    <Close/>
                </IconButton>
            )}
        </div>
    )
};

export default RenderRadioGroup
