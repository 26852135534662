import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";
import PersonAdd from '@material-ui/icons/PersonAdd';
import ExitToApp from '@material-ui/icons/ExitToApp';

import Spinner from "../../../common/components/common/Spinner";
import Button from "../../../common/components/common/Button";
import CampaignsBannersList from "../../../common/components/common/campaign/CampaignsBannersList";
import StampcardBannersList from "../../../common/components/common/stampcard/StampcardBannersList";
import CenterContainer from "../../../common/components/common/CenterContainer";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import LanguageSelect from "../../../common/components/common/language/LanguageSelect";

import {
    fetchCampaignsWithoutAuthBySubdomain,
    fetchCommercialChainWithoutAuthBySubdomain,
    fetchStampcardsWithoutAuthBySubdomain
} from "../../actions/kundklubb-public-actions";
import {getPotentiallySubpageFromPathname, isSubpageInPathname} from "../../../common/utils/string-utils";

class ChainHome extends React.Component {

    static propTypes = {
        auth: PropTypes.object,
        app: PropTypes.object,
        history: PropTypes.object,
        company: PropTypes.object,
        location: PropTypes.object,
        commercialChain: PropTypes.object,
        stampcards: PropTypes.array,
        campaigns: PropTypes.array,
        fetchCommercialChainWithoutAuthBySubdomain: PropTypes.func,
        fetchCampaignsWithoutAuthBySubdomain: PropTypes.func,
        fetchStampcardsWithoutAuthBySubdomain: PropTypes.func
    };

    componentDidMount() {
        const {auth, history} = this.props;

        if (auth && auth.authenticated && history) {
            history.push(`/`);
        }
        this.props.fetchCommercialChainWithoutAuthBySubdomain();
        this.props.fetchCampaignsWithoutAuthBySubdomain();
        this.props.fetchStampcardsWithoutAuthBySubdomain();
    }

    register() {
        const potentiallySubpage = getPotentiallySubpageFromPathname(this.props.location.pathname);
        const redirectPath = isSubpageInPathname(potentiallySubpage)
            ? `/${potentiallySubpage}/onboarding`
            : `/onboarding`;
        this.props.history.push(redirectPath);
    }

    login() {
        this.props.history.push(`/login`);
    }

    render() {
        const {commercialChain, stampcards, campaigns, app} = this.props;

        if (!commercialChain || !stampcards) {
            return <Spinner app={app}/>
        }

        if (commercialChain.commercialName === 'Non Existing') {
            return (
                <Container>
                    <Paper padding>
                        <React.Fragment>
                            <FormattedMessage
                                id="providedShopNotExists"
                                defaultMessage="Provided shop does not exists"/>
                            !
                        </React.Fragment>
                    </Paper>
                </Container>
            )
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography
                        paragraph
                        variant="subtitle1">
                        <FormattedMessage
                            id="loginToAccessAccount"
                            defaultMessage="Log in or sign up to access your account and use offerings!"/>
                    </Typography>

                    <Button
                        icon={<PersonAdd/>}
                        label={<FormattedMessage
                            id="register"
                            defaultMessage="Register"/>}
                        color="secondary"
                        onClick={this.register.bind(this)}/>

                    <Button
                        icon={<ExitToApp/>}
                        label={<FormattedMessage
                            id="login"
                            defaultMessage="Login"/>}
                        onClick={this.login.bind(this)}/>

                    <CenterContainer>
                        <LanguageSelect/>

                        <CampaignsBannersList
                            campaigns={campaigns
                                ? campaigns.filter(campaign => campaign.publicToAll)
                                : undefined}
                            chain={commercialChain}/>

                        <StampcardBannersList stampcards={stampcards}/>
                    </CenterContainer>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        commercialChain: state.company.commercialChain,
        campaigns: state.company.campaigns,
        stampcards: state.company.stampcards
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCommercialChainWithoutAuthBySubdomain,
        fetchCampaignsWithoutAuthBySubdomain,
        fetchStampcardsWithoutAuthBySubdomain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChainHome);
