import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import {parseAddHomeScreenSafariIconUrl} from "../../../utils/enums/languages";

const styles = theme => ({
    shareIcon: {
        verticalAlign: 'middle',
        width: 25,
        padding: theme.spacing(1)
    },
    addIcon: {
        width: 60,
        height: "auto",
        verticalAlign: "middle"
    },
    content: {
        fontWeight: "bold"
    }
});

const RenderSafariMessage = ({classes, language}) => (
    <React.Fragment>
        <Typography
            variant="body1"
            color='textPrimary'
            align="left">
            <FormattedMessage
                id="addToHomeScreenTitle"
                defaultMessage="Add to apps on your phone!"/>
        </Typography>
        <Typography
            variant="body1"
            color='textPrimary'
            className={classes.content}
            align="left">
            <FormattedMessage
                id="addToHomeScreenSafariContent"
                defaultMessage="Click {shareIcon} and then select {addIcon}"
                values={{
                    shareIcon: <img className={classes.shareIcon} src="/static/images/share-icon-iphone.jpg"/>,
                    addIcon: <img className={classes.addIcon} src={parseAddHomeScreenSafariIconUrl(language)}/>
                }}/>
        </Typography>
    </React.Fragment>
);

RenderSafariMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired
};

export default withStyles(styles)(RenderSafariMessage)
