import React from "react";
import {colorWhite} from "../../../utils/color-const";
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

export const STANDARD_STAMPCARD = 'STANDARD_STAMPCARD';
export const REGISTER_PURCHASE_STAMPCARD = 'REGISTER_PURCHASE_STAMPCARD';

const STAMPCARD_SIZE_BIGGEST = "STAMPCARD_SIZE_BIGGEST";
const STAMPCARD_SIZE_BIGGER = "STAMPCARD_SIZE_BIGGER";
const STAMPCARD_SIZE_BIG = "STAMPCARD_SIZE_BIG";
const STAMPCARD_SIZE_MEDIUM = "STAMPCARD_SIZE_MEDIUM";
const STAMPCARD_SIZE_SMALL = "STAMPCARD_SIZE_SMALL";

const STAMPCARD_SIZE_BIGGEST_THRESHOLD = 7;
const STAMPCARD_SIZE_BIG_THRESHOLD = 5;
const STAMPCARD_SIZE_MEDIUM_THRESHOLD = 3;
const STAMPCARD_SIZE_SMALL_THRESHOLD = 2;

const STAMPCARD_BIG_MARGIN = '8px';
const STAMPCARD_SMALL_MARGIN = '4px';
const STAMPCARD_BIGGEST_SIZE = '160px';
const STAMPCARD_BIGGER_SIZE = '120px';
const STAMPCARD_BIG_SIZE = '96px';
const STAMPCARD_MEDIUM_SIZE = '72px';
const STAMPCARD_SMALL_SIZE = '54px';
export const STAMPCARD_HEADER_FONT_SIZE_BIGGEST = 28;
export const STAMPCARD_HEADER_FONT_SIZE_BIGGER = 20;
export const STAMPCARD_HEADER_FONT_SIZE = 16;

const REGISTER_PURCHASE_STAMPCARD_BIG_MARGIN = '6px';
const REGISTER_PURCHASE_STAMPCARD_SMALL_MARGIN = '3px';
const REGISTER_PURCHASE_STAMPCARD_BIGGEST_SIZE = '56px';
const REGISTER_PURCHASE_STAMPCARD_BIGGER_SIZE = '38px';
const REGISTER_PURCHASE_STAMPCARD_BIG_SIZE = '30px';
const REGISTER_PURCHASE_STAMPCARD_MEDIUM_SIZE = '26px';
const REGISTER_PURCHASE_STAMPCARD_SMALL_SIZE = '20px';
const REGISTER_PURCHASE_STAMPCARD_HEADER_FONT_SIZE_BIGGER = '8px';
const REGISTER_PURCHASE_STAMPCARD_HEADER_FONT_SIZE = '6px';

const STANDARD_NUMBER_OF_STAMPS_IN_A_ROW = 2;
const BIGGER_NUMBER_OF_STAMPS_IN_A_ROW = 3;

export function renderStamps(stampsAmount, title, subtitle, stamp, stampUncollected, viewType, collectedStamps,
                             hideTitle, hideSubtitle, titleColor, titleSize, subtitleColor, subtitleSize) {

    const numberOfStampsInARow = stampsAmount > 8 ? BIGGER_NUMBER_OF_STAMPS_IN_A_ROW : STANDARD_NUMBER_OF_STAMPS_IN_A_ROW;
    const margin = getStampsMargin(stampsAmount, viewType);
    const size = getStampsSize(stampsAmount, viewType);

    const stampsAmountOptionArray = [];

    for (let i = 1; i <= stampsAmount; i++) {
        if (i <= (collectedStamps !== undefined ? collectedStamps : 1)
            && (stampsAmount > 1 || collectedStamps !== undefined && collectedStamps === 1)) {
            if (stamp && stampsAmount) {
                stampsAmountOptionArray.push(
                    <img
                        key={"stampImage" + i}
                        style={{margin: margin}}
                        width={size}
                        height={size}
                        src={stamp}/>
                )
            } else {
                stampsAmountOptionArray.push(
                    <Star
                        key={"starIcon" + i}
                        style={{
                            fill: colorWhite,
                            margin: margin,
                            fontSize: size
                        }}/>
                )
            }
        } else {
            if (stampUncollected) {
                stampsAmountOptionArray.push(
                    <img
                        key={"stampUncollectedImage" + i}
                        style={{margin: margin}}
                        width={size}
                        height={size}
                        src={stampUncollected}/>
                )
            } else {
                stampsAmountOptionArray.push(
                    <StarBorder
                        key={"starBorderIcon" + i}
                        style={{
                            fill: colorWhite,
                            margin: margin,
                            fontSize: size
                        }}/>
                )
            }
        }
    }
    let rows = [];
    for (let i = 0; i < stampsAmount; i += numberOfStampsInARow) {
        const slicedOptions = stampsAmountOptionArray.slice(i, i + numberOfStampsInARow);
        rows = [...rows, slicedOptions]
    }
    const titleLength = title.length;
    return (
        <div style={{
            width: '100%',
            position: 'absolute',
            zIndex: 99,
            height: '100%'
        }}>
            <div style={{whiteSpace: 'pre-wrap', fontFamily: "Arial", height: '25%'}}>
                <div style={{height: '50%'}}>
                    <h1 style={{
                        color: titleColor,
                        fontSize: viewType === REGISTER_PURCHASE_STAMPCARD
                            ? (titleLength > 12
                                ? REGISTER_PURCHASE_STAMPCARD_HEADER_FONT_SIZE
                                : REGISTER_PURCHASE_STAMPCARD_HEADER_FONT_SIZE_BIGGER)
                            : titleSize,
                        marginTop: '8%',
                        marginBottom: 0
                    }}>
                        {!hideTitle && title}
                    </h1>
                </div>
                <div style={{
                    fontFamily: "Arial",
                    fontSize: subtitleSize,
                    color: subtitleColor
                }}>
                    {!hideSubtitle && subtitle}
                </div>

            </div>

            <div>
                {(rows.map((optionRow, index) =>
                        <div key={index}>
                            <div style={{height: size}}>
                                {optionRow} <br/>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

function getStampsMargin(stampsAmount, viewType) {
    switch (viewType) {
        case STANDARD_STAMPCARD:
            return stampsAmount < 8 ? STAMPCARD_BIG_MARGIN : STAMPCARD_SMALL_MARGIN;
        case REGISTER_PURCHASE_STAMPCARD:
            return stampsAmount < 8 ? REGISTER_PURCHASE_STAMPCARD_BIG_MARGIN : REGISTER_PURCHASE_STAMPCARD_SMALL_MARGIN;
    }
}

function getStampsSize(stampsAmount, viewType) {
    const stampSize = stampsAmount < STAMPCARD_SIZE_SMALL_THRESHOLD
        ? STAMPCARD_SIZE_BIGGEST
        : stampsAmount < STAMPCARD_SIZE_MEDIUM_THRESHOLD
            ? STAMPCARD_SIZE_BIGGER
            : stampsAmount < STAMPCARD_SIZE_BIG_THRESHOLD
                ? STAMPCARD_SIZE_BIG
                : stampsAmount < STAMPCARD_SIZE_BIGGEST_THRESHOLD
                    ? STAMPCARD_SIZE_MEDIUM
                    : STAMPCARD_SIZE_SMALL;
    switch (viewType) {
        case STANDARD_STAMPCARD:
            switch (stampSize) {
                case STAMPCARD_SIZE_SMALL:
                    return STAMPCARD_SMALL_SIZE;
                case STAMPCARD_SIZE_MEDIUM:
                    return STAMPCARD_MEDIUM_SIZE;
                case STAMPCARD_SIZE_BIG:
                    return STAMPCARD_BIG_SIZE;
                case STAMPCARD_SIZE_BIGGER:
                    return STAMPCARD_BIGGER_SIZE;
                case STAMPCARD_SIZE_BIGGEST:
                    return STAMPCARD_BIGGEST_SIZE;
            }
            break;
        case REGISTER_PURCHASE_STAMPCARD:
            switch (stampSize) {
                case STAMPCARD_SIZE_SMALL:
                    return REGISTER_PURCHASE_STAMPCARD_SMALL_SIZE;
                case STAMPCARD_SIZE_MEDIUM:
                    return REGISTER_PURCHASE_STAMPCARD_MEDIUM_SIZE;
                case STAMPCARD_SIZE_BIG:
                    return REGISTER_PURCHASE_STAMPCARD_BIG_SIZE;
                case STAMPCARD_SIZE_BIGGER:
                    return REGISTER_PURCHASE_STAMPCARD_BIGGER_SIZE;
                case STAMPCARD_SIZE_BIGGEST:
                    return REGISTER_PURCHASE_STAMPCARD_BIGGEST_SIZE;
            }
            break;
    }
}
