import {
    CONSUMER_FETCH_CAMPAIGNS,
    CONSUMER_FETCH_COMMERCIAL_CHAIN,
    CONSUMER_FETCH_POINTS_OF_SALE,
    CONSUMER_FETCH_STAMPCARD_COUPON,
    CONSUMER_FETCH_STAMPCARDS,
    CONSUMER_FETCH_STAMPCARDS_COUPONS,
    FETCH_CONSUMER_POINTS,
    FETCH_COUPONS,
    FETCH_HOME_LOCATION,
    FETCH_POINTS_VALIDATION_DATE,
    FETCH_VALID_PURCHASES
} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case CONSUMER_FETCH_COMMERCIAL_CHAIN:
            if (!action.payload) {
                return {...state, commercialChain: {name: 'Non Existing'}};
            }
            return {...state, commercialChain: action.payload};
        case FETCH_HOME_LOCATION:
            if (!action.payload) {
                return {...state, homeLocation: {name: 'Non Existing'}};
            }
            return {...state, homeLocation: action.payload};
        case CONSUMER_FETCH_CAMPAIGNS:
            if (!action.payload) {
                return {...state, campaigns: {name: 'Non Existing'}};
            }
            return {...state, campaigns: action.payload};
        case FETCH_CONSUMER_POINTS:
            return {...state, consumerPoints: action.payload};
        case FETCH_POINTS_VALIDATION_DATE:
            return {...state, pointsValidationDate: action.payload};
        case FETCH_VALID_PURCHASES:
            return {...state, purchases: action.payload};
        case FETCH_COUPONS:
            return {...state, coupons: action.payload};
        case CONSUMER_FETCH_STAMPCARDS:
            return {...state, stampcards: action.payload};
        case CONSUMER_FETCH_STAMPCARDS_COUPONS:
            return {...state, stampcardCoupons: action.payload};
        case CONSUMER_FETCH_STAMPCARD_COUPON:
            return {...state, stampcardCoupon: action.payload};
        case CONSUMER_FETCH_POINTS_OF_SALE:
            return {...state, pointsOfSale: action.payload};
    }

    return state;
}
