import React from "react";
import FormattedMessageFixed from "../FormattedMessageFixed";

const SUNDAY = {id: 'sunday', message: "Söndag", value: 0};
const MONDAY = {id: 'monday', message: "Måndag", value: 1};
const TUESDAY = {id: 'tuesday', message: "Tisdag", value: 2};
const WEDNESDAY = {id: 'wednesday', message: "Onsdag", value: 3};
const THURSDAY = {id: 'thursday', message: "Torsdag", value: 4};
const FRIDAY = {id: 'friday', message: "Fredag", value: 5};
const SATURDAY = {id: 'saturday', message: "Lördag", value: 6};

export const daysOfWeek = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

export const DAYS = daysOfWeek.map(key =>
    ({
        label: <FormattedMessageFixed id={key.id} defaultMessage={key.message}/>,
        value: key.value
    })
);