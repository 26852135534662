import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Dialog from '../../../common/components/common/Dialog'
import Button from "../../../common/components/common/Button";

import {zIndex1299} from "../../../common/utils/style-const";

const styles = theme => ({
    cardContainer: {
        display: 'flex',
        bottom: 50,
        left: '50%',
        height: 40,
        maxWidth: 400,
        width: '80%',
        padding: theme.spacing(1),
        color: theme.palette.common.black,
        background: theme.palette.grey[300],
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: 10,
        position: 'fixed',
        textAlign: 'center',
        transform: 'translate(-50%, 0)',
        zIndex: zIndex1299
    },
    button: {
        margin: '0 !important',
        textTransform: 'uppercase',
        position: 'absolute',
        right: theme.spacing(1),
    },
    dialog: {
        margin: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    membershipCard: {
        height: 'fit-content',
        width: '85vw',
        margin: '0 auto',
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        color: theme.palette.common.white,
        background: 'linear-gradient(to right, #259da9, #175568)',
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: theme.shape.borderRadius,
    },
    divider: {
        borderColor: '#4d8291'
    },
    cardContent: {
        display: 'flex',
        textAlign: 'center',
        padding: theme.spacing(2),
        "& :last-child": {
            flex: '1 50%'
        }
    },
    cardImage: {
        width: 60,
        height: 60
    }
});

class ConsumerMembershipCard extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        user: PropTypes.object
    };

    state = {
        open: false
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes, user} = this.props;
        return (
            <React.Fragment>
                <div className={classes.cardContainer}>
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        component="div">
                        <FormattedMessage
                            id="membershipCard"
                            defaultMessage="Membership Card"/>
                    </Typography>

                    <Button
                        color="default"
                        variant="outlined"
                        classes={{root: classes.button}}
                        onClick={this.handleOpen}
                        label={<FormattedMessage
                            id="getOut"
                            defaultMessage="Get out"/>}/>
                </div>

                <Dialog
                    rootClasses={{paper: this.props.classes.dialog}}
                    open={this.state.open}
                    onClose={this.handleClose}
                    content={
                        <div className={classes.membershipCard}>
                            <Typography
                                variant="subtitle1"
                                color="inherit"
                                component="div">
                                <FormattedMessage
                                    id="membershipCard"
                                    defaultMessage="Membership Card"/>
                            </Typography>

                            <hr className={classes.divider}/>

                            <div className={classes.cardContent}>
                                <img src="/static/images/qr-code.png" className={classes.cardImage}/>
                                <div>
                                    <Typography
                                        variant="h6"
                                        color="inherit"
                                        component="div">
                                        {this.renderUserName(user.profile)}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        color="inherit"
                                        component="div">
                                        {user.profile.membershipId}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    }/>
            </React.Fragment>
        );
    }

    renderUserName(profile) {
        if (profile.firstName || profile.lastName) {
            return (
                <span>{profile.firstName} {profile.lastName}</span>
            )
        }
    }

}

export default withStyles(styles)(ConsumerMembershipCard)
