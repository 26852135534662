import axios from "axios";
import {
    FETCH_CAMPAIGNS_WITHOUT_AUTH,
    FETCH_CLUB_POINTS_WITHOUT_AUTH,
    FETCH_COMMERCIAL_CHAIN_WITHOUT_AUTH,
    FETCH_MEMBERSHIP_CONDITIONS,
    FETCH_POINT_OF_SALE_WITHOUT_AUTH,
    FETCH_PRIVACY_POLICY,
    FETCH_SMS_ACCOUNT_WITHOUT_AUTH,
    FETCH_STAMPCARDS_WITHOUT_AUTH
} from "../../common/actions/types";
import {apiError} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";
import {getCurrentSubdomain} from "./kundklubb-utils-actions";

const ENDPOINT_URL = `${ROOT_URL}/public`;

export function fetchCommercialChainWithoutAuthBySubdomain() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/subdomain/${getCurrentSubdomain()}`)
            .then(response => {
                dispatch({
                    type: FETCH_COMMERCIAL_CHAIN_WITHOUT_AUTH,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch commercial chain by subdomain', response));
            });
    }
}

export function fetchCompanySmsAccountBySubdomain() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/sms/${getCurrentSubdomain()}`)
            .then(response => {
                dispatch({
                    type: FETCH_SMS_ACCOUNT_WITHOUT_AUTH,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch sms account by subdomain', response));
            });
    }
}

export function fetchClubPointsWithoutAuthBySubdomain() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/points/${getCurrentSubdomain()}`)
            .then(response => {
                dispatch({
                    type: FETCH_CLUB_POINTS_WITHOUT_AUTH,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch club points by subdomain', response));
            });
    }
}

export function fetchPointOfSaleWithoutAuthBySubpage(subpage) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/${getCurrentSubdomain()}/${subpage}/`)
            .then(response => {
                dispatch({
                    type: FETCH_POINT_OF_SALE_WITHOUT_AUTH,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('There is no such sale location', response));
            });
    }
}

export function fetchCampaignsWithoutAuthBySubdomain() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${getCurrentSubdomain()}/campaigns`)
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_CAMPAIGNS_WITHOUT_AUTH,
                        payload: response.data
                    })
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch campaigns', response));
        });
    }
}

export function fetchStampcardsWithoutAuthBySubdomain() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${getCurrentSubdomain()}/stampcards`)
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_STAMPCARDS_WITHOUT_AUTH,
                        payload: response.data
                    })
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch stampcards', response));
        });
    }
}

export function fetchPrivacyPolicy() {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/${getCurrentSubdomain()}/terms/privacy/`)
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_PRIVACY_POLICY,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Privacy Policy', response));
        });
    }
}

export function fetchMembershipConditions() {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/${getCurrentSubdomain()}/terms/membership/`)
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_MEMBERSHIP_CONDITIONS,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Membership Conditions', response));
        });
    }
}
