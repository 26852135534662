import {blur, change} from "redux-form";

export function addBannerChangeToForm(formName, fieldName, bannerValue) {
    return dispatch => {
        dispatch(blur(formName, fieldName, bannerValue));
    }
}

export function setFormValue(formName, fieldName, value) {
    return dispatch => {
        dispatch(change(formName, fieldName, value));
    }
}

