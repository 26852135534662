import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import {zIndex999998} from "../../../common/utils/style-const";
import RenderOnMobile from "../../../common/components/common/RenderOnMobile";

const styles = theme => ({
    appBar: {
        top: '0',
        height: 64,
        margin: '0',
        zIndex: zIndex999998,
        flexWrap: 'wrap',
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
    },
    toolBar: {
        display: 'block',
        justifyContent: 'center'
    },
    logoWrapper: {
        marginLeft: 230
    },
    logo: {
        height: 52,
        marginTop: 8,
        left: '50%',
        position: 'relative',
        textAlign: 'center',
        transform: 'translate(-50%, 0)'
    }
});

class LoggedUserAppBar extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        commercialChain: PropTypes.object,
        homeLocation: PropTypes.object
    };

    render() {
        const {classes, commercialChain, homeLocation} = this.props;

        return (
            <RenderOnMobile>
                {isMobile => (
                    <AppBar
                        position={isMobile ? "static" : "fixed"}
                        className={classes.appBar}>
                        <Toolbar className={classes.toolBar}>
                            {this.renderLogo(
                                commercialChain, isMobile, homeLocation.name === 'Non Existing'
                                    ? null
                                    : homeLocation)}
                        </Toolbar>
                    </AppBar>
                )}
            </RenderOnMobile>
        );
    }

    renderLogo(commercialChain, isMobile, homeLocation) {
        const {classes} = this.props;

        const timestamp = new Date().getTime();
        if (homeLocation && homeLocation.logoPath) {
            if (isMobile) {
                return (
                    <img
                        className={classes.logo}
                        src={homeLocation.logoPath + '?' + timestamp}/>
                )
            }
            return (
                <div className={classes.logoWrapper}>
                    <img
                        className={classes.logo}
                        src={homeLocation.logoPath + '?' + timestamp}/>
                </div>
            )
        } else if (commercialChain.logoPath) {
            if (isMobile) {
                return (
                    <img
                        className={classes.logo}
                        src={commercialChain.logoPath + '?' + timestamp}/>
                )
            }
            return (
                <div className={classes.logoWrapper}>
                    <img
                        className={classes.logo}
                        src={commercialChain.logoPath + '?' + timestamp}/>
                </div>
            )
        }
    }
}

export default withRouter(withStyles(styles)(LoggedUserAppBar))
