import React from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'inherit',
        boxShadow: 'none',
        marginTop: props => !props.small && theme.spacing(1),
        '& .MuiExpansionPanelSummary-root': {
            height: props => props.small ? '25px' : 'inherit',
            minHeight: props => props.small && '0'
        }
    },
    expandedPanel: {
        minHeight: props => props.small && '0',
        margin: props => props.small && '0 !important'
    },
    summary: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(0.2),
        paddingBottom: theme.spacing(0.2)
    },
    content: {
        justifyContent: props => props.small ? 'start' : 'flex-end'
    },
    hideButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    hideButtonLabel: {
        display: 'block'
    }
}));

export const HistoryExpansionPanel = props => {
    const {content, title, hasHideButton} = props;
    const [expanded, setExpanded] = React.useState(null);
    const classes = useStyles(props);

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <ExpansionPanel
            classes={{root: classes.root, expanded: classes.expandedPanel}}
            expanded={expanded === 'panel'}
            onChange={handleChange('panel')}>
            <ExpansionPanelSummary
                classes={{
                    root: classes.summary,
                    content: classes.content,
                    expandIcon: classes.hideButton
                }}
                IconButtonProps={{
                    disableRipple: true,
                    disableFocusRipple: true
                }}
                expandIcon={<ExpandMoreIcon color="secondary"/>}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography color="secondary">
                    {title}
                </Typography>
            </ExpansionPanelSummary>

            {content}

            {hasHideButton && (
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    disableRipple
                    disableFocusRipple
                    classes={{
                        root: classes.hideButton,
                        label: classes.hideButtonLabel
                    }}
                    onClick={handleChange('panel')}>
                    <ExpandLess color="secondary"/>
                    <Typography color="secondary">
                        <FormattedMessage
                            id="hideUpdates"
                            defaultMessage="Hide updates"/>
                    </Typography>
                </IconButton>
            )}
        </ExpansionPanel>
    );
};

HistoryExpansionPanel.propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
    title: PropTypes.node.isRequired,
    hasHideButton: PropTypes.bool
};
