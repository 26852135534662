import {AUTH_USER, UNAUTH_USER, SEND_PASSWORD_RESET_MAIL, RESET_PASSWORD} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case AUTH_USER:
            return {...state, authenticated: true, role: action.payload};
        case UNAUTH_USER:
            return {...state, authenticated: false, role: null};
        case SEND_PASSWORD_RESET_MAIL:
            return {...state, sendPasswordResetMail: action.payload};
        case RESET_PASSWORD:
            return {...state, resetPassword: action.payload};
    }

    return state;
}
