import React from "react";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import grey from "@material-ui/core/colors/grey";

import {REGISTER_PURCHASE_STAMPCARD, renderStamps} from "../../../../common/components/common/stampcard/StampcardUtils";
import Button from "../../../../common/components/common/Button";
import {PUBLISH_STATUSES} from "../../../../common/utils/enums/publish-status";
import {getFormattedDate} from "../../../../common/utils/date-utils";
import {colorGrey500} from "../../../../common/utils/color-const";

const useStyles = makeStyles(theme => ({
    stampcardWrapper: {
        position: 'relative',
        color: theme.palette.common.white,
    },
    disabledStampcardImage: {
        filter: 'grayscale(1) opacity(50%)'
    },
    contentCaption: {
        fontWeight: 'bold'
    },
    actionButtons: {
        display: 'flex',
        '& button': {
            minWidth: '50%',
            height: 40,
            margin: '0px !important',
            padding: theme.spacing(0.5),
            borderRadius: 0,
            boxShadow: 'none'
        },
        "& button:first-child": {
            borderBottomLeftRadius: theme.spacing(0.35),
        },
        "& button:last-child": {
            borderBottomRightRadius: theme.spacing(0.35),
        },
        '& svg': {
            display: 'flex',
            alignItems: 'center'
        },
    },
    textBold: {
        fontWeight: 'bold'
    },
    boxText: {
        display: "inline-grid",
    },
    historySubtitle: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        background: grey[300],
        color: grey[600]
    },
    // details: {
    //     marginTop: props => props.isHistoric ? 0 : theme.spacing(1.5),
    //     marginBottom: props => props.isHistoric ? 0 : theme.spacing(1.5),
    // },
    editWrapper: {
        position: 'absolute',
        width: 100
    }
}));

const UpdateStampcardBox = (props) => {
    const {content, collectedStamps, timestamp, addedStamps, onRemoveClick, onAddClick} = props;
    const isHistoricStampcard = content.disabled || content.afterPurchase;
    const styleProps = {
        'isHistoric': isHistoricStampcard
    };
    const classes = useStyles(styleProps);

    return (
        <React.Fragment>
            <div className={classes.stampcardWrapper}>
                {renderStamps(
                    content.stampsAmount,
                    content.title,
                    undefined,
                    content.stampPath,
                    content.stampUncollectedPath,
                    REGISTER_PURCHASE_STAMPCARD,
                    collectedStamps,
                    content.hideTitle,
                    true,
                    content.titleColor
                )}

                <img
                    className={content.disabled && classes.disabledStampcardImage}
                    height="auto"
                    width="100%"
                    src={content.imagePath + '?' + timestamp}/>
            </div>

            {/*{isHistoricStampcard && (*/}
            {/*    <Typography*/}
            {/*        variant="caption"*/}
            {/*        className={classes.historySubtitle}>*/}
            {/*        <HistoryOutlined/>*/}
            {/*        <FormattedMessage*/}
            {/*            id="historic"*/}
            {/*            defaultMessage="Historic"/>*/}
            {/*    </Typography>*/}
            {/*)}*/}

            {!content.afterPurchase && (
                <div>
                    <div className={classes.details}>
                        <Typography
                            variant="caption"
                            align="center">
                            <FormattedMessage
                                id="collectedStamps"
                                defaultMessage="Collected stamps"/>:
                        </Typography>

                        <Typography
                            variant="body2"
                            align="center"
                            className={classes.textBold}>
                            {collectedStamps} / {content.stampsAmount}
                        </Typography>

                        {!isHistoricStampcard &&
                        <React.Fragment>
                            <Typography
                                variant="caption"
                                align="center">
                                <FormattedMessage
                                    id="addedStamps"
                                    defaultMessage="Added stamps"/>:
                            </Typography>

                            <Typography
                                variant="body2"
                                align="center"
                                className={classes.textBold}>
                                {addedStamps || 0}
                            </Typography>
                        </React.Fragment>
                        }
                    </div>
                    {!isHistoricStampcard &&
                    <div className={classes.actionButtons}>
                        <Button
                            label={<Remove/>}
                            customColor="red"
                            disabled={content.disabled}
                            onClick={onRemoveClick}/>

                        <Button
                            label={<Add/>}
                            color="primary"
                            disabled={content.disabled}
                            onClick={onAddClick}/>
                    </div>
                    }
                </div>
            )}

            {content.disabled && <div>
                <hr style={{borderColor: colorGrey500}}/>
                <Typography
                    variant="caption">
                    {PUBLISH_STATUSES.find(status => status.value === content.stampcardUsageStatus).message}
                    <br/>
                    {getFormattedDate(content.lastStatusUpdateDate)}
                </Typography>
            </div>
            }

            {content.afterPurchase && (
                <div className={classes.boxText}>
                    <Typography
                        variant="caption"
                        align="center">
                        <FormattedMessage
                            id="currentCartCreatedAfterPurchase"
                            defaultMessage="Current card created after this purchase."/>
                    </Typography>

                    <Typography
                        variant="caption"
                        align="center">
                        <FormattedMessage
                            id="stampsCanBeAddedInNewPurchase"
                            defaultMessage="Stamp can be added in a new purchase."/>
                    </Typography>
                </div>
            )}
        </React.Fragment>
    )
};

export default UpdateStampcardBox
