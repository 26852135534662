import {
    ONBOARDING_MERCHANT,
    ONBOARDING_CONSUMER,
    ONBOARDING_VERIFICATION,
    ONBOARDING_FIND_COMPANY
} from "../actions/types";
export default function (state = {}, action) {
    switch (action.type) {
        case ONBOARDING_MERCHANT:
            return {...state, onboarding: action.payload};
        case ONBOARDING_FIND_COMPANY:
            return {...state, company: action.payload};
        case ONBOARDING_CONSUMER:
            return {...state, onboarding: action.payload};
        case ONBOARDING_VERIFICATION:
            return {...state, onboardingVerification: action.payload};
    }

    return state;
}
