import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Home from "@material-ui/icons/Home";
import LocationCity from "@material-ui/icons/LocationCity";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Person from "@material-ui/icons/Person";

import ConsumerMembershipCard from "./ConsumerMembershipCard";

import {zIndex999998} from "../../../common/utils/style-const";
import {
    CONSUMER_ACCOUNT_URL,
    CONSUMER_CONTACT_URL,
    CONSUMER_PURCHASES_URL,
    HOME_REDIRECT_URL
} from "../../../../static/utils/redirect-const";

const styles = theme => ({
    footerWrapper: {
        paddingBottom: 120
    },
    navigationBar: {
        display: 'inline-flex',
        justifyContent: 'space-around',
        position: 'fixed',
        width: '105%',
        height: 51,
        bottom: 0,
        left: '50%',
        textAlign: 'center',
        transform: 'translate(-50%, 0)',
        zIndex: zIndex999998,
        backgroundColor: theme.palette.common.white
    },
    navigationItem: {
        display: 'grid',
        justifyItems: 'center',
        position: 'relative',
        flex: '1 100%',
        margin: 'auto 0',
        color: theme.palette.grey.main,
        "&:hover": {
            color: theme.palette.secondary.main
        }
    }
});

class ConsumerFooter extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        user: PropTypes.object,
        clubPointsActive: PropTypes.bool,
        isPhone: PropTypes.bool
    };

    render() {
        const {classes, user, clubPointsActive} = this.props;

        return (
            <React.Fragment>
                <ConsumerMembershipCard user={user}/>
                <nav className={classes.navigationBar}>
                    <Link
                        to={HOME_REDIRECT_URL}
                        className={classes.navigationItem}>
                        <Home/>
                        <Typography
                            variant="caption"
                            color="inherit">
                            <FormattedMessage
                                id="home"
                                defaultMessage="Home"/>
                        </Typography>
                    </Link>

                    <Link
                        to={CONSUMER_PURCHASES_URL}
                        className={classes.navigationItem}>
                        <ShoppingBasket/>
                        <Typography
                            variant="caption"
                            color="inherit">
                            {clubPointsActive
                                ? <FormattedMessage
                                    id="points"
                                    defaultMessage="Points"/>
                                : <FormattedMessage
                                    id="purchases"
                                    defaultMessage="Purchases"/>}
                        </Typography>
                    </Link>

                    <Link
                        to={CONSUMER_CONTACT_URL}
                        className={classes.navigationItem}>
                        <LocationCity/>
                        <Typography
                            variant="caption"
                            color="inherit">
                            <FormattedMessage
                                id="contact"
                                defaultMessage="Contact"/>
                        </Typography>
                    </Link>

                    <Link
                        to={CONSUMER_ACCOUNT_URL}
                        className={classes.navigationItem}>
                        <Person/>
                        <Typography
                            variant="caption"
                            color="inherit">
                            <FormattedMessage
                                id="account"
                                defaultMessage="Account"/>
                        </Typography>
                    </Link>
                </nav>
            </React.Fragment>
        )
    }

}

export default withStyles(styles)(ConsumerFooter);
