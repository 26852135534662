import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import ConsumerFooter from "./ConsumerFooter";

class AppFooter extends React.Component {

    static propTypes = {
        auth: PropTypes.object,
        app: PropTypes.object,
        user: PropTypes.object,
        clubPoints: PropTypes.object,
        clubPointsActive: PropTypes.bool
    };

    render() {
        const {auth, app, user, clubPointsActive} = this.props;

        if (!auth || !auth.authenticated || !user || !user.profile) {
            return null
        }

        return (
            <ConsumerFooter
                auth={auth}
                app={app}
                user={user}
                clubPointsActive={clubPointsActive}/>
        );
    }

}

export default (withRouter(AppFooter));
