import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import {daysOfWeek} from "../../common/components/common/form/OpeningHoursFields";
import Spinner from "../../common/components/common/Spinner";
import Container from "../../common/components/common/Container";
import Paper from "../../common/components/common/Paper";

import {fetchPointOfSaleWithoutAuthBySubpage} from "../actions/kundklubb-public-actions";

class Subpage extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        match: PropTypes.object,
        pointOfSale: PropTypes.object,
        fetchPointOfSaleWithoutAuthBySubpage: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchPointOfSaleWithoutAuthBySubpage(this.props.match.params.subpage);
    }

    render() {
        const {app, pointOfSale} = this.props

        if (!pointOfSale) {
            return <Spinner app={app}/>
        } else if (pointOfSale.name === "Non Existing") {
            return (
                <Container>
                    <Paper padding>
                        There is no point of sale under this subpage.
                    </Paper>
                </Container>
            )
        }

        const address = pointOfSale.address;

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    {this.renderPointOfSaleLogo(pointOfSale)}

                    {pointOfSale.commercialName && (
                        <Typography
                            paragraph
                            variant="h6">
                            {pointOfSale.commercialName}
                        </Typography>
                    )}

                    {address && address.street && (
                        <Typography
                            variant="body1">
                            {address.street}
                        </Typography>
                    )}

                    {(address && (address.zipCode || address.city)) && (
                        <Typography
                            variant="body1">
                            {address.zipCode} {address.city}
                        </Typography>
                    )}

                    {pointOfSale.phone && (
                        <Typography
                            variant="body1">
                            {pointOfSale.phone}
                        </Typography>
                    )}

                    {pointOfSale.email && (
                        <Typography
                            variant="body1">
                            {pointOfSale.email}
                        </Typography>
                    )}

                    <Typography
                        variant="body1">
                        <FormattedMessage
                            id="openingHours"
                            defaultMessage="Opening hours"/>:
                    </Typography>

                    {pointOfSale && pointOfSale.openingHours && (
                        <React.Fragment>
                            {pointOfSale.openingHours.map((pointOfSale) => (
                                <div key={pointOfSale.id}>
                                    <Typography variant="body1">
                                        {this.getDayName(pointOfSale.dayFrom)}
                                        {pointOfSale.dayTo
                                            ? "- " + this.getDayName(pointOfSale.dayTo)
                                            : ""}
                                    </Typography>
                                    <Typography variant="body1">
                                        {pointOfSale.timeFrom} - {pointOfSale.timeTo}
                                    </Typography>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </Paper>
            </Container>
        );
    }

    renderPointOfSaleLogo(pointOfSale) {
        const timestamp = new Date().getTime();
        if (pointOfSale.logoPath) {
            return (
                <img
                    height={52}
                    src={pointOfSale.logoPath + '?' + timestamp}/>
            )
        }
    }

    getDayName(dayOrderNumber) {
        const day = daysOfWeek.find(d => d.value === dayOrderNumber);
        return day ? day.key : '';
    }

}

function mapStateToProps(state) {
    return {
        pointOfSale: state.company.pointOfSale,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchPointOfSaleWithoutAuthBySubpage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Subpage);
