import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import ConsumerOnboardingForm, {CONSUMER_ONBOARDING_FORM_NAME} from "./ConsumerOnboardingForm";

import {consumerOnboarding} from "../../../common/actions/onboarding-actions";
import {
    fetchCommercialChainWithoutAuthBySubdomain,
    fetchCompanySmsAccountBySubdomain,
    fetchPointOfSaleWithoutAuthBySubpage
} from "../../actions/kundklubb-public-actions";
import {getCurrentSubdomain} from "../../actions/kundklubb-utils-actions";
import {getPotentiallySubpageFromPathname, isSubpageInPathname} from "../../../common/utils/string-utils";
import LanguageSelect from "../../../common/components/common/language/LanguageSelect";

class ConsumerOnboarding extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        chain: PropTypes.object,
        pointOfSale: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object,
        smsAccount: PropTypes.object,
        language: PropTypes.string,
        fetchPointOfSaleWithoutAuthBySubpage: PropTypes.func,
        fetchCompanySmsAccountBySubdomain: PropTypes.func,
        fetchCommercialChainWithoutAuthBySubdomain: PropTypes.func,
        consumerOnboarding: PropTypes.func
    };

    componentDidMount() {
        const {location: {pathname}, fetchCommercialChainWithoutAuthBySubdomain, fetchPointOfSaleWithoutAuthBySubpage, fetchCompanySmsAccountBySubdomain} = this.props;
        fetchCommercialChainWithoutAuthBySubdomain();
        fetchCompanySmsAccountBySubdomain();

        const potentiallySubpage = getPotentiallySubpageFromPathname(pathname);
        if (isSubpageInPathname(potentiallySubpage)) {
            fetchPointOfSaleWithoutAuthBySubpage(potentiallySubpage);
        }
    }

    render() {
        const {app, chain, pointOfSale, location, smsAccount} = this.props;

        if (!chain || (isSubpageInPathname(getPotentiallySubpageFromPathname(location.pathname))
                && !pointOfSale)) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography variant="h6">
                        <FormattedMessage
                            id="signUp"
                            defaultMessage="Sign up"/>
                    </Typography>

                    <ConsumerOnboardingForm
                        chain={chain}
                        smsAccount={smsAccount}
                        app={app}
                        onSubmit={this.consumerOnboarding.bind(this, pointOfSale)}
                        initialValues={{
                            "commercialChainSubdomain": getCurrentSubdomain(),
                            "pointOfSaleId": pointOfSale.id,
                        }}/>

                    <LanguageSelect/>
                </Paper>
            </Container>
        )
    }

    consumerOnboarding(pointOfSale, values) {
        const {consumerOnboarding, history, language} = this.props;
        consumerOnboarding(
            {
                ...values,
                'language': language
            },
            history,
            CONSUMER_ONBOARDING_FORM_NAME,
            pointOfSale
                ? pointOfSale.subpage
                : null
        );
    }
}

function mapStateToProps(state) {
    return {
        chain: state.company.commercialChain,
        pointOfSale: state.company.pointOfSale,
        language: state.app.language,
        smsAccount: state.company.smsAccount,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        consumerOnboarding,
        fetchCommercialChainWithoutAuthBySubdomain,
        fetchPointOfSaleWithoutAuthBySubpage,
        fetchCompanySmsAccountBySubdomain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerOnboarding);
