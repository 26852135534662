import {FETCH_COMMERCIAL_CHAIN_PURCHASES, FETCH_PURCHASE, FETCH_PURCHASES} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_COMMERCIAL_CHAIN_PURCHASES:
            return {...state, purchases: action.payload};
        case FETCH_PURCHASES:
            return {...state, purchases: action.payload};
        case FETCH_PURCHASE:
            return {...state, purchase: action.payload};
    }

    return state;
}
