import React from "react";
import classnames from "classnames";

import {makeStyles} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

import NumberFormat from "react-number-format"
import {FLOAT_FORMAT_CONFIG} from "../../../merchant/utils/formats";

export const required = "Required";

const KarmingNumberFormat = (props) => {
    const {onChange, inputRef, ...restInput} = props;
    return (
        <NumberFormat
            {...restInput}
            getInputRef={inputRef}
            onValueChange={values => onChange(
                values.floatValue === undefined
                    ? null
                    : values.floatValue)}/>
    )
};

const useStyles = makeStyles((theme => ({
    textField: {
        width: '100%',
        marginTop: theme.spacing(1),
        '& .MuiInputLabel-formControl': {
            width: 'max-content'
        },
    },
    multilineTextField: {
        width: '100%',
        marginTop: theme.spacing(1),
        textAlign: 'left',
        "& .text-area": {
            border: `0.5px solid ${theme.palette.secondary.main}`,
            borderRadius: theme.shape.borderRadius
        },
        "& .text-area hr": {
            display: 'none'
        },
        "& .text-area label": {
            left: theme.spacing(1),
        }
    },
    textFieldDashed: {
        '& .MuiInput-underline.Mui-disabled:before': {
            border: `1px dashed ${theme.palette.grey.main}`,
            top: 0
        },
        '& .MuiInputBase-root.Mui-disabled': {
            paddingLeft: theme.spacing(1)
        }
    }
})));

const RenderTextField = props => {
    const {
        className, hintText, helperText, validate, style, type, multiline, rows, rowsMax, input, formatConfig,
        onKeyPress, maxLength, meta: {touched, error}, disabled, min, max, placeholder, endAdornment, hasDashedBorder,
        ...rest
    } = props;

    const classes = useStyles(props);

    let classInput = classnames(
        className, classes.textField,
        multiline && classes.multilineTextField,
        hasDashedBorder && classes.textFieldDashed);

    const isNumberField = type === 'number';
    const numberFormatConfig = isNumberField
        ? {
            InputProps: {inputComponent: KarmingNumberFormat, endAdornment: endAdornment},
            inputProps: {...formatConfig, min: min, max: max}
        }
        : {};

    const inputProps = maxLength ? {inputProps: {maxLength: maxLength}} : {};

    return (
        <TextField
            {...rest}
            {...input}
            {...numberFormatConfig}
            {...inputProps}
            className={classInput}
            style={style}
            type={isNumberField ? 'text' : type}
            onKeyPress={onKeyPress}
            onKeyDown={(event) => {
                if (multiline && rowsMax && event.key === "Enter"
                    && input.value.toString().split("\n").length >= rowsMax) {
                    event.preventDefault()
                }
            }}
            multiline={multiline}
            rows={rows}
            rowsMax={rowsMax}
            validate={validate}
            error={touched && !!error}
            label={hintText}
            helperText={touched && error ? error : helperText}
            placeholder={placeholder}
            disabled={disabled}
            min={min}
            // TODO     required={true}
        />
    )
};

RenderTextField.defaultProps = {
    formatConfig: FLOAT_FORMAT_CONFIG
};

export default RenderTextField
