import React from "react";
import {Route} from "react-router-dom";

import ConsumerPrivacyPolicy from "./terms/ConsumerPrivacyPolicy";
import ConsumerMembershipTerms from "./terms/ConsumerMembershipTerms";
import CampaignPageContainer from "./CampaignPageContainer";
import CouponPageContainer from "./CouponPageContainer";

const GuestRoutes = (props) => {
    const {auth, app} = props;

    return [
        <Route
            key='1'
            exact path="/terms/membership/"
            auth={auth}
            app={app}
            component={ConsumerMembershipTerms}/>,

        <Route
            key='2'
            exact path="/terms/privacy/"
            auth={auth}
            app={app}
            component={ConsumerPrivacyPolicy}/>,

        <Route
            key='3'
            exact path="/c/:id/"
            auth={auth}
            app={app}
            component={CampaignPageContainer}/>,

        <Route
            key='4'
            exact path="/r/:id/"
            auth={auth}
            app={app}
            component={CouponPageContainer}/>,
    ]
};

export default GuestRoutes
