import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import AuthReducer from "./AuthReducer";
import AdminReducer from "./AdminReducer";
import OnboardingReducer from "./OnboardingReducer";
import AppReducer from "./AppReducer";
import UserReducer from "./UserReducer";
import MerchantReducer from "./MerchantReducer";
import ConsumerReducer from "./ConsumerReducer";
import CompanyReducer from "./CompanyReducer";
import PurchaseReducer from "./PurchaseReducer";
import {UNAUTH_USER} from "../actions/types";
import MerchantChainsReducers from './merchant-chains-reducers';
import MerchantTermsReducers from './merchant-terms-reducers';
import MerchantPointsReducers from './merchant-points-reducer';

const appReducer = combineReducers({
    form: formReducer,
    app: AppReducer,
    auth: AuthReducer,
    user: UserReducer,
    admin: AdminReducer,
    merchant: MerchantReducer,
    consumer: ConsumerReducer,
    purchase: PurchaseReducer,
    company: CompanyReducer,
    onboarding: OnboardingReducer,
    chains: MerchantChainsReducers,
    terms: MerchantTermsReducers,
    points: MerchantPointsReducers
});

const rootReducer = (state, action) => {
    if (action.type === UNAUTH_USER) {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer;
