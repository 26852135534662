import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field} from "redux-form";
import PropTypes from "prop-types";

import MerchantOnboardingForm from "./MerchantOnboardingForm";
import Checkbox from "../../../common/components/common/form/RenderCheckbox";
import {merchantOnboarding} from "../../../common/actions/onboarding-actions";

export const renderAgreementCheckbox = (name, label) => {
    return (
        <Field
            name={name}
            label={label}
            type="checkbox"
            component={Checkbox}/>
    )
};

class MerchantOnboarding extends React.Component {

    static propTypes = {
        match: PropTypes.object,
        history: PropTypes.object,
        merchantOnboarding: PropTypes.func
    };

    merchantOnboarding(history, values) {
        this.props.merchantOnboarding({
            ...values,
            'source': this.props.match.params.source
        }, history)
    }

    render() {
        const {history} = this.props;
        const source = this.props.match.params.source;

        return (
            <MerchantOnboardingForm
                source={source}
                onSubmit={this.merchantOnboarding.bind(this, history)}>
            </MerchantOnboardingForm>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({merchantOnboarding}, dispatch);
}

export default connect(null, mapDispatchToProps)(MerchantOnboarding);
