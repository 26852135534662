import React from "react";
import {FormattedMessage} from "react-intl";
import DateFnsUtils from '@date-io/date-fns';

import {makeStyles} from "@material-ui/styles";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Close from '@material-ui/icons/Close';
import DateRange from '@material-ui/icons/DateRange';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AccessTime from '@material-ui/icons/AccessTime';
import IconButton from "@material-ui/core/IconButton";

import {DATE_FORMAT, endOfDayForDate} from "../../../utils/date-utils";
import {colorGrey500} from "../../../utils/color-const";

export const atLeastOneDateRequired = value => (
    value
        ? undefined
        : <FormattedMessage
            id="dateFromOrDateToIsRequired"
            defaultMessage="Start date or end date is required"/>
);

const useStyles = makeStyles((theme => ({
    root: {
        position: 'relative'
    },
    datePicker: {
        width: '100%'
    },
    icon: {
        position: 'absolute',
        right: 48,
        bottom: -theme.spacing(1),
    },
    iconMaxOnRight: {
        position: 'absolute',
        right: 0,
        top: theme.spacing(1),
    },
    helperText: {
        fontSize: 12,
        color: colorGrey500
    }
})));

const RenderDatePicker = props => {
    const {
        disableKeyboard, input: {value, onChange, onBlur}, hintText, endOfDay, maxDate,
        meta: {touched, error}, clearable, helperText, onInputChange, ...rest
    } = props;
    const classes = useStyles();

    const Picker = disableKeyboard ? DatePicker : KeyboardDatePicker;
    return (
        <React.Fragment>
            <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div>
                        <Picker
                            {...rest}
                            className={classes.datePicker}
                            label={hintText}
                            format={DATE_FORMAT}
                            value={value ? new Date(value) : null}
                            onChange={value => {
                                onChange(parseTime(value, endOfDay));
                                if (onInputChange) {
                                    onInputChange(parseTime(value, endOfDay))
                                }
                            }}
                            onBlur={event => {
                                const dateUtils = new DateFnsUtils();
                                const dateValue = dateUtils.parse(event.target.value, DATE_FORMAT);
                                if (dateValue) {
                                    onChange(parseTime(dateUtils.isValid(dateValue) ? dateValue : null, endOfDay));
                                }
                            }}
                            animateYearScrolling={false}
                            maxDate={maxDate}
                            onClose={onBlur}
                            error={touched && error}
                            helperText={touched && error}
                            keyboard={(!disableKeyboard).toString()}
                            rightArrowIcon={<KeyboardArrowRight/>}
                            leftArrowIcon={<KeyboardArrowLeft/>}
                            daterangeicon={<DateRange/>}
                            timeicon={<AccessTime/>}
                        />
                        {clearable && value && (
                            <IconButton
                                className={disableKeyboard ? classes.iconMaxOnRight : classes.icon}
                                onClick={() => onChange('')}>
                                <Close/>
                            </IconButton>
                        )}
                    </div>
                </MuiPickersUtilsProvider>
            </div>
            <span className={classes.helperText}>
                {helperText && helperText}
                </span>
        </React.Fragment>
    );
};

const parseTime = (date, endOfDay) => {
    return date
        ? (endOfDay
            ? endOfDayForDate(date.getTime())
            : new Date(date.getTime()))
        : null;
};

export default RenderDatePicker
