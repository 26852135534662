import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";

import {withStyles} from "@material-ui/core/styles";

import Pager from "../../pager/Pager";

const styles = theme => ({
    table: {
        "& .rt-tbody": {
            fontWeight: 300
        },
        "& :focus": {
            outline: 0
        },
        "& .rt-noData": {
            display: 'none'
        },
        "& .rt-tbody .rt-tr-group:last-child": {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        "& .rt-tr.-odd": {
            background: theme.palette.primary.light
        },
        "& .rt-tbody .rt-tr:not(.-padRow):hover": {
            cursor: 'pointer',
            background: theme.palette.primary.lightDark
        }
    }
});

class DesktopKarmingTable extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        baseSearchConfig: PropTypes.object,
        data: PropTypes.array,
        columns: PropTypes.array,
        pageSize: PropTypes.number,
        totalCount: PropTypes.number,
        showPagination: PropTypes.bool,
        sortable: PropTypes.bool,
        sortByDateOfPurchase: PropTypes.bool,
        pager: PropTypes.bool,
        onRowClickHistoryPush: PropTypes.func,
        onSearchConfigChanged: PropTypes.func
    };

    render() {
        const {classes, history, data, columns, pageSize, onRowClickHistoryPush, pager, totalCount, onSearchConfigChanged, baseSearchConfig, sortable, sortByDateOfPurchase} = this.props;

        // FIXME works only for purchases
        if (sortByDateOfPurchase) {
            data.sort((a, b) => new Date(b.dateOfPurchase) - new Date(a.dateOfPurchase));
        }

        return (
            <ReactTable
                className={classes.table}
                data={data}
                columns={columns}
                defaultPageSize={pageSize}
                resizable={false}
                sortable={sortable}
                getTdProps={(state, rowInfo) => {
                    return {
                        onClick: () => {
                            if (rowInfo) {
                                history.push(onRowClickHistoryPush(state, rowInfo.original))
                            }
                        }
                    }
                }}
                PaginationComponent={pager && Pager}
                totalCount={totalCount
                    ? totalCount
                    : data
                        ? data.length
                        : 0}
                onSearchConfigChanged={onSearchConfigChanged}
                baseSearchConfig={baseSearchConfig}/>
        )
    }

}

export default withStyles(styles)(DesktopKarmingTable)
