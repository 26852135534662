import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Spinner from "../../../common/components/common/Spinner";
import ConsumerPoints from "./ConsumerPoints";
import ConsumerPurchaseList from "./ConsumerPurchaseList";

import {fetchCommercialChain, fetchPurchases} from "../../actions/consumer-actions";
import {fetchClubPointsWithoutAuthBySubdomain} from "../../actions/kundklubb-public-actions";

class ConsumerPurchases extends React.Component {

    static propTypes = {
        clubPoints: PropTypes.object,
        app: PropTypes.object,
        user: PropTypes.object,
        commercialChain: PropTypes.object,
        history: PropTypes.object,
        purchases: PropTypes.array,
        fetchPurchases: PropTypes.func,
        fetchCommercialChain: PropTypes.func,
        fetchClubPointsWithoutAuthBySubdomain: PropTypes.func
    };

    componentDidMount() {
        const {fetchPurchases, fetchCommercialChain, fetchClubPointsWithoutAuthBySubdomain} = this.props;
        fetchPurchases();
        fetchCommercialChain();
        fetchClubPointsWithoutAuthBySubdomain();
    }

    render() {
        const {purchases, commercialChain, clubPoints, history, app, user: {profile}} = this.props;
        const clubPointsActive = clubPoints && clubPoints.active;

        if (!purchases || !commercialChain || !clubPoints) {
            return <Spinner app={app}/>
        }

        if (clubPointsActive) {
            return (
                <ConsumerPoints
                    history={history}
                    purchases={purchases}
                    clubPoints={clubPoints}
                    language={profile && profile.language}
                    app={app}/>
            )
        }

        return (
            <ConsumerPurchaseList
                history={history}
                purchases={purchases}/>
        )
    }

}

function mapStateToProps(state) {
    return {
        commercialChain: state.consumer.commercialChain,
        clubPoints: state.company.clubPoints,
        purchases: state.consumer.purchases
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPurchases,
        fetchCommercialChain,
        fetchClubPointsWithoutAuthBySubdomain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerPurchases);
