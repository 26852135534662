import React from "react";
import PropTypes from "prop-types";

import MobileKarmingTable from "./MobileKarmingTable";
import DesktopKarmingTable from "./DesktopKarmingTable";
import RenderOnMobile from "../RenderOnMobile";
import RenderOnDesktop from "../RenderOnDesktop";

export const DEFAULT_PAGE_SIZE = 15;

export class KarmingTable extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        baseSearchConfig: PropTypes.object,
        data: PropTypes.array,
        columns: PropTypes.array,
        pageSize: PropTypes.number,
        totalCount: PropTypes.number,
        showPagination: PropTypes.bool,
        sortable: PropTypes.bool,
        pager: PropTypes.bool,
        sortByDateOfPurchase: PropTypes.bool,
        onSearchConfigChanged: PropTypes.func,
        onRowClickHistoryPush: PropTypes.func
    };

    static defaultProps = {
        pageSize: DEFAULT_PAGE_SIZE,
        showPagination: true
    };

    render() {
        const {
            history, onSearchConfigChanged, pager, totalCount, baseSearchConfig, data, columns, pageSize,
            onRowClickHistoryPush, showPagination, sortable, sortByDateOfPurchase
        } = this.props;

        const paginationIfLessDataThenDefault = data && data.length > pageSize;

        const overriddenShowPagination = showPagination && paginationIfLessDataThenDefault;
        const overriddenPageSize = (data && data.length + 1 < pageSize)
            ? Math.max(KarmingTable.defaultProps.pageSize / 2, data.length + 1)
            : pageSize;

        return (
            <React.Fragment>
                <RenderOnMobile>
                    <MobileKarmingTable
                        data={data}
                        columns={columns}
                        defaultPageSize={overriddenPageSize}
                        sortByDateOfPurchase={sortByDateOfPurchase}
                        showPagination={overriddenShowPagination}
                        onClick={(state, row) => {
                            if (row) {
                                history.push(onRowClickHistoryPush(state, row))
                            }
                        }}
                        totalCount={totalCount}
                        pager={pager}
                        onSearchConfigChanged={onSearchConfigChanged}
                        baseSearchConfig={baseSearchConfig}/>
                </RenderOnMobile>
                <RenderOnDesktop>
                    <DesktopKarmingTable
                        history={history}
                        data={data}
                        columns={columns}
                        sortable={sortable}
                        sortByDateOfPurchase={sortByDateOfPurchase}
                        pageSize={overriddenPageSize}
                        onRowClickHistoryPush={onRowClickHistoryPush}
                        showPagination={overriddenShowPagination}
                        pager={pager}
                        totalCount={totalCount}
                        onSearchConfigChanged={onSearchConfigChanged}
                        baseSearchConfig={baseSearchConfig}/>
                </RenderOnDesktop>
            </React.Fragment>
        )
    }

}
