import axios from "axios";
import {FETCH_PURCHASE} from "./types";
import {headerConfig, apiError} from "./utils-actions";
import {ROOT_URL} from "../config/karming-links";

export function fetchPurchase(id) {
    return dispatch => {
        axios.get(`${ROOT_URL}/purchases/${id}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_PURCHASE,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch purchase', response));
            });
    }
}
