import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Spinner from "../../../common/components/common/Spinner";
import CouponPage from "../../../common/components/common/coupon/CouponPage";

import {fetchCouponWithoutAuth} from "../../../common/actions/public-actions";

class CouponPageContainer extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        coupon: PropTypes.object,
        history: PropTypes.object,
        auth: PropTypes.object,
        match: PropTypes.object,
        fetchCouponWithoutAuth: PropTypes.func
    };

    componentDidMount() {
        const {fetchCouponWithoutAuth, match: {params: {id}}} = this.props;
        fetchCouponWithoutAuth(id);
    }

    render() {
        const {app, coupon, history, auth} = this.props;

        if (!coupon) {
            return <Spinner app={app}/>
        }

        if (coupon.publishStatus !== "ACTIVE") {
            history.push("/");
        }

        return (
            <CouponPage
                coupon={coupon}
                history={history}
                auth={auth}/>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        coupon: state.company.coupon
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCouponWithoutAuth}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponPageContainer);
