import {createMuiTheme} from '@material-ui/core';

import {colorWhite, primaryColor, primaryColorLight, primaryColorLightDark, secondaryColor} from "./color-const";
import yellow from "@material-ui/core/colors/yellow";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import lightBlue from "@material-ui/core/colors/lightBlue";
import green from "@material-ui/core/colors/green";

import {zIndex999998} from "./style-const";

const font = "'Lato', sans-serif";

const baseTheme = createMuiTheme({
    typography: {
        fontFamily: font,
        subtitle1: {
            fontWeight: 600
        },
        h4: {
            fontWeight: 600
        },
        h5: {
            fontWeight: 600
        },
        h6: {
            fontWeight: 600
        }
    },
    palette: {
        primary: {
            main: primaryColor,
            light: primaryColorLight,
            lightDark: primaryColorLightDark,
            contrastText: colorWhite
        },
        secondary: {
            main: secondaryColor,
            contrastText: colorWhite
        },
        error: {
            main: red[500],
            dark: red[700],
            light: red[50],
        },
        green: {
            main: green.A400,
            dark: green.A700,
            contrastText: colorWhite
        },
        yellow: {
            main: yellow[700],
            dark: yellow[800],
            contrastText: colorWhite
        },
        blue: {
            light: lightBlue[50]
        },
        grey: {
            main: grey[700],
            dark: grey[800],
            light: grey[300],
            contrastText: colorWhite
        },
    },
    //TODO check bellow
    fontFamily: font,
    raisedButton: {
        textTransform: 'none',
    },
    appBar: {
        height: 58
    },
    paper: {
        zDepthShadows: -100 //FIXME https://github.com/mui-org/material-ui/issues/5278 - workaround for box-shadow in Dialog
    },
    shape: {
        borderRadius: 8
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:hover:not($disabled):not($error):not($focused):before': {
                    borderBottomColor: primaryColor,
                },
            }
        },
        MuiDialog: {
            root: {
                zIndex: zIndex999998
            }
        }
    }
});

export {baseTheme as appTheme};