import React from "react";
import MediaQuery from 'react-responsive'
import PropTypes from "prop-types";

import {useTheme} from "@material-ui/styles";

const RenderOnMobile = (props) => {
    const {children} = props;
    const theme = useTheme();

    return (
        <MediaQuery maxWidth={theme.breakpoints.values["md"] - 1}>
            {children}
        </MediaQuery>
    )
};

RenderOnMobile.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    theme: PropTypes.object
};

export default RenderOnMobile;
