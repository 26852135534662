import React from "react";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import SidebarMenu from "./SidebarMenu";
import AppBarMenu from "./AppBarMenu";
import RenderOnDesktop from "../../../common/components/common/RenderOnDesktop";
import RenderOnMobile from "../../../common/components/common/RenderOnMobile";
import AppFooter from "./AppFooter";

import {fetchCommercialChain, fetchHomeLocation} from "../../actions/consumer-actions";

class ConsumerAppMenu extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        commercialChain: PropTypes.object,
        chain: PropTypes.object,
        homeLocation: PropTypes.object,
        clubPoints: PropTypes.object,
        logout: PropTypes.func,
        fetchHomeLocation: PropTypes.func,
        fetchCommercialChain: PropTypes.func
    };

    fetchDataIfPossible() {
        const {auth} = this.props;
        if (auth && auth.authenticated) {
            this.props.fetchHomeLocation();
            this.props.fetchCommercialChain();
        }
    }

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    render() {
        const {user, app, auth, logout, commercialChain, homeLocation, chain} = this.props;

        return (
            <React.Fragment>
                <AppBarMenu
                    user={user}
                    chain={chain}
                    app={app}
                    auth={auth}
                    commercialChain={commercialChain}
                    homeLocation={homeLocation}
                />

                <RenderOnDesktop>
                    <SidebarMenu
                        app={app}
                        auth={auth}
                        logout={logout}/>
                </RenderOnDesktop>

                <RenderOnMobile>
                    <AppFooter
                        auth={auth}
                        app={app}
                        user={user}
                        clubPointsActive={chain && chain.clubPointActive}
                    />
                </RenderOnMobile>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {
        commercialChain: state.consumer.commercialChain,
        homeLocation: state.consumer.homeLocation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCommercialChain, fetchHomeLocation}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConsumerAppMenu))
