import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {isAfter, isBefore} from "date-fns";

import CampaignPage from "../../../common/components/common/campaign/CampaignPage";
import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";

import {fetchCampaignWithoutAuth} from "../../../common/actions/public-actions";

class CampaignPageContainer extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        campaign: PropTypes.object,
        history: PropTypes.object,
        auth: PropTypes.object,
        fetchCampaignWithoutAuth: PropTypes.func,
        match: PropTypes.object
    };

    componentDidMount() {
        const {fetchCampaignWithoutAuth, match: {params: {id}}} = this.props;
        fetchCampaignWithoutAuth(id);
    }

    render() {
        const {app, campaign, history, auth, match} = this.props;

        if (!campaign || campaign.id !== Number(match.params.id)) {
            return <Spinner app={app}/>
        }

        const currentDate = new Date();

        if (isBefore(currentDate, campaign.startDate)
            || isAfter(currentDate, campaign.endDate)
            || campaign.publishStatus !== "ACTIVE") {
            history.push("/");
        }

        return (
            <Container>
                <CampaignPage
                    campaign={this.props.campaign}
                    singlePage={true}
                    history={history}
                    auth={auth}/>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        campaign: state.company.campaign
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCampaignWithoutAuth}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPageContainer);
