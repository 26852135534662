import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import LoginForm from "../../../common/components/auth/LoginForm";

import {fetchUserDetails} from "../../../common/actions/user-actions";
import {loginMember} from "../../actions/kundklubb-auth-actions";

class MemberLogin extends React.Component {

    static propTypes = {
        auth: PropTypes.object,
        history: PropTypes.object,
        loginMember: PropTypes.func,
        fetchUserDetails: PropTypes.func
    };

    redirectAfterLoginUrl() {
        return '';
    }

    loginWithRedirect(history, redirectAfterLoginUrl, values) {
        this.props.loginMember(values, history, redirectAfterLoginUrl);
        this.afterLogin();
    }

    componentDidMount() {
        if (this.props.auth.authenticated) {
            this.props.history.push(this.redirectAfterLoginUrl());
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.auth.authenticated) {
            this.props.history.push(this.redirectAfterLoginUrl());
            this.afterLogin();
        }
    }

    afterLogin() {
        this.props.fetchUserDetails();
    }

    render() {
        const {history} = this.props;

        return (
            <LoginForm
                consumer={true}
                history={history}
                onSubmit={this.loginWithRedirect.bind(this, this.props.history, this.redirectAfterLoginUrl())}
                passwordRequestUrl={"/auth/password/request"}/>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loginMember, fetchUserDetails}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MemberLogin));

