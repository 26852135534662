import {
    CREATE_CAMPAIGN,
    CREATE_CATEGORY,
    FETCH_BILLING_DETAILS,
    FETCH_CAMPAIGN,
    FETCH_CAMPAIGNS,
    FETCH_CATEGORIES,
    FETCH_CONSUMER,
    FETCH_CONSUMER_CAMPAIGNS,
    FETCH_CONSUMER_STAMPCARD_COUPON_USAGES,
    FETCH_CONSUMER_STAMPCARD_COUPONS,
    FETCH_CONSUMERS,
    FETCH_CONSUMERS_NUMBER,
    FETCH_MERCHANT_CURRENT_POINT_OF_SALE,
    FETCH_MERCHANT_POINT_OF_SALE,
    FETCH_MERCHANT_POINTS_OF_SALE,
    FETCH_SENDOUT_HISTORY,
    FETCH_SMART_SELECTION,
    FETCH_SMS_HISTORY,
    FETCH_STAMPCARD, FETCH_STAMPCARD_USAGES,
    FETCH_STAMPCARDS,
    FILTER_MEMBERS_FOR_CAMPAIGNS,
    MERCHANT_FETCH_MERCHANT,
    MERCHANT_FETCH_MERCHANTS,
    REGISTER_PURCHASE,
    SET_CURRENT_SMART_SELECTION,
    UPDATE_CAMPAIGN
} from "../actions/types";
import {selectCampaignsWithoutWelcomeOffer, selectWelcomeOffer} from "../../merchant/selectors/campaign-selectors";

export default function (state = {}, action) {

    switch (action.type) {
        case REGISTER_PURCHASE:
            return {...state, registerPurchase: action.payload};
        case FETCH_CONSUMER:
            return {...state, consumer: action.payload};
        case FETCH_BILLING_DETAILS:
            return {...state, billingDetails: action.payload};
        case FETCH_SMS_HISTORY:
            return {...state, smsHistory: action.payload};
        case FETCH_CONSUMERS:
            return {...state, consumers: action.payload};
        case FETCH_CONSUMERS_NUMBER:
            return {...state, consumersNumber: action.payload};
        case MERCHANT_FETCH_MERCHANTS:
            return {...state, merchants: action.payload};
        case MERCHANT_FETCH_MERCHANT:
            return {...state, merchant: action.payload};
        case CREATE_CAMPAIGN:
            return {...state, createCampaign: action.payload};
        case UPDATE_CAMPAIGN:
            return {...state, updateCampaign: action.payload};
        case FETCH_CAMPAIGN:
            if (!action.payload) {
                return {...state, campaign: {name: 'Non Existing'}};
            }
            return {...state, campaign: action.payload};
        case FETCH_CAMPAIGNS:
            return {
                ...state,
                campaigns: selectCampaignsWithoutWelcomeOffer(action.payload),
                welcomeOffer: selectWelcomeOffer(action.payload)
            };
        case FETCH_CONSUMER_CAMPAIGNS:
            return {...state, consumerCampaigns: action.payload};
        case CREATE_CATEGORY:
            return {...state, createCategory: action.payload};
        case FETCH_CATEGORIES:
            return {...state, categories: action.payload};
        case FETCH_MERCHANT_POINT_OF_SALE:
            return {...state, pointOfSale: action.payload};
        case FETCH_MERCHANT_CURRENT_POINT_OF_SALE:
            return {...state, currentPointOfSale: action.payload};
        case FETCH_MERCHANT_POINTS_OF_SALE:
            return {...state, pointsOfSale: action.payload};
        case FETCH_SMART_SELECTION:
            if (!action.payload) {
                return {...state, smartSelection: {name: 'Non Existing'}};
            }
            return {...state, smartSelection: action.payload};
        case SET_CURRENT_SMART_SELECTION:
            return {...state, currentSmartSelection: action.payload};
        case FILTER_MEMBERS_FOR_CAMPAIGNS:
            return {...state, membersForCampaign: action.payload};
        case FETCH_SENDOUT_HISTORY:
            return {...state, sendoutHistory: action.payload};
        case FETCH_STAMPCARD:
            return {...state, stampcard: action.payload};
        case FETCH_STAMPCARD_USAGES:
            return {...state, stampcardUsages: action.payload};
        case FETCH_STAMPCARDS:
            if (!action.payload) {
                return {...state, stampcards: {name: 'Non Existing'}};
            }
            return {...state, stampcards: action.payload};
        case FETCH_CONSUMER_STAMPCARD_COUPONS:
            return {...state, consumerStampcardCoupons: action.payload};
        case FETCH_CONSUMER_STAMPCARD_COUPON_USAGES:
            return {...state, consumerStampcardCouponUsages: action.payload};
    }

    return state;
}
