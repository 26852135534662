import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";

const styles = theme => ({
    redButton: {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        }
    }
});

class ConsumerUnsubscribeForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        contactData: PropTypes.object,
        chain: PropTypes.object,
        type: PropTypes.object,
        moveToService: PropTypes.object,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {classes, handleSubmit, contactData, chain, type, moveToService} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    paragraph
                    variant="h6">
                    {contactData}
                </Typography>

                <Typography
                    paragraph
                    variant="body1">
                    <FormattedMessage
                        id="areYouSureUnsubscribeText"
                        defaultMessage="Are you sure you want to unsubscribe from {type} notification from {commercial}?"
                        values={{
                            type: type,
                            commercial: chain.commercialName
                        }}/>
                </Typography>

                <Button
                    classes={{root: classes.redButton}}
                    label={<FormattedMessage
                        id="no"
                        defaultMessage="No"/>}
                    type="submit"
                    onClick={moveToService}/>

                <Button
                    label={<FormattedMessage
                        id="unsubscribe"
                        defaultMessage="Unsubscribe"/>}
                    type="submit"/>
            </form>
        )
    }

}

export default reduxForm({
    form: 'consumerOnboardingVerificationForm',
})(withStyles(styles)(ConsumerUnsubscribeForm));
