import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToApp from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BallotOutlined from '@material-ui/icons/BallotOutlined';
import Typography from "@material-ui/core/Typography";

import Spinner from "../../../common/components/common/Spinner";

import {fetchCommercialChain} from "../../actions/consumer-actions";
import {logout} from "../../../common/actions/auth-actions";
import LanguageSelect from "../../../common/components/common/language/LanguageSelect";
import {setUserLanguage} from "../../../common/actions/user-actions";

const styles = theme => ({
    item: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        "& > :first-child": {
            marginRight: 0
        }
    },
    selectWrapper: {
        padding: theme.spacing(2)
    }
});

class ConsumerAccount extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        history: PropTypes.object,
        commercialChain: PropTypes.object,
        logout: PropTypes.func,
        fetchCommercialChain: PropTypes.func,
        setUserLanguage: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchCommercialChain();
    }

    render() {
        const {classes, app, history, commercialChain, setUserLanguage} = this.props;

        if (!commercialChain) {
            return <Spinner app={app}/>
        }

        return (
            <React.Fragment>
                <MenuItem
                    className={classes.item}
                    onClick={this.pushToProfile.bind(this, history)}>
                    <ListItemIcon>
                        <AccountCircle/>
                    </ListItemIcon>

                    <ListItemText>
                        <Typography color="inherit">
                            <FormattedMessage
                                id="editProfile"
                                defaultMessage="Edit profile"/>
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    className={classes.item}
                    onClick={this.pushToMembershipConditions.bind(this, history)}>
                    <ListItemIcon>
                        <BallotOutlined/>
                    </ListItemIcon>

                    <ListItemText>
                        <Typography color="inherit">
                            <FormattedMessage
                                id="membershipConditions"
                                defaultMessage="Membership Conditions"/>
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    className={classes.item}
                    onClick={this.pushToManageMembership.bind(this, history)}>
                    <ListItemIcon>
                        <AccountCircle/>
                    </ListItemIcon>

                    <ListItemText>
                        <Typography color="inherit">
                            <FormattedMessage
                                id="manageMembership"
                                defaultMessage="Manage Membership"/>
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    className={classes.item}
                    onClick={this.pushToPrivacyPolicy.bind(this, history)}>
                    <ListItemIcon>
                        <BallotOutlined/>
                    </ListItemIcon>

                    <ListItemText>
                        <Typography color="inherit">
                            <FormattedMessage
                                id="privacyPolicy"
                                defaultMessage="Privacy Policy"/>
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem
                    className={classes.item}
                    onClick={this.logout.bind(this, history)}>
                    <ListItemIcon>
                        <ExitToApp/>
                    </ListItemIcon>

                    <ListItemText>
                        <Typography color="inherit">
                            <FormattedMessage
                                id="logout"
                                defaultMessage="Logout"/>
                        </Typography>
                    </ListItemText>
                </MenuItem>

                <div className={classes.selectWrapper}>
                    <LanguageSelect afterChange={setUserLanguage.bind(this)}/>
                </div>
            </React.Fragment>
        )
    }

    pushToProfile(history) {
        history.push(`/user/profile/`);
    }

    pushToMembershipConditions(history) {
        history.push(`/terms/membership/`);
    }

    pushToManageMembership(history) {
        history.push(`/consumer/membership/`);
    }

    pushToPrivacyPolicy(history) {
        history.push(`/terms/privacy/`);
    }

    logout(history) {
        this.props.logout();
        history.push(`/login`);
    }

}

function mapStateToProps(state) {
    return {
        commercialChain: state.consumer.commercialChain,
        profile: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCommercialChain, logout, setUserLanguage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsumerAccount));
