import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    stampcardWrapper: {
        wordBreak: 'break-word',
        textAlign: 'left',
        "& > div": {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            minWidth: 320,
            maxWidth: 400,
            margin: '0 auto',
        },
    },
    couponDescription: {
        marginTop: theme.spacing(2),
        wordBreak: 'break-word'
    },
    mediaWrapper: {
        textAlign: 'center',
        position: 'relative'
    },
    mediaContent: {
        color: theme.palette.common.white,
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
});

class StampcardCouponPage extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        stampcardCoupon: PropTypes.object
    };

    UNSAFE_componentWillMount() {
        if (this.props.stampcardCoupon && this.props.stampcardCoupon.imagePath) {
            window.URL.revokeObjectURL(this.props.stampcardCoupon.imagePath)
        }
    }

    componentWillUnmount() {
        if (this.props.stampcardCoupon && this.props.stampcardCoupon.imagePath) {
            window.URL.revokeObjectURL(this.props.stampcardCoupon.imagePath)
        }
    }

    render() {
        const {classes, stampcardCoupon} = this.props;

        const timestamp = new Date().getTime();
        const bannerPath = stampcardCoupon.image && stampcardCoupon.image.newData ? window.URL.createObjectURL(stampcardCoupon.image.newData) : stampcardCoupon.imagePath + '?' + timestamp;

        return (
            <div className={classes.stampcardWrapper}>
                <Card>
                    <div className={classes.mediaWrapper}>
                        <div className={classes.mediaContent}>
                            <Typography variant="h5" color="inherit">
                                {!stampcardCoupon.hideTitle && stampcardCoupon.reward}
                            </Typography>
                        </div>
                        <img width="100%" src={bannerPath}/>
                    </div>

                    <CardContent>
                        <div className={classes.rewardTitle}>
                            <Typography variant="subtitle1">
                                {stampcardCoupon.reward}
                            </Typography>

                            <Typography variant="body1">
                                <FormattedMessage
                                    id="validToDate"
                                    defaultMessage="Valid to {date}"
                                    values={{
                                        date: '...'
                                    }}/>
                            </Typography>
                        </div>

                        <Typography
                            variant="body1"
                            className={classes.couponDescription}>
                            {stampcardCoupon.description}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        );
    }

}

export default withStyles(styles)(StampcardCouponPage)
