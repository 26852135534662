import React from "react";

import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme => ({
    root: {
        margin: theme.spacing(0.25),
        minWidth: '2.5em',
        verticalAlign: 'unset',
        "&:disabled": {
            color: 'inherit',
            borderRadius: 0,
            borderBottom: `2px solid ${theme.palette.primary.main}`
        }
    }
})));

function PageButton(props) {
    const classes = useStyles();
    return <Button className={classes.root} {...props}/>
}

export default PageButton
