import React from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {withStyles} from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';

import {renderKarmingLogo} from '../../../common/components/AppMenu';
import AppToolbar from "../../../common/components/common/menu/AppToolbar";

import {HOME_REDIRECT_URL} from "../../../../static/utils/redirect-const";
import {
    getPotentiallyCampaignIdFromPathname,
    getPotentiallySubpageFromPathname,
    isCampaignIdInPathname,
    isSubpageInPathname
} from "../../../common/utils/string-utils";

import {
    fetchCommercialChainWithoutAuthBySubdomain,
    fetchPointOfSaleWithoutAuthBySubpage
} from "../../actions/kundklubb-public-actions";

const styles = theme => ({
    consumerAppBar: {
        top: '0',
        height: 64,
        margin: '0',
        zIndex: theme.zIndex.appBar,
        flexWrap: 'wrap',
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
    },
    guestAppBar: {
        position: 'fixed',
        margin: '0',
        height: 64,
        zIndex: theme.zIndex.appBar,
        flexWrap: 'wrap',
        boxShadow: 'none'
    },
    logo: {
        height: 54,
        marginTop: 8,
        left: '50%',
        position: 'relative',
        textAlign: 'center',
        transform: 'translate(-50%, 0)'
    }
});

class GuestAppBar extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        auth: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object,
        commercialChain: PropTypes.object,
        pointOfSale: PropTypes.object,
        fetchCommercialChainWithoutAuthBySubdomain: PropTypes.func,
        fetchPointOfSaleWithoutAuthBySubpage: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchCommercialChainWithoutAuthBySubdomain();

        const potentiallyCampaignId = getPotentiallyCampaignIdFromPathname(this.props.location.pathname);

        // FIXME
        if (!isCampaignIdInPathname(potentiallyCampaignId)) {
            const potentiallySubpage = getPotentiallySubpageFromPathname(this.props.location.pathname);
            if (isSubpageInPathname(potentiallySubpage)) {
                this.props.fetchPointOfSaleWithoutAuthBySubpage(potentiallySubpage);
            }
        }
    }

    render() {
        const {classes, commercialChain, pointOfSale, location: {pathname}} = this.props;

        // FIXME
        const potentiallyCampaignId = getPotentiallyCampaignIdFromPathname(pathname);

        if (!commercialChain
            || (!isCampaignIdInPathname(potentiallyCampaignId)
                && isSubpageInPathname(getPotentiallySubpageFromPathname(pathname))
                && !pointOfSale)) {
            return null
        }
        return (
            <AppBar
                position="static"
                className={this.chainOrPOSLogoExists(commercialChain)
                    ? classes.consumerAppBar
                    : classes.guestAppBar}>
                <AppToolbar>
                    {this.chainOrPOSLogoExists(commercialChain, pointOfSale)
                        ? this.renderLogo(commercialChain, pointOfSale)
                        : renderKarmingLogo()}
                </AppToolbar>
            </AppBar>
        );
    }

    chainOrPOSLogoExists(commercialChain, pointOfSale) {
        return (commercialChain && commercialChain.logoPath) || (pointOfSale && pointOfSale.logoPath);
    }

    renderLogo(commercialChain, pointOfSale) {
        const {classes} = this.props;

        const pointOfSaleLogoPath = pointOfSale && pointOfSale.logoPath;
        return (
            <Link to={HOME_REDIRECT_URL}>
                <img
                    className={classes.logo}
                    src={pointOfSaleLogoPath
                        ? pointOfSale.logoPath
                        : commercialChain.logoPath}/>
            </Link>
        )
    }

}

function mapStateToProps(state) {
    return {
        commercialChain: state.company.commercialChain,
        pointOfSale: state.company.pointOfSale
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCommercialChainWithoutAuthBySubdomain,
        fetchPointOfSaleWithoutAuthBySubpage
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(GuestAppBar)));
