import {MEMBERSHIP_CONDITIONS, PRIVACY_POLICY} from "../actions/types";

export default function (state = {}, action) {

    switch (action.type) {
        case MEMBERSHIP_CONDITIONS:
            return {...state, membershipConditions: action.payload};
        case PRIVACY_POLICY:
            return {...state, privacyPolicy: action.payload};
    }

    return state;
}
