import React from "react";
import {connect} from "react-redux";
import {LANGUAGES} from "../../../utils/enums/languages";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {setLanguage} from "../../../actions/utils-actions";

class LanguageSelect extends React.Component {

    static propTypes = {
        language: PropTypes.string,
        setLanguage: PropTypes.func,
        afterChange: PropTypes.func
    };

    static defaultProps = {
        language: ""
    };

    handleSetLanguage(event) {
        const {setLanguage, afterChange} = this.props;
        setLanguage(event.target.value);
        if (afterChange) {
            afterChange(event.target.value)
        }
    }

    render() {
        const {language} = this.props;

        return (
            <Select
                value={language}
                options={LANGUAGES}
                onChange={this.handleSetLanguage.bind(this)}>

                {LANGUAGES.map((option, index) => (
                    <MenuItem key={index}
                              value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        )
    }

}

function mapStateToProps(state) {
    return {
        language: state.app.language,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setLanguage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);
