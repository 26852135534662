import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    list: {
        paddingLeft: 0,
        listStyleType: 'none',
        "& > :not(:last-child)": {
            marginBottom: theme.spacing(1),
        }
    }
});

const Terms = (props) => {

    const {classes, terms} = props;

    return (
        <React.Fragment>
            <Typography variant="h5">
                {terms.title}
            </Typography>
            <ul className={classes.list}>
                {terms.termsParagraphs.map(t =>
                    <li key={t.id || t.title}>
                        <Typography
                            variant="subtitle1"
                            paragraph
                            align="left">
                            {t.title}
                        </Typography>

                        <Typography
                            variant="body1"
                            align="justify"
                            component="div">
                            <div dangerouslySetInnerHTML={{__html: t.content && t.content.replace(/\n/g, '<br>')}}/>
                        </Typography>
                    </li>
                )}
            </ul>
        </React.Fragment>
    )

};

Terms.propTypes = {
    classes: PropTypes.object.isRequired,
    terms: PropTypes.object.isRequired
};

export default withStyles(styles)(Terms)
