export const primaryColor = '#09daaa';
export const primaryColorLightDark = '#99f0d2';
export const primaryColorLight = '#E1FBF2';
export const secondaryColor = '#115c6d';
export const colorRed = '#db3c30';
export const colorOrange = '#ffc107';
export const colorGreen = '#7cb33f';
export const colorBlack = '#000000';
export const colorWhite = '#ffffff';
export const colorGrey200 = '#EEEEEE';
export const colorGrey300 = '#e0e0e0';
export const colorGrey500 = '#9e9e9e';
export const colorDarkGrey = '#525252';
