import React from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import Button from "../../../common/components/common/Button";
import PhoneInput from "../../../common/components/common/form/PhoneInput";
import Checkbox from "../../../common/components/common/form/RenderCheckbox";
import RenderTextField from "../../../common/components/common/RenderTextField";
import Dialog from './../../../common/components/common/Dialog';
import ConsumerMembershipTerms from "../guest/terms/ConsumerMembershipTerms";
import ConsumerPrivacyPolicy from "../guest/terms/ConsumerPrivacyPolicy";

import {required, RequiredInfo} from "../../../common/components/common/form/FormUtils";
import {
    emailValidation,
    passwordValidation,
    phoneValidation,
    privacyPolicyValidation,
    termsOfMembershipValidation
} from "../../../common/utils/karming-validators";
import {COUNTRY_CODES, DEFAULT_COUNTRY_CODE} from "../../../common/utils/country-codes";

const validate = values => {
    return Object.assign(
        passwordValidation(
            {name: "password", value: values.password},
            {name: "confirmPassword", value: values.confirmPassword}
        ),
        emailValidation({name: "email", value: values.email}),
        phoneValidation({name: "phone", value: values.phone}),
        privacyPolicyValidation({name: "privacyPolicy", value: values.privacyPolicy}),
        termsOfMembershipValidation({name: "termsOfMembership", value: values.termsOfMembership}),
    )
};

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
    conditionText: {
        "& :not(:first-child)": {
            marginLeft: theme.spacing(1)
        },
        "& > :last-child": {
            display: 'inline'
        }
    }
});

class ConsumerOnboardingVerificationForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        profile: PropTypes.object,
        form: PropTypes.string,
        chain: PropTypes.object,
        handleSubmit: PropTypes.func
    };

    state = {
        membershipConditionOpen: false,
        privacyPolicyOpen: false,
    };

    handleMembershipConditionOpen = () => {
        this.setState({membershipConditionOpen: true});
    };

    handleMembershipConditionClose = () => {
        this.setState({membershipConditionOpen: false});
    };

    handlePrivacyPolicyOpen = () => {
        this.setState({privacyPolicyOpen: true});
    };

    handlePrivacyPolicyClose = () => {
        this.setState({privacyPolicyOpen: false});
    };

    render() {
        const {handleSubmit, profile, chain} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    paragraph
                    variant="h5">
                    <FormattedMessage
                        id="completeRegistration"
                        defaultMessage="Complete registration"/>
                </Typography>

                {this.renderWelcomeMessage()}

                <Field
                    name="password"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="password"
                                defaultMessage="Password"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    component={RenderTextField}
                    type="password"
                    validate={required}/>

                <Field
                    name="confirmPassword"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="confirmPassword"
                                defaultMessage="Confirm password"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    component={RenderTextField}
                    type="password"
                    validate={required}/>

                {this.renderOtherFields(profile)}

                {this.renderTermsCheckboxes(chain.id)}

                <Button
                    label={<FormattedMessage
                        id="confirmAndBecomeMember"
                        defaultMessage="Confirm and become member"/>}
                    type="submit"/>
                <RequiredInfo/>
            </form>
        );
    }

    renderOtherFields(profile) {
        const {classes} = this.props;

        return (
            <React.Fragment>
                {this.renderOptionalContactField(profile)}

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="firstName"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="firstName"
                            defaultMessage="First name"/>}/>
                    <Field
                        name="lastName"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="lastName"
                            defaultMessage="Last name"/>}/>
                </div>
            </React.Fragment>
        )
    }

    renderOptionalContactField(profile) {
        if (!profile.email) {
            return (
                <Field
                    name="email"
                    component={RenderTextField}
                    type="email"
                    hintText={<FormattedMessage
                        id="email"
                        defaultMessage="Email"/>}/>
            );
        } else if (!profile.phone) {
            return (
                <PhoneInput
                    defaultCountryCode={profile.language ? COUNTRY_CODES[profile.language].code : DEFAULT_COUNTRY_CODE}
                    form={this.props.form}/>
            )
        }
    }

    renderWelcomeMessage() {
        return (
            <FormattedMessage
                id="consumer-onboarding-verification-form.chooseYourPasswordText"
                defaultMessage="Choose your password and fill your data."/>
        )
    }

    renderTermsCheckboxes(chainId) {
        return (
            <React.Fragment>
                {this.getMembershipConditionsCheckbox(chainId)}
                {this.getPrivacyPolicyCheckbox(chainId)}
            </React.Fragment>
        )
    }

    getMembershipConditionsCheckbox(chainId) {
        return (
            <Field
                name="termsOfMembership"
                label={this.getMembershipConditionsLabel(chainId)}
                type="checkbox"
                component={Checkbox}/>
        )
    }

    getMembershipConditionsLabel() {
        const {classes, chain} = this.props;

        return (
            <React.Fragment>
                <Typography
                    className={classes.conditionText}
                    component="div">
                    <FormattedMessage
                        id="haveReadAndAgreeText"
                        defaultMessage="I have read and agree to the"/>

                    <Link onClick={this.handleMembershipConditionOpen}>
                        <FormattedMessage
                            id="termsOfTheMembership"
                            defaultMessage="Terms of the Membership"/>
                        &nbsp;*
                    </Link>
                </Typography>

                <Dialog
                    onClose={this.handleMembershipConditionClose}
                    title={<FormattedMessage
                        id="termsOfTheMembership"
                        defaultMessage="Terms of the Membership"/>}
                    actions={[
                        <Button
                            key="close"
                            label={<FormattedMessage
                                id="close"
                                defaultMessage="Close"/>}
                            onClick={this.handleMembershipConditionClose}/>
                    ]}
                    open={this.state.membershipConditionOpen}
                    content={<ConsumerMembershipTerms subdomain={chain.subdomain}/>}/>
            </React.Fragment>
        )
    }

    getPrivacyPolicyCheckbox(chainId) {
        return (
            <Field
                name="privacyPolicy"
                label={this.getPrivacyPolicyLabel(chainId)}
                type="checkbox"
                component={Checkbox}/>
        )
    }

    getPrivacyPolicyLabel() {
        const {classes, chain} = this.props;
        return (
            <React.Fragment>
                <Typography
                    className={classes.conditionText}
                    component="div">
                    <FormattedMessage
                        id="haveReadAndAgreeText"
                        defaultMessage="I have read and agree to the"/>

                    <Link onClick={this.handlePrivacyPolicyOpen}>
                        <FormattedMessage
                            id="privacyPolicy"
                            defaultMessage="Privacy Policy"/>
                        &nbsp;*
                    </Link>
                </Typography>

                <Dialog
                    onClose={this.handlePrivacyPolicyClose}
                    title={<FormattedMessage
                        id="privacyPolicy"
                        defaultMessage="Privacy Policy"/>}
                    actions={[
                        <Button
                            key="close"
                            label={<FormattedMessage
                                id="close"
                                defaultMessage="Close"/>}
                            onClick={this.handlePrivacyPolicyClose}/>
                    ]}
                    open={this.state.privacyPolicyOpen}
                    content={<ConsumerPrivacyPolicy subdomain={chain.subdomain}/>}/>
            </React.Fragment>
        )
    }
}

export default reduxForm({
    form: 'consumerOnboardingVerificationForm',
    validate,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withStyles(styles)(ConsumerOnboardingVerificationForm));
