import React, {useEffect, useRef} from "react";

import {makeStyles} from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme => ({
    root: {
        flexDirection: 'inherit',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    }
})));

const RenderSelect = props => {
    const {input, label, defaultValue, children, ...rest} = props;
    const classes = useStyles();
    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current && defaultValue) {
            input.onChange(defaultValue);
            mounted.current = true
        }
    });

    return (
        <FormControl className={classes.root}>
            <InputLabel>{label}</InputLabel>
            <Select
                {...rest}
                onChange={(event) => {
                    input.onChange(event.target.value)
                }}
                autoWidth
                value={input.value || input.value === false ? input.value : defaultValue}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    }
                }}>
                {children}
            </Select>
        </FormControl>
    )
};

export default RenderSelect
