import React from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {isAfter} from "date-fns";

import {withStyles} from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import {HistoryExpansionPanel} from "../../../merchant/components/purchase/history/HistoryExpansionPanel";
import PurchasePointsDetails from "./PurchasePointsDetails";

import {CONSUMER_PURCHASES_URL} from "../../../../static/utils/redirect-const";
import {SWEDISH} from "../../../common/utils/country-codes";
import {getTranslatedDate} from "../../../common/utils/date-utils";

import {fetchClubPointsWithoutAuthBySubdomain} from "../../actions/kundklubb-public-actions";
import {fetchPurchases} from "../../actions/consumer-actions";

const styles = theme => ({
    textBold: {
        fontWeight: 'bold',
        color: theme.palette.common.black
    },
    textSpaced: {
        marginLeft: theme.spacing(0.3),
        "& > span": {
            marginLeft: theme.spacing(0.3)
        }
    },
    card: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        textAlign: 'left'
    }
});

class ConsumerPurchaseHistory extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        purchases: PropTypes.array,
        language: PropTypes.string,
        clubPoints: PropTypes.object,
        history: PropTypes.object,
        fetchClubPointsWithoutAuthBySubdomain: PropTypes.func,
        fetchPurchases: PropTypes.func
    };

    componentDidMount() {
        const {fetchPurchases, fetchClubPointsWithoutAuthBySubdomain} = this.props;
        fetchPurchases();
        fetchClubPointsWithoutAuthBySubdomain()
    }

    render() {
        const {history, classes, purchases, clubPoints, language} = this.props;
        const dateLanguage = language || SWEDISH;

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <TitleWithBackButton
                        onBackClick={() => history.push(CONSUMER_PURCHASES_URL)}
                        title={
                            <Typography
                                variant="h5"
                                paragraph>
                                <FormattedMessage
                                    id="purchaseHistory"
                                    defaultMessage="Purchase history"/>
                            </Typography>
                        }/>
                    {purchases && purchases.map((purchase, purchaseIndex) => {
                        return (
                            <Card key={purchaseIndex} classes={{root: classes.card}}>
                                <Typography
                                    variant="body1"
                                    className={classes.textBold}>
                                    <FormattedMessage
                                        id="purchaseDate"
                                        defaultMessage="Purchase date"/>:
                                    <span className={classes.textSpaced}>
                                        {getTranslatedDate(purchase.dateOfPurchase, dateLanguage)}
                                    </span>
                                </Typography>
                                <Typography variant="body1">
                                    <span className={classes.textBold}>
                                        <FormattedMessage
                                            id="categories"
                                            defaultMessage="Categories"/>:
                                    </span>
                                    <span className={classes.textSpaced}>
                                        {purchase.categories
                                        && purchase.categories.length > 0
                                        && purchase.categories.map((category, categoryIndex) => {
                                            return (
                                                <span key={categoryIndex}>{category.name}</span>
                                            )
                                        }).reduce((prev, curr) => [prev, ', ', curr])}
                                    </span>
                                </Typography>
                                <Typography variant="body1">
                                    <span className={classes.textBold}>
                                        <FormattedMessage
                                            id="campaigns"
                                            defaultMessage="Campaigns"/>:
                                    </span>
                                    <span className={classes.textSpaced}>
                                        {purchase.campaigns
                                        && purchase.campaigns.length > 0
                                        && purchase.campaigns.map((campaign, campaignIndex) => {
                                            return (
                                                <span key={campaignIndex}>{campaign.name}</span>
                                            )
                                        }).reduce((prev, curr) => [prev, ', ', curr])}
                                    </span>
                                </Typography>
                                <Typography variant="body1">
                                    <span className={classes.textBold}>
                                        <FormattedMessage
                                            id="totalAmountPaidPurchaseHistory"
                                            defaultMessage="Total paid amount"/>:
                                    </span>
                                    <span className={classes.textSpaced}>
                                        {purchase.totalAmount || 0}
                                    </span>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className={classes.textBold}>
                                    <FormattedMessage
                                        id="redeemedPoints"
                                        defaultMessage="Redeemed points"/>:
                                    <span className={classes.textSpaced}>
                                        -{purchase.tradedPoints || 0}p
                                    </span>
                                </Typography>
                                {purchase.coupons
                                && purchase.coupons.length > 0
                                && purchase.coupons.map((coupon, couponIndex) => {
                                    return (
                                        <Typography
                                            key={couponIndex}
                                            variant="body1">
                                            -{coupon.pointsRequired} <FormattedMessage
                                            id="couponPoints"
                                            defaultMessage="points"/>
                                            , &quot;{coupon.name}&quot; (id: {coupon.id})
                                        </Typography>
                                    )
                                })}
                                {clubPoints
                                && isAfter(purchase.dateOfPurchase, clubPoints.countedPointsFrom)
                                && renderEarnedPoints(purchase, dateLanguage)}
                            </Card>
                        )
                    }).reverse()}
                </Paper>
            </Container>
        );

        function renderEarnedPoints(purchase, language) {
            const earnedPoints = (
                <Typography
                    variant="body1"
                    component="span"
                    className={classes.textBold}>
                    <FormattedMessage
                        id="earnedPoints"
                        defaultMessage="Earned points"/>:
                    <span className={classes.textSpaced}> +{purchase.earnedPoints || 0}p</span>
                </Typography>
            );

            if (purchase.earnedPoints > 0) {
                return (
                    <HistoryExpansionPanel
                        small
                        content={
                            <PurchasePointsDetails
                                purchase={purchase}
                                monthsValidation={clubPoints && clubPoints.monthValidation}
                                language={language}/>
                        }
                        title={earnedPoints}/>
                )
            }
            return earnedPoints
        }
    }

}

function mapStateToProps(state) {
    return {
        purchases: state.consumer.purchases,
        clubPoints: state.company.clubPoints
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPurchases,
        fetchClubPointsWithoutAuthBySubdomain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsumerPurchaseHistory));
