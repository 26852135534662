import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import PersonAdd from '@material-ui/icons/PersonAdd';
import CalendarToday from '@material-ui/icons/CalendarToday';
import withStyles from "@material-ui/core/styles/withStyles";

import Spinner from "../Spinner";
import {getFormattedDate} from "../../../utils/date-utils";
import Button from "../Button";
import {addHttpIfNeeded} from "../../../../merchant/utils/url-utils";
import Container from "../Container";
import {ROLE_MERCHANT} from "../../auth/roles";

const styles = theme => ({
    cardContent: {
        textAlign: 'left'
    },
    timeFrameWrapper: {
        display: 'flex',
        "& > :not(:first-child)": {
            marginLeft: theme.spacing(0.5)
        },
        "& > :nth-child(even)": {
            fontWeight: 'bold'
        }
    },
    buttonsWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    icon: {
        fontSize: 18
    }
});

class CampaignPage extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        campaign: PropTypes.object,
        auth: PropTypes.object,
        sendout: PropTypes.object,
        history: PropTypes.object,
        singlePage: PropTypes.bool
    };

    UNSAFE_componentWillMount() {
        if (this.props.campaign.bannerPath) {
            window.URL.revokeObjectURL(this.props.campaign.bannerPath)
        }
    }

    componentWillUnmount() {
        if (this.props.campaign.bannerPath) {
            window.URL.revokeObjectURL(this.props.campaign.bannerPath)
        }
    }

    render() {
        const {classes, app, campaign, auth, singlePage, sendout, history} = this.props;

        if (!campaign || !campaign.name) {
            return <Spinner app={app}/>
        }

        const actionButtonLabel = campaign.actionButtonLabel;
        const actionButtonTarget = campaign.actionButtonTarget;

        const timestamp = new Date().getTime();
        const bannerPath = campaign.banner && campaign.banner.newData
            ? window.URL.createObjectURL(campaign.banner.newData)
            : campaign.bannerPath + '?' + timestamp;

        return (
            <Container size={Container.SIZE_TINY}>
                <Card>
                    <img width="100%" src={bannerPath}/>

                    <CardContent className={classes.cardContent}>
                        <Typography variant="subtitle1">
                            {campaign.name}
                        </Typography>

                        <Typography variant="body1">
                            {campaign.description}
                        </Typography>

                        {!sendout && actionButtonTarget && (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={addHttpIfNeeded(actionButtonTarget)}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    label={actionButtonLabel}/>
                            </a>
                        )}

                        {auth && auth.role && auth.role === ROLE_MERCHANT &&
                        !campaign.welcomeOffer && campaign.startDate && campaign.endDate && (
                            <React.Fragment>
                                <Typography variant="subtitle1">
                                    <CalendarToday className={classes.icon}/>
                                    &nbsp;
                                    <FormattedMessage
                                        id="whenVisible"
                                        defaultMessage="When visible"/>
                                    ?
                                </Typography>

                                <div className={classes.timeFrameWrapper}>
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="from"
                                            defaultMessage="From"/>
                                    </Typography>

                                    <Typography variant="body1">
                                        {getFormattedDate(campaign.startDate)}
                                    </Typography>

                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="till"
                                            defaultMessage="till"/>
                                    </Typography>

                                    <Typography variant="body1">
                                        {getFormattedDate(campaign.endDate)}
                                    </Typography>
                                </div>
                            </React.Fragment>
                        )}
                    </CardContent>
                </Card>

                {(singlePage && (!auth || !auth.authenticated)) && (
                    <div className={classes.buttonsWrapper}>
                        <Button
                            label={<FormattedMessage
                                id="login"
                                defaultMessage="Login"/>}
                            onClick={this.moveToLogin.bind(this)}/>
                        <Button
                            icon={<PersonAdd/>}
                            label={<FormattedMessage
                                id="register"
                                defaultMessage="Register"/>
                            }
                            color="secondary"
                            onClick={() => history.push("/onboarding")}/>
                    </div>
                )}
            </Container>
        )
    }

    moveToLogin() {
        this.props.history.push("/login");
    }

}

export default withStyles(styles)(CampaignPage)
