import {
    API_ERROR,
    API_SUCCESS,
    LOADING,
    SET_ACCOUNT_MENU_VISIBILITY,
    SET_LANGUAGE,
    SET_SIDEBAR_VISIBILITY,
    SET_START_GUIDE_VISIBILITY
} from "../actions/types";
import {checkIfIsPhone} from "../components/common/PhoneUtils";

const defaultState = {
    sidebarOpen: !checkIfIsPhone(),
    startGuideOpen: false,
    accountMenuOpen: false,
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                status: {
                    loading: true,
                    isError: false,
                    message: null,
                },
            };
        case API_ERROR:
            return {
                ...state,
                status: {
                    loading: false,
                    isError: true,
                    message: action.payload.message,
                },
            };
        case API_SUCCESS:
            return {
                ...state,
                status: {
                    requestId: action.actionId,
                    success: true,
                    loading: false,
                    isError: false,
                    message: action.payload.message,
                },
            };
        case SET_SIDEBAR_VISIBILITY:
            return {...state, sidebarOpen: action.payload};
        case SET_START_GUIDE_VISIBILITY:
            return {...state, startGuideOpen: action.payload};
        case SET_ACCOUNT_MENU_VISIBILITY:
            return {...state, accountMenuOpen: action.payload};
        case SET_LANGUAGE:
            return {...state, language: action.payload};
    }

    return {
        ...state,
        status: {
            loading: false,
            isError: false,
        }
    };
}
