import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Spinner from "../../../../common/components/common/Spinner";
import Terms from '../../../../common/components/common/Terms'
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {fetchPrivacyPolicy} from "../../../actions/kundklubb-public-actions";

class ConsumerPrivacyPolicy extends React.Component {

    static propTypes = {
        terms: PropTypes.object,
        app: PropTypes.object,
        fetchPrivacyPolicy: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchPrivacyPolicy();
    }

    render() {
        const {terms, app} = this.props;

        if (!terms) {
            return <Spinner app={app}/>
        }
        return (
            <Container>
                <Paper padding>
                    <Terms terms={terms}/>
                </Paper>
            </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        terms: state.company.policy,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchPrivacyPolicy}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerPrivacyPolicy);

