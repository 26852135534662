import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {isAfter} from "date-fns";

import {withStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import ImageCard from "../ImageCard";

import {addDaysToDate, endOfDayForDate, getFormattedDate} from "../../../utils/date-utils";

const styles = theme => ({
    stampcardCouponWrapper: {
        textAlign: 'center',
        "& > div": {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            paddingLft: '0 !important'
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            minWidth: 320,
            maxWidth: 400
        }
    },
    mediaWrapper: {
        position: 'relative'
    },
    mediaContent: {
        color: theme.palette.common.white,
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
});

class StampcardCouponBannersList extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chain: PropTypes.object,
        campaigns: PropTypes.array,
        stampcardCoupons: PropTypes.array,
        stampcardCouponUsage: PropTypes.array
    };

    renderStampcardCouponsBanners(stampcardCoupons) {
        const {classes, stampcardCouponUsage} = this.props;
        const timestamp = new Date().getTime();

        return (
            <div className={classes.stampcardCouponWrapper}>
                {stampcardCouponUsage.map((usage, index) => {
                        const stampcardCoupon = stampcardCoupons
                            .find(option => option.id === usage.stampcardCouponId);
                        if (!usage.used
                            && isAfter(addDaysToDate(usage.startDate, stampcardCoupon.dayValidation),
                                endOfDayForDate(new Date()))) {
                            return (
                                <div key={index} className={classes.stampcardCouponBanner}>
                                    <ImageCard
                                        mediaContent={
                                            <div className={classes.mediaWrapper}>
                                                <div className={classes.mediaContent}>
                                                    <Typography
                                                        variant="h5"
                                                        color="inherit"
                                                        component="div">
                                                        {!stampcardCoupon.hideTitle && stampcardCoupon.reward}
                                                    </Typography>
                                                </div>
                                                <CardMedia
                                                    component="img"
                                                    image={stampcardCoupon.imagePath + '?' + timestamp}/>
                                            </div>
                                        }
                                        bottomTitleText={stampcardCoupon.reward}
                                        bottomSubtitleText={
                                            <FormattedMessage
                                                id="validToDate"
                                                defaultMessage="Valid to {date}"
                                                values={{
                                                    date: getFormattedDate(
                                                        addDaysToDate(usage.startDate, stampcardCoupon.dayValidation))
                                                }}/>
                                        }
                                        expandedText={stampcardCoupon.description}/>
                                </div>
                            )
                        }
                    }
                )}
            </div>
        )
    }

    render() {
        const {stampcardCoupons} = this.props;
        if (stampcardCoupons) {
            return (
                this.renderStampcardCouponsBanners(stampcardCoupons)
            )
        }
        return null
    }

}

export default withStyles(styles)(StampcardCouponBannersList)
