import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Home from "@material-ui/icons/Home";
import Spinner from "../../common/components/common/Spinner";
import Container from "../../common/components/common/Container";
import Button from "../../common/components/common/Button";
import Paper from "../../common/components/common/Paper";

import {addHttpIfNeeded} from "../../merchant/utils/url-utils";
import {fetchHomeLocation, fetchPointsOfSale, setPOSAsHomeLocation} from "../actions/consumer-actions";
import {fetchUserDetails} from "../../common/actions/user-actions";
import {daysOfWeek} from "../../common/utils/enums/days";
import FormattedMessageFixed from "../../common/utils/FormattedMessageFixed";

const styles = theme => ({
    list: {
        paddingLeft: 0,
        margin: 0,
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(2)
        }
    },
    listItem: {
        listStyleType: 'none',
    },
    homeLocationWrapper: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.primary.main}`
    },
    locationWrapper: {
        padding: theme.spacing(2),
    },
    homeTitle: {
        display: 'inline-flex',
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
    openingHoursWrapper: {
        marginTop: theme.spacing(1)
    },
    openingHours: {
        display: 'flex',
        margin: '0 auto',
        width: 300,
        "& :first-child": {
            width: '50%',
            textAlign: 'center',
        },
    }
});

class ConsumerCommercialChain extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        user: PropTypes.object,
        commercialChain: PropTypes.object,
        homeLocation: PropTypes.object,
        pointsOfSale: PropTypes.array,
        fetchUserDetails: PropTypes.func,
        fetchPointsOfSale: PropTypes.func,
        fetchHomeLocation: PropTypes.func,
        setPOSAsHomeLocation: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchUserDetails();
        this.props.fetchPointsOfSale();
        this.props.fetchHomeLocation();
    }

    render() {
        const {app, user, pointsOfSale, homeLocation} = this.props;

        if (!user || !pointsOfSale || !homeLocation) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography
                        paragraph
                        variant="h5">
                        <FormattedMessage
                            id="contact"
                            defaultMessage="Contact"/>
                    </Typography>

                    {this.renderPointsOfSale(pointsOfSale, homeLocation.name === 'Non Existing'
                        ? null
                        : homeLocation.id)}
                </Paper>
            </Container>
        );

    }

    renderPointsOfSale(pointsOfSale, homeLocationId) {
        const {classes} = this.props;

        if (pointsOfSale) {
            const isMoreThanOneLocationInChain = pointsOfSale.length > 1;

            return (
                <ul className={classes.list}>
                    {pointsOfSale
                        .sort((a) => {
                            return a.id === homeLocationId ? -1 : 1
                        })
                        .filter(e => e.id === homeLocationId)
                        .concat(pointsOfSale
                            .filter(e => e.id !== homeLocationId)
                            .sort((a, b) => a.address.city.localeCompare(b.address.city)))
                        .map((pointOfSale) => (
                            <li key={pointOfSale.id} className={classes.listItem}>
                                <Card
                                    className={isMoreThanOneLocationInChain && homeLocationId === pointOfSale.id ? classes.homeLocationWrapper : classes.locationWrapper}
                                    elevation={1}>
                                    {isMoreThanOneLocationInChain && homeLocationId === pointOfSale.id && (
                                        <Typography
                                            paragraph
                                            variant="subtitle1"
                                            component="div"
                                            className={classes.homeTitle}>
                                            <Home/>
                                            <FormattedMessage
                                                id="myHomeLocation"
                                                defaultMessage="My Home location"/>
                                        </Typography>
                                    )}

                                    <Typography variant="subtitle1">
                                        {pointOfSale.commercialName}
                                    </Typography>

                                    {pointOfSale.address.street && (
                                        <Typography variant="body1">
                                            {pointOfSale.address.street}
                                        </Typography>
                                    )}

                                    {(pointOfSale.address.zipCode || pointOfSale.address.city) && (
                                        <Typography variant="body1">
                                            {pointOfSale.address.zipCode} {pointOfSale.address.city}
                                        </Typography>
                                    )}

                                    {pointOfSale.phone && (
                                        <Typography variant="body1">
                                            {pointOfSale.phone}
                                        </Typography>
                                    )}

                                    {pointOfSale.email && (
                                        <Typography
                                            variant="body1">
                                            {pointOfSale.email}
                                        </Typography>
                                    )}

                                    {pointOfSale.website && (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={addHttpIfNeeded(pointOfSale.website)}>
                                            {pointOfSale.website}
                                        </a>
                                    )}

                                    {this.renderOpeningHours(pointOfSale)}

                                    {homeLocationId !== pointOfSale.id && (
                                        <Button
                                            label={<FormattedMessage
                                                id="setAsHomeLocation"
                                                defaultMessage="Set as home location"/>}
                                            onClick={this.props.setPOSAsHomeLocation.bind(this, pointOfSale.id)}/>
                                    )}
                                </Card>
                            </li>
                        ))}
                </ul>
            )
        }
    }

    renderOpeningHours(pointOfSale) {
        const {classes} = this.props;

        if (pointOfSale.openingHours && pointOfSale.openingHours.length > 0) {
            return (
                <div className={classes.openingHoursWrapper}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="openingHours"
                            defaultMessage="Opening hours"/>:
                    </Typography>

                    {pointOfSale.openingHours.map((c) => (
                        <div key={c.id} className={classes.openingHours}>
                            <Typography variant="body1">
                                {this.getDayName(c.dayFrom)}
                                {c.dayTo !== undefined && c.dayTo !== null ? " - " : ""}
                                {this.getDayName(c.dayTo)}
                            </Typography>
                            <Typography variant="body1">
                                {c.timeFrom} - {c.timeTo}
                            </Typography>
                        </div>
                    ))}
                </div>
            )
        }
    }

    getDayName(dayOrderNumber) {
        const day = daysOfWeek.find(d => d.value === dayOrderNumber);
        return day ? <FormattedMessageFixed id={day.id} defaultMessage={day.message}/> : '';
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.profile,
        pointsOfSale: state.consumer.pointsOfSale,
        homeLocation: state.consumer.homeLocation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchUserDetails,
        fetchPointsOfSale,
        fetchHomeLocation,
        setPOSAsHomeLocation
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsumerCommercialChain));
