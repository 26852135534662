import React from "react";
import {FormattedMessage} from "react-intl";

export const NEW = {value: 'NEW', message: <FormattedMessage id='publish-status.new' defaultMessage="New"/>};
export const STOPPED = {
    value: 'STOPPED',
    message: <FormattedMessage id='publish-status.stopped' defaultMessage="Stopped"/>
};
export const COMPLETED = {
    value: 'COMPLETED',
    message: <FormattedMessage id='publish-status.completed' defaultMessage="Completed"/>
};
export const SUSPENDED = {
    value: 'SUSPENDED',
    message: <FormattedMessage id='publish-status.suspended' defaultMessage="Suspended"/>
};
export const ACTIVE = {
    value: 'ACTIVE',
    message: <FormattedMessage id='publish-status.active' defaultMessage="Active"/>
};
export const EXPIRED = {
    value: 'EXPIRED',
    message: <FormattedMessage id='publish-status.expired' defaultMessage="Expired"/>
};
export const WITHDRAWN = {
    value: 'WITHDRAWN',
    message: <FormattedMessage id='publish-status.withdrawn' defaultMessage="Withdrawn"/>
};

export const PUBLISH_STATUSES = [NEW, STOPPED, COMPLETED, SUSPENDED, ACTIVE, EXPIRED, WITHDRAWN];
export const DISABLED_PUBLISH_STATUSES = [COMPLETED, EXPIRED, WITHDRAWN];
