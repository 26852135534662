import React from "react";
import {render} from "react-dom";
import {applyMiddleware, createStore} from "redux";
import reduxThunk from "redux-thunk";
import {Provider} from "react-redux";
import {composeWithDevTools} from 'redux-devtools-extension';
import {CookiesProvider} from "react-cookie";

import {ThemeProvider} from '@material-ui/styles';

import KundKlubbApp from "./components/KundKlubbApp";
import {appTheme} from "../common/utils/themes";

import {checkIfIsPhone} from "../common/components/common/PhoneUtils";

import reducers from "../common/reducers";
import {AUTH_USER, SET_SIDEBAR_VISIBILITY, UNAUTH_USER} from "../common/actions/types";
import LanguageProvider from "../common/components/common/language/LanguageProvider";

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

/* eslint-disable no-undef */
const store = (process.env.NODE_PROFILE === 'local')
    ? createStoreWithMiddleware(reducers, composeWithDevTools())
    : createStoreWithMiddleware(reducers);
/* eslint-enable no-undef */

const token = localStorage.getItem('token');
const role = localStorage.getItem('role');
// If we have a token, consider the user to be signed in
if (token) {
    // we need to update application state
    store.dispatch({
        type: AUTH_USER,
        payload: role
    });
} else {
    store.dispatch({type: UNAUTH_USER});
}

if (checkIfIsPhone()) {
    store.dispatch({
        type: SET_SIDEBAR_VISIBILITY,
        payload: false
    });
}

render(
    <CookiesProvider>
        <ThemeProvider theme={appTheme}>
            <Provider store={store}>
                <LanguageProvider>
                    <KundKlubbApp/>
                </LanguageProvider>
            </Provider>
        </ThemeProvider>
    </CookiesProvider>
    , document.querySelector('.container-app')
);

