import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import {fetchUserByConfirmationId} from "../../../common/actions/onboarding-actions";
import Spinner from "../../../common/components/common/Spinner";
import ConsumerOnboardingVerificationForm from "./ConsumerOnboardingVerificationForm";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {fetchCommercialChainWithoutAuthBySubdomain} from "../../actions/kundklubb-public-actions";
import {onboardingVerificationMember} from "../../actions/kundklubb-onboarding-actions";

class ConsumerOnboardingVerification extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        app: PropTypes.object,
        user: PropTypes.object,
        profile: PropTypes.object,
        chain: PropTypes.object,
        auth: PropTypes.object,
        match: PropTypes.object,
        fetchUserByConfirmationId: PropTypes.func,
        fetchCommercialChainWithoutAuthBySubdomain: PropTypes.func,
        onboardingVerificationMember: PropTypes.func
    };

    componentDidMount() {
        const {auth, history, match: {params: {id}}, fetchUserByConfirmationId, fetchCommercialChainWithoutAuthBySubdomain} = this.props;
        if (auth && auth.authenticated && history) {
            history.push(`/`);
        }

        fetchUserByConfirmationId(id);
        fetchCommercialChainWithoutAuthBySubdomain();
    }

    render() {
        const {profile, chain, history, app} = this.props;

        if (app && app.status.isError) {
            history.push("/guest");
            return null;
        }

        if (!chain || !profile) {
            return <Spinner app={app}/>
        }

        const loginData = profile.email ? profile.email : profile.phone;

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <ConsumerOnboardingVerificationForm
                        onSubmit={this.createAndRedirectToLogin.bind(this, loginData, history)}
                        profile={profile}
                        chain={chain}/>
                </Paper>
            </Container>
        );
    }

    createAndRedirectToLogin(contactData, history, values) {
        const {onboardingVerificationMember, match: {params: {id}}} = this.props;
        onboardingVerificationMember(id, {
                ...values,
                'username': contactData
            }, history,
            `/consumer/complete`);
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        app: state.app,
        profile: state.user.profile,
        chain: state.company.commercialChain
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onboardingVerificationMember,
        fetchUserByConfirmationId,
        fetchCommercialChainWithoutAuthBySubdomain,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerOnboardingVerification);
