import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../common/components/common/Spinner";
import CampaignsBannersList from "../../common/components/common/campaign/CampaignsBannersList";
import CouponBannerList from "../../common/components/common/coupon/CouponBannerList";
import StampcardBannersList from "../../common/components/common/stampcard/StampcardBannersList";
import StampcardCouponBannersList from "../../common/components/common/stampcard/StampcardCouponBannersList";
import CenterContainer from "../../common/components/common/CenterContainer";
import AddToHomeScreenPopup from "../../common/components/common/pwa/AddToHomeScreenPopup";

import {ENGLISH, FRENCH, SWEDISH} from "../../common/utils/enums/languages";

import {fetchClubPointsWithoutAuthBySubdomain} from "../actions/kundklubb-public-actions";
import {
    fetchCampaigns,
    fetchCommercialChain,
    fetchCoupons,
    fetchPurchases,
    fetchStampcardCoupons,
    fetchStampcards
} from "../actions/consumer-actions";

class ConsumerHome extends React.Component {

    static propTypes = {
        user: PropTypes.object,
        app: PropTypes.object,
        commercialChain: PropTypes.object,
        coupons: PropTypes.array,
        campaigns: PropTypes.array,
        stampcardCoupons: PropTypes.array,
        purchases: PropTypes.array,
        stampcards: PropTypes.array,
        clubPointsActive: PropTypes.bool,
        clubPoints: PropTypes.object,
        fetchCampaigns: PropTypes.func,
        fetchPurchases: PropTypes.func,
        fetchCommercialChain: PropTypes.func,
        fetchClubPointsWithoutAuthBySubdomain: PropTypes.func,
        fetchStampcards: PropTypes.func,
        fetchStampcardCoupons: PropTypes.func,
        fetchCoupons: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchClubPointsWithoutAuthBySubdomain();
        this.props.fetchCommercialChain();
        this.props.fetchPurchases();
        this.props.fetchCampaigns();
        this.props.fetchStampcards();
        this.props.fetchStampcardCoupons();
    }

    componentDidUpdate(prevProps) {
        const {commercialChain, coupons} = this.props;
        if (commercialChain && commercialChain.clubPointActive && !coupons) {
            this.props.fetchCoupons(true);
        }
    }

    render() {
        const {
            app, campaigns, commercialChain, coupons, stampcards, stampcardCoupons, clubPointsActive,
            user, purchases, clubPoints
        } = this.props;
        const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);

        if (!campaigns
            || !purchases
            || !commercialChain
            || !user
            || !user.profile
            || !stampcardCoupons
            || !stampcards
            || (clubPointsActive && !coupons)) {
            return <Spinner app={app}/>
        }

        const language = user.profile.language === FRENCH && ENGLISH || SWEDISH;

        return (
            <React.Fragment>
                <CenterContainer>
                    {coupons && coupons.length > 0 && (
                        <CouponBannerList
                            coupons={coupons}
                            purchases={purchases}
                            chain={commercialChain}
                            clubPoints={clubPoints}
                            language={language}
                            consumerPoints={user.profile.clubPoints}/>
                    )}

                    {stampcardCoupons.length > 0 && (
                        <StampcardCouponBannersList
                            stampcardCouponUsage={user.profile.stampcardCouponUsage}
                            stampcardCoupons={stampcardCoupons}
                            chain={commercialChain}/>
                    )}

                    {stampcards.length > 0 && (
                        <StampcardBannersList
                            stampcardUsage={user.profile.stampcardUsage}
                            stampcards={stampcards}/>
                    )}

                    {campaigns.length > 0 && (
                        <CampaignsBannersList
                            campaigns={campaigns}
                            chain={commercialChain}/>
                    )}
                </CenterContainer>

                {!isSamsungBrowser && <AddToHomeScreenPopup language={language}/>}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        campaigns: state.consumer.campaigns,
        commercialChain: state.consumer.commercialChain,
        coupons: state.consumer.coupons,
        stampcards: state.consumer.stampcards,
        stampcardCoupons: state.consumer.stampcardCoupons,
        clubPoints: state.company.clubPoints,
        purchases: state.consumer.purchases
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCampaigns,
        fetchCommercialChain,
        fetchCoupons,
        fetchPurchases,
        fetchStampcards,
        fetchClubPointsWithoutAuthBySubdomain,
        fetchStampcardCoupons
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerHome);
