import React from "react"
import PropTypes from "prop-types";
import classnames from "classnames";

import {withStyles} from "@material-ui/core/styles";

import Box from "./Box";
import {connect} from "react-redux";

const NUMBER_OF_OPTIONS_IN_ROW = 3;
const NUMBER_OF_OPTIONS_IN_ROW_FOR_CAMPAIGN_FILTER = 2;

const styles = theme => ({
    wrapper: {
        display: 'grid',
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    wrapperCentered: {
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center'
        }
    },
    wrapperLeftAligned: {
        textAlign: 'left'
    },
    row: {
        display: 'inline-flex',
        marginBottom: theme.spacing(1),
        "& > :not(:first-child)": {
            marginLeft: theme.spacing(1)
        }
    }
});

class ImageBoxRow extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        remainingPoints: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        initiallySelectedOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        input: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        options: PropTypes.array,
        squareShape: PropTypes.bool,
        stampcardCoupon: PropTypes.bool,
        filter: PropTypes.bool,
        stampcard: PropTypes.bool,
        editable: PropTypes.bool,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        readOnly: PropTypes.bool,
        campaignFilter: PropTypes.bool,
        coupon: PropTypes.bool,
        onFilterClick: PropTypes.func,
        subtractTradedPoints: PropTypes.func,
        addTradedPoints: PropTypes.func,
        form: PropTypes.object,
        purchaseStampcardUsage: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    };

    static defaultProps = {
        input: []
    };

    componentDidMount() {
        const {initiallySelectedOptions, stampcard, input, purchaseStampcardUsage} = this.props;
        if (initiallySelectedOptions) {
            input.value = initiallySelectedOptions
        } else if (stampcard && purchaseStampcardUsage && purchaseStampcardUsage.length > 0) {
            this.updateAddedStampsValue(purchaseStampcardUsage, input);
        }
    }

    componentDidUpdate(prevProps) {
        const {options, stampcard, purchaseStampcardUsage, input} = this.props;

        if (stampcard && purchaseStampcardUsage && purchaseStampcardUsage.length > 0 &&
            (prevProps.purchaseStampcardUsage !== purchaseStampcardUsage)) {
            this.updateAddedStampsValue(purchaseStampcardUsage, input);
        }

        if (prevProps.options && prevProps.options !== options) {
            this.forceUpdate()
        }
    }

    updateAddedStampsValue(purchaseStampcardUsage, input) {
        const purchaseStampcardUsages = [];
        purchaseStampcardUsage.map(option =>
            purchaseStampcardUsages.push(
                {
                    id: option.id,
                    stampcardId: option.stampcardId,
                    addedStamps: option.addedStamps
                })
        );
        input.value = purchaseStampcardUsages;
    }

    render() {
        const {
            options, stampcard, initiallySelectedOptions, stampcardCoupon, squareShape, editable, coupon, history,
            remainingPoints, filter, onFilterClick, readOnly, campaignFilter, classes
        } = this.props;

        if (options) {
            let rows = [];
            for (let i = 0; i < options.length; i += (campaignFilter ? NUMBER_OF_OPTIONS_IN_ROW_FOR_CAMPAIGN_FILTER : NUMBER_OF_OPTIONS_IN_ROW)) {
                const slicedOptions = options.slice(i, i + (campaignFilter ? NUMBER_OF_OPTIONS_IN_ROW_FOR_CAMPAIGN_FILTER : NUMBER_OF_OPTIONS_IN_ROW));
                rows = [...rows, slicedOptions]
            }
            return (
                <div className={classnames(
                    classes.wrapper,
                    readOnly || campaignFilter || filter
                        ? classes.wrapperCentered
                        : classes.wrapperLeftAligned)}>
                    {rows.map((optionRow, rowIndex) =>
                        <div
                            key={rowIndex}
                            style={{height: squareShape && 108}}
                            className={classes.row}>
                            {optionRow.map((option, optionIndex) =>
                                <Box
                                    key={optionIndex}
                                    onClickOnBox={this.addToSelectedOptions.bind(this)}
                                    onAddStamp={this.addStamp.bind(this)}
                                    onRemoveStamp={this.removeStamp.bind(this)}
                                    remainingPoints={remainingPoints}
                                    value={!option.disabled && !option.afterPurchase && option.value}
                                    stampcard={stampcard}
                                    stampcardCoupon={stampcardCoupon}
                                    content={option}
                                    squareShape={squareShape}
                                    initiallySelectedOptions={initiallySelectedOptions}
                                    editable={editable}
                                    coupon={coupon}
                                    filter={filter}
                                    onFilterClick={onFilterClick}
                                    history={history}
                                    campaignFilter={campaignFilter}
                                    readOnly={readOnly || stampcard}/>)}
                        </div>
                    )}
                </div>
            )

        }
        return null
    }

    addStamp(stampcardId, collectedStampsAfterAdd) {
        const {input} = this.props;

        if (input.value && input.value.length > 0) {
            let filteredArray = input.value.filter(stampcard => stampcard.stampcardId === stampcardId);
            if (filteredArray.length > 0) {
                const idInArray = filteredArray.reduce((a, b) => a.id > b.id ? a : b);
                idInArray.addedStamps = collectedStampsAfterAdd;
            } else {
                input.value = [...input.value, {
                    stampcardId: stampcardId,
                    addedStamps: collectedStampsAfterAdd
                }];
            }
        } else {
            input.value = [{stampcardId: stampcardId, addedStamps: collectedStampsAfterAdd}]
        }

        this.addUsageOrderToStampcards();
        input.onChange(input.value)
    }

    removeStamp(stampcard, collectedStampsAfterRemove) {
        const {input} = this.props;
        if (input.value && input.value.length > 0) {
            let filteredArray = input.value.filter(stampcard_ => stampcard_.stampcardId === stampcard.value);
            if (filteredArray.length > 0) {
                const idInArray = filteredArray.reduce((a, b) => a.id > b.id ? a : b);
                idInArray.addedStamps = collectedStampsAfterRemove;
            } else {
                input.value = [...input.value, {
                    stampcardId: stampcard.value,
                    addedStamps: collectedStampsAfterRemove
                }];
            }
        }
        this.addUsageOrderToStampcards();
        input.onChange(input.value)
    }

    addUsageOrderToStampcards() {
        const {input, isUpdate, form} = this.props;
        if (isUpdate) {
            let stampcardsID = input.value.map(option => option.stampcardId).filter((v, i, a) => a.indexOf(v) === i);

            stampcardsID.map(stampcardId => {
                let filterStampcardUsage = form.updatePurchaseForm.values.consumer.stampcardUsage.filter(stampcard => stampcard.stampcardId === stampcardId)[0];
                let usageOrderBasic = 0;

                if (filterStampcardUsage && filterStampcardUsage.historicStampcardUsages) {
                    usageOrderBasic = filterStampcardUsage.historicStampcardUsages.length
                }

                input.value.filter(stampcard => stampcard.stampcardId === stampcardId)
                    .map(stampcard => {
                        stampcard.usageOrder = usageOrderBasic;
                        usageOrderBasic--;
                    })
            })
        }
    }

    addToSelectedOptions = (id, pointsRequired, coupon) => {
        const {input} = this.props;
        const idAlreadyInArray = input.value.includes(id);
        if (idAlreadyInArray) {
            if (coupon) {
                this.props.subtractTradedPoints(pointsRequired);
            }
            input.value = input.value.filter(option => option !== id);
        } else {
            if (coupon) {
                this.props.addTradedPoints(pointsRequired);
            }
            input.value = [...input.value, id];
        }
        input.onChange(input.value)
    };

}

function mapStateToProps(state) {
    return {
        form: state.form
    };
}

export default connect(mapStateToProps, null)(withStyles(styles)(ImageBoxRow));
