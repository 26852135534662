import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@material-ui/styles";

import "react-select/dist/react-select.css";

const inputSizes = {
    desktop: {
        big: 100,
        small: 60
    },
    mobile: {
        big: 55,
        small: 35
    }
};

const useStyles = makeStyles((theme => ({
    root: {
        "& .Select-clear-zone": {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(3)
        },
        "& .Select-multi-value-wrapper": {
            [theme.breakpoints.up('xs')]: {
                width: props => (inputSizes.desktop[props.inputSize])
            },
            [theme.breakpoints.down('xs')]: {
                width: props => (inputSizes.mobile[props.inputSize])
            }
        },
        "& .Select-value": {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            right: 'unset !important',
            left: `${theme.spacing(0.5)}px !important`
        },
        "& .Select-arrow-zone": {
            width: '0 !important',
            padding: theme.spacing(0.5)
        }
    }
})));

const clearValue = ({
    label: <FormattedMessage id="comboboxNone" defaultMessage="None"/>,
    value: null
});

const Combobox = props => {
    const {input, options, multi, clearable, closeOnSelect, placeholder} = props;
    const {name, value, onBlur, onChange, onFocus} = input;
    const classes = useStyles(props);
    const transformedValue = transformValue(value, options, multi);

    const optionValues = [...options];
    clearable && optionValues.unshift(clearValue);

    return (
        <Select
            valueKey="value"
            name={name}
            value={transformedValue}
            multi={multi}
            clearable={false}
            placeholder={placeholder === "" ? placeholder : "Select..."}
            closeOnSelect={closeOnSelect}
            options={optionValues}
            onChange={multi
                ? multiChangeHandler(onChange)
                : singleChangeHandler(onChange)
            }
            onBlur={() => onBlur(value)}
            onFocus={onFocus}
            className={classes.root}/>
    );
};

Combobox.defaultProps = {
    multi: false,
    className: "",
    inputSize: 'big'
};

Combobox.propTypes = {
    classes: PropTypes.object,
    input: PropTypes.object,
    options: PropTypes.array,
    inputSize: PropTypes.oneOf(["small", "big"]),
    multi: PropTypes.bool,
    clearable: PropTypes.bool,
    closeOnSelect: PropTypes.object,
    tight: PropTypes.bool,
    moreTight: PropTypes.bool,
    veryTight: PropTypes.bool,
    placeholder: PropTypes.string
};

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
    return function handleSingleChange(value) {
        func(value ? value.value : '');
    };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
    return function handleMultiHandler(values) {
        func(values.map(value => value.value));
    };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
    if (multi && typeof value === 'string') {
        return [];
    }

    const filteredOptions = options.filter(option => {
        return multi
            ? value.indexOf(option.value) !== -1
            : option.value === value;
    });

    return multi ? filteredOptions : filteredOptions[0];
}

export default Combobox
