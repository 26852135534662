import React from "react";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Spinner from "../../../../common/components/common/Spinner";
import Terms from '../../../../common/components/common/Terms'
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {fetchMembershipConditions} from "../../../actions/kundklubb-public-actions";

class ConsumerMembershipTerms extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        company: PropTypes.object,
        conditions: PropTypes.object,
        fetchMembershipConditions: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchMembershipConditions();
    }

    render() {
        const {conditions, app} = this.props;

        if (!conditions) {
            return <Spinner app={app}/>
        }
        return (
            <Container>
                <Paper padding>
                    <Terms terms={conditions}/>
                </Paper>
            </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        conditions: state.company.conditions,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchMembershipConditions}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerMembershipTerms);
