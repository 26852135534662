import React from 'react';
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import {wrapWith} from "../../utils/component-utils";

const styles = theme => ({
    root: {
        width: 50,
        height: 50,
        border: `3px solid ${theme.palette.grey.light}`,
        borderTop: `3px solid ${theme.palette.secondary.main}`,
        borderRadius: '50%',
        animation: '$spin 2s linear infinite',
        verticalAlign: 'middle',
        display: 'inline-block'
    },
    rootCentered: {
        textAlign: 'center'
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(360deg)'
        }
    }
});

const Spinner = (props) => {
    const {app, classes, isCentered} = props;

    const content = app && app.status && app.status.isError
        ? <Typography
            variant="body1"
            color="error">
            UPS. Something went wrong
        </Typography>
        : <div className={classes.root}/>;

    return (
        isCentered
            ? wrapWith(content, "div", {className: classes.rootCentered})
            : content
    );
};

Spinner.propTypes = {
    classes: PropTypes.object.isRequired,
    app: PropTypes.object,
    isCentered: PropTypes.bool
};

Spinner.defaultProps = {
    isCentered: true
};

export default withStyles(styles)(Spinner)
