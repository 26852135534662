import axios from "axios";
import {FETCH_USER, UNSUBSCRIBE} from "../../common/actions/types";
import {apiError, apiSuccessMessage} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";

const ENDPOINT_URL = `${ROOT_URL}/unsubscribe`;

export function unsubscribeSms(memberId, history) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/sms/${memberId}`)
            .then(response => {
                dispatch({
                    type: UNSUBSCRIBE,
                    payload: response.data
                });
                dispatch(apiSuccessMessage("You are successfully unsubscribed from sms notification"))
                history.push("")
            })
            .catch(response => {
                dispatch(apiError('Unable to unsubscribe', response));
            });
    }
}

export function unsubscribeEmail(memberId, history) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/email/${memberId}`)
            .then(response => {
                dispatch({
                    type: UNSUBSCRIBE,
                    payload: response.data
                });
                dispatch(apiSuccessMessage("You are successfully unsubscribed from email notification"))
                history.push("")
            })
            .catch(response => {
                dispatch(apiError('Unable to unsubscribe', response));
            });
    }
}

export function fetchUserByUnsubscribeId(unsubscribeId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/consumer/${unsubscribeId}`)
            .then(response => {
                dispatch({
                    type: FETCH_USER,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch profile', response));
            });
    }
}
