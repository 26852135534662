import React from "react";

// use string for html element, for example wrapWith([...], "b")
// or class for react element, for example wrapWith([...], Link, {to: "/"})
export function wrapWith(content, Wrapper, wrapperProps = {}) {
    return (
        <Wrapper {...wrapperProps}>
            {content}
        </Wrapper>
    );
}
