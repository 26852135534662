import React from "react";
import PropTypes, {instanceOf} from "prop-types";
import {FormattedMessage} from "react-intl";
import {Cookies, withCookies} from 'react-cookie';
import classnames from "classnames";
import {addYears, endOfDay} from 'date-fns';

import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    wrapper: {
        zIndex: '999999',
        left: theme.spacing(0.5),
        right: theme.spacing(0.5),
        bottom: theme.spacing(0.5),
        "& > div": {
            display: 'inline-grid',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.blue.light,
            color: theme.palette.common.grey,
            borderRadius: theme.shape.borderRadius,
            boxShadow: 'none'
        }
    },
    arrow: {
        "& > div:after": {
            content: '""',
            position: 'absolute',
            border: `${theme.spacing(3)}px solid transparent`,
            borderTopColor: theme.palette.blue.light,
            borderBottom: 0,
            marginBottom: -theme.spacing(3)
        }
    },
    middleArrow: {
        bottom: theme.spacing(3.5),
        "& > div:after": {
            right: '43%',
            bottom: 0
        }
    },
    topArrow: {
        top: theme.spacing(3.5),
        "& > div:after": {
            right: '12%',
            top: -theme.spacing(3),
            transform: 'rotate(0.5turn)'
        }
    },
    icon: {
        verticalAlign: 'middle',
        width: 15
    },
    checkbox: {
        padding: 0,
        marginRight: theme.spacing(0.5)
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 40,
        padding: 0,
        margin: `${theme.spacing(0.5)}px 0 0 0`,
        flexDirection: 'row-reverse'
    }
});

class Popup extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        cookies: instanceOf(Cookies).isRequired,
        message: PropTypes.node,
        hasRepeatCheckbox: PropTypes.bool,
        position: PropTypes.oneOf(["top", "bottom"]),
        variant: PropTypes.oneOf(["default", "pointing"]),
        indicatedPlace: PropTypes.oneOf(["middle", "right"])
    };

    state = {
        isOpen: true,
        isCheckboxMarked: false
    };

    render() {
        const {classes, message, hasRepeatCheckbox, indicatedPlace, position, variant, cookies} = this.props;
        const {isOpen} = this.state;
        const isTopPlacement = position === 'top';
        const isAlwaysHidden = cookies.get('popup_hide', {path: '/'});
        const isTemporaryHidden = cookies.get('popup_temp_hide', {path: '/'});
        const snackbarClass = classnames(
            classes.wrapper,
            variant === "pointing" && classes.arrow,
            indicatedPlace && classes[indicatedPlace + 'Arrow']);

        return (
            <Snackbar
                open={isOpen && !isAlwaysHidden && !isTemporaryHidden}
                className={snackbarClass}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: isTopPlacement ? 'top' : 'bottom'
                }}
                message={
                    <React.Fragment>
                        {message}

                        <div className={classes.actions}>
                            <Button
                                color="default"
                                variant="outlined"
                                onClick={() => this.handleClosePopUp()}>
                                <FormattedMessage
                                    id="ok"
                                    defaultMessage="OK"/>
                            </Button>

                            {hasRepeatCheckbox && (
                                <Typography
                                    component="div"
                                    color='textPrimary'
                                    variant="caption">
                                    <Checkbox
                                        color="default"
                                        className={classes.checkbox}
                                        onChange={this.handleCheckbox.bind(this)}/>
                                    <FormattedMessage
                                        id="dontShowMeThisMessageAgain"
                                        defaultMessage="Don't show me this message again"/>
                                </Typography>
                            )}
                        </div>
                    </React.Fragment>
                }/>
        );
    }

    handleClosePopUp = () => {
        const {cookies} = this.props;
        const {isCheckboxMarked} = this.state;

        isCheckboxMarked
            ? cookies.set('popup_hide', isCheckboxMarked, {path: '/', expires: addYears(new Date(), 1)})
            : cookies.set('popup_temp_hide', isCheckboxMarked, {path: '/', expires: endOfDay(new Date())});

        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    };

    handleCheckbox = (event) => {
        this.setState({
            isCheckboxMarked: event.target.checked
        });
    }

}

export default withCookies(withStyles(styles)(Popup));
