import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {addLocaleData, IntlProvider} from "react-intl";
import seLocaleData from "react-intl/locale-data/se";
import {bindActionCreators} from "redux";

import * as translations from "../../../../../static/translations/index";
import {FRENCH, SWEDISH} from "../../../utils/enums/languages";
import {setLanguage} from "../../../actions/utils-actions";

class LanguageProvider extends React.Component {

    static propTypes = {
        profile: PropTypes.object,
        language: PropTypes.string,
        children: PropTypes.node,
        setLanguage: PropTypes.func
    };

    componentDidUpdate(prevProps) {
        const {profile, setLanguage} = this.props;

        if (profile && profile.language && (!prevProps.profile || prevProps.profile.language !== profile.language)) {
            setLanguage(profile.language)
        }
    }

    render() {
        const {children, language, profile} = this.props;

        const LOCALE = "EN";
        addLocaleData(seLocaleData);
        let translationsForUsersLocale = profile && profile.language || language;

        const userLanguage = profile && profile.language || language;
        switch (userLanguage) {
            case SWEDISH:
                translationsForUsersLocale = translations.se;
                break;
            case FRENCH:
                translationsForUsersLocale = translations.fr;
                break;
        }

        return (
            <IntlProvider
                locale={LOCALE}
                messages={translationsForUsersLocale}>
                {children}
            </IntlProvider>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.app.language,
        profile: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setLanguage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
