export const AMAZON_S3_URL = 'https://s3-eu-west-1.amazonaws.com';

export const KARMING_TERMS_OF_SERVICE_ADDRESS = AMAZON_S3_URL + '/karming-doc/TJÄNSTEAVTAL_Karming_20180415.pdf';

export const KARMING_PRIVACY_POLICY_ADDRESS = AMAZON_S3_URL + '/karming-doc/Integritetspolicy_Karming.pdf';

export const KARMING_REGISTERFORTECKNING_TEMPLATE = AMAZON_S3_URL + '/karming-doc/Registerforteckning.xlsx';

export const S3_IMAGES_LOCAL_URL = AMAZON_S3_URL + '/karming-images-local';

export const S3_IMAGES_DEV_URL = AMAZON_S3_URL + '/karming-images-dev';

export const S3_IMAGES_PROD_URL = AMAZON_S3_URL + '/karming-images-prod';

export const ROOT_URL = apiUrl();

export const APP_MERCHANT_URL = appMerchantUrl();

export const S3_IMAGES_URL = getS3ImagesUrl();

export const BILLING_DETAILS_URL = `${APP_MERCHANT_URL}/billings/details`;

export const SMS_URL = `${APP_MERCHANT_URL}/sms`;

// TODO refactor --> move to profiles
function getS3ImagesUrl() {
    /* eslint-disable no-undef */
    if (process.env.NODE_PROFILE === 'local') {
        return S3_IMAGES_LOCAL_URL;
    } else if (process.env.NODE_PROFILE === 'dev') {
        return S3_IMAGES_DEV_URL;
    } else if (process.env.NODE_PROFILE === 'prod') {
        return S3_IMAGES_PROD_URL;
    }
    /* eslint-enable no-undef */
}

function apiUrl() {
    /* eslint-disable no-undef */
    if (process.env.NODE_PROFILE === 'local') {
        return 'http://localhost:8800'
    } else if (process.env.NODE_PROFILE === 'dev') {
        return 'https://api.lab.karming.se'
    } else if (process.env.NODE_PROFILE === 'prod') {
        return 'https://api.kundklubb.online'
    }
    /* eslint-enable no-undef */
}

export function getAppConsumerUrl(subdomain) {
    /* eslint-disable no-undef */
    if (process.env.NODE_PROFILE === 'local') {
        return `http://${subdomain}.localhost:8080`
    } else if (process.env.NODE_PROFILE === 'dev') {
        return `https://${subdomain}.lab.karming.se`
    } else if (process.env.NODE_PROFILE === 'prod') {
        return `https://${subdomain}.kundklubb.online`
    }
    /* eslint-enable no-undef */
}

export function getAppConsumerUrlWithoutProtocol(subdomain) {
    /* eslint-disable no-undef */
    if (process.env.NODE_PROFILE === 'local') {
        return `${subdomain}.localhost:8080`
    } else if (process.env.NODE_PROFILE === 'dev') {
        return `${subdomain}.lab.karming.se`
    } else if (process.env.NODE_PROFILE === 'prod') {
        return `${subdomain}.kundklubb.online`
    }
    /* eslint-enable no-undef */
}

function appMerchantUrl() {
    /* eslint-disable no-undef */
    if (process.env.NODE_PROFILE === 'local') {
        return 'http://localhost:8080'
    } else if (process.env.NODE_PROFILE === 'dev') {
        return 'https://merchant.lab.karming.se'
    } else if (process.env.NODE_PROFILE === 'prod') {
        return 'https://app.smartkundklubb.se'
    }
    /* eslint-enable no-undef */
}