import React from "react"
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import {withStyles} from "@material-ui/core/styles";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Typography from "@material-ui/core/Typography";
import {colorRed} from "../../utils/color-const";
import ImageCheckedIcon from "./ImageCheckedIcon";
import UpdateStampcardBox from "../../../merchant/components/purchase/stampcard/UpdateStampcardBox";

const styles = theme => ({
    wrapper: {
        textAlign: 'center',
        fontSize: 10,
        wordBreak: 'break-word'
    },
    wrapperBorder: {
        border: `1px solid ${theme.palette.grey.main}`,
        borderRadius: 4
    },
    wrapperSmall: {
        width: 100,
        [theme.breakpoints.down("xs")]: {
            width: "30%"
        }
    },
    wrapperBig: {
        width: 130
    },
    wrapperSquare: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    boxBig: {
        width: 120
    },
    boxSmall: {
        width: 92
    },
    boxSelected: {
        border: `3px solid ${theme.palette.primary.main}`
    },
    boxClicable: {
        position: 'relative',
        "&:hover": {
            background: theme.palette.grey[100],
            opacity: 0.8,
            cursor: 'pointer'
        }
    },
    stampcardCouponWrapper: {
        position: 'relative'
    },
    stampcardTitleWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 99,
        color: theme.palette.common.white
    },
    stampcardCouponTitle: {
        whiteSpace: 'pre-wrap',
        position: 'relative',
        padding: 8,
        top: '40%',
        transform: 'translateY(-40%) rotate(0deg)',
        fontWeight: 'bold'
    },
    tradeText: {
        lineHeight: 0.9,
        margin: theme.spacing(0.5)
    },
    tradeTextRight: {
        textAlign: 'right'
    },
    boxText: {
        display: "inline-grid"
    },
    iconWrapper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    labelIcon: {
        color: theme.palette.secondary.main,
        fontSize: 40
    },
    contentCaption: {
        fontWeight: 'bold'
    },
    campaignsDetailsWrapper: {
        display: 'grid',
        "& :first-child": {
            fontWeight: 'bold'
        }
    },
    couponWrapper: {
        paddingTop: theme.spacing(0.5)
    },
});

class Box extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        content: PropTypes.object,
        history: PropTypes.object,
        remainingPoints: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        initiallySelectedOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        squareShape: PropTypes.bool,
        stampcardCoupon: PropTypes.bool,
        filter: PropTypes.bool,
        stampcard: PropTypes.bool,
        editable: PropTypes.bool,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        readOnly: PropTypes.bool,
        coupon: PropTypes.bool,
        campaignFilter: PropTypes.bool,
        onAddStamp: PropTypes.func,
        onFilterClick: PropTypes.func,
        onRemoveStamp: PropTypes.func,
        onClickOnBox: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            selected: false,
            changed: false,
            tooFewPoints: false,
            collectedStamps: props.content ? props.content.collected : null,
            addedStamps: props.content ? props.content.added : null
        };
    }

    componentDidMount() {
        const {content} = this.props;
        this.setState({collectedStamps: content ? content.collected : null});
        this.setState({addedStamps: content ? content.added : null});
    }

    componentDidUpdate(prevProps) {
        const {content} = this.props;
        if (content && prevProps.content) {
            if (content.added !== prevProps.content.added) {
                this.setState({addedStamps: content.added});
            }
            if (content.collected !== prevProps.content.collected) {
                this.setState({collectedStamps: content.collected});
            }
        }
    }

    render() {
        const {
            classes, content, stampcard, onClickOnBox, stampcardCoupon, value, filter, onFilterClick,
            initiallySelectedOptions, squareShape, coupon, editable, remainingPoints, readOnly,
            campaignFilter, onAddStamp, onRemoveStamp
        } = this.props;
        const {selected, changed, collectedStamps, addedStamps} = this.state;

        const initiallySelectedOption = initiallySelectedOptions
            && initiallySelectedOptions.includes(value)
            && !changed;
        const showGreen = (initiallySelectedOption || selected);

        const wrapperClassName = classnames(
            showGreen && classes.boxSelected,
            squareShape && classes.wrapperSquare,
            !readOnly && classes.boxClicable,
            classes.wrapper,
            classes.wrapperBorder,
            coupon && classes.couponWrapper,
            campaignFilter
                ? classes.wrapperBig
                : classes.wrapperSmall
        );

        const timestamp = new Date().getTime();
        return (
            <div
                onClick={
                    !stampcard
                        ? this.onClick.bind(this, value, initiallySelectedOption, onClickOnBox, editable,
                        coupon, content.pointsRequired, remainingPoints, content, showGreen, readOnly, filter,
                        onFilterClick)
                        : undefined
                }
                className={wrapperClassName}>
                {showGreen && <ImageCheckedIcon/>}

                <div>
                    {coupon && (
                        <Typography
                            variant="caption"
                            className={classnames(classes.tradeText, editable
                            || readOnly ? undefined : classes.tradeTextRight)}>
                            {readOnly && (
                                <span>
                                    <FormattedMessage
                                        id="tradeInFor"
                                        defaultMessage="Trade in for"/>
                                    &nbsp;
                                </span>
                            )}
                            <span
                                style={{
                                    color: ((remainingPoints < content.pointsRequired)
                                        && !selected) ? colorRed : ''
                                }}>
                                {content.pointsRequired}
                                &nbsp;
                                <span style={{textTransform: 'lowercase'}}>
                                <FormattedMessage
                                    id="points"
                                    defaultMessage="Points"/>
                                </span>
                            </span>
                        </Typography>
                    )}

                    {stampcard && (
                        <UpdateStampcardBox
                            content={content}
                            coupon={coupon}
                            collectedStamps={collectedStamps}
                            timestamp={timestamp}
                            addedStamps={addedStamps}
                            onRemoveClick={this.handleRemoveStamp.bind(this, content, onRemoveStamp)}
                            onAddClick={this.handleAddStamp.bind(this, content, onAddStamp)}
                        />
                    )}

                    {stampcardCoupon && (
                        <div className={classes.stampcardCouponWrapper}>
                            <div className={classes.stampcardTitleWrapper}>
                                {!content.hideTitle && (
                                    <div className={classes.stampcardCouponTitle}>
                                        {content.reward}
                                    </div>
                                )}
                            </div>

                            <img
                                height="auto"
                                width="100%"
                                src={content.imagePath + '?' + timestamp}/>

                            <div className={classes.boxText}>
                                <Typography variant="caption">
                                    <FormattedMessage
                                        id="validTo"
                                        defaultMessage="Valid to"/>:
                                </Typography>

                                <Typography variant="caption">
                                    {content.valid}
                                </Typography>
                            </div>
                        </div>
                    )}

                    {content.path && (
                        <img
                            height="auto"
                            width="100%"
                            src={content.path + '?' + timestamp}/>
                    )}

                    {coupon && (
                        <img
                            height="auto"
                            width="100%"
                            src={content.imagePath + '?' + timestamp}/>
                    )}

                    {squareShape && !content.icon && (
                        <div className={classes.iconWrapper}>
                            <LocalOffer className={classes.labelIcon}/>
                        </div>
                    )}

                    {squareShape && content.icon && (
                        <div className={classes.iconWrapper}>
                            {content.icon}
                        </div>
                    )}

                    <Typography
                        variant="caption"
                        className={classes.contentCaption}>
                        {content.label}
                    </Typography>

                    {campaignFilter && (
                        <div className={classes.campaignsDetailsWrapper}>
                            {content.dateRange && (
                                <span>
                                    {content.dateRange}
                                </span>
                            )}

                            {content.numberOfUsages !== undefined && (
                                <span>
                                    <FormattedMessage
                                        id="usages"
                                        defaultMessage="Usages"/>:
                                    {content.numberOfUsages}
                                </span>
                            )}

                            {content.sellTotalAmount !== undefined && (
                                <span>
                                    <FormattedMessage
                                        id="totalAmountOfSale"
                                        defaultMessage="Sale amount"/>:
                                    {content.sellTotalAmount} SEK
                                </span>
                            )}
                        </div>
                    )}

                    <Typography
                        variant="caption"
                        className={classes.contentCaption}>
                        {coupon && content.name}
                    </Typography>
                </div>
            </div>
        )
    }

    handleAddStamp(content, addStamp) {
        const {addedStamps} = this.state;
        if (this.state.collectedStamps < content.stampsAmount && addedStamps < content.stampsAmount) {
            const collectedStampsAfterAdd = this.state.collectedStamps + 1;
            const addedStampsAfterAdd = this.state.addedStamps + 1;
            this.setState({collectedStamps: collectedStampsAfterAdd});
            this.setState({addedStamps: addedStampsAfterAdd});
            addStamp(content.value, addedStampsAfterAdd);
        }
    }

    handleRemoveStamp(content, removeStamp) {
        const {collectedStamps} = this.state;
        if (collectedStamps > 0 && collectedStamps > (content.collected - content.added)) {
            const collectedStampsAfterRemove = this.state.collectedStamps - 1;
            const addedStampsAfterRemove = this.state.addedStamps - 1;
            this.setState({collectedStamps: collectedStampsAfterRemove});
            this.setState({addedStamps: addedStampsAfterRemove});
            removeStamp(content, addedStampsAfterRemove)
        }
    }


    onClick(value, initiallySelectedOption, onClickOnBox, editable, coupon, pointsRequired, remainingPoints, content,
            showGreen, readOnly, filter, onFilterClick) {
        if (filter) {
            onFilterClick(value)
        }

        if (coupon && editable) {
            this.props.history.push(`/coupons/${content.id}`)

        } else if (coupon && readOnly) {
            this.props.history.push(`/r/${content.id}`)
        } else if (!editable && !readOnly && !filter) {
            if (!coupon || showGreen || (remainingPoints >= pointsRequired)) {
                this.setState({selected: !(initiallySelectedOption || this.state.selected)});
                this.setState({changed: true});
                onClickOnBox(value, pointsRequired, coupon);
            }
        }
    }

}

export default withStyles(styles)(Box);
