import React from "react";
import PropTypes from "prop-types";

import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import {withStyles} from "@material-ui/core/styles";

const styles = {
    root: {
        minHeight: 64,
        justifyContent: 'center',
        alignItems: 'center'
    }
};

class AppToolbar extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
    };

    render() {
        const {classes, children} = this.props;

        return (
            <Toolbar className={classes.root}>
                {children}
            </Toolbar>
        );
    }

}

export default withStyles(styles)(AppToolbar);
