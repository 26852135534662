import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import LoggedUserAppBar from "./LoggedUserAppBar";
import GuestAppBar from "./GuestAppBar";

class AppBarMenu extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        chain: PropTypes.object,
        commercialChain: PropTypes.object,
        homeLocation: PropTypes.object,
    };

    render() {
        const {auth, app, user, chain, commercialChain, homeLocation} = this.props;

        if (!auth.authenticated) {
            return (
                <GuestAppBar
                    commercialChain={chain && chain}
                    history={history}
                    auth={auth}
                    app={app}/>
            );
        } else if (!user.profile || !commercialChain || !homeLocation) {
            return null
        }

        return (
            <LoggedUserAppBar
                commercialChain={commercialChain}
                homeLocation={homeLocation}/>
        );
    }

}

export default withRouter(AppBarMenu)
