import React from "react";
import MediaQuery from 'react-responsive'
import PropTypes from "prop-types";

import {useTheme} from "@material-ui/styles";

const RenderOnDesktop = (props) => {
    const {children} = props;
    const theme = useTheme();

    return (
        <MediaQuery minWidth={theme.breakpoints.values["md"]}>
            {children}
        </MediaQuery>
    )
};

RenderOnDesktop.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    theme: PropTypes.object
};

export default RenderOnDesktop;
