import React from "react";
import classnames from "classnames";

import {makeStyles} from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme => ({
    noMarginFormControl: {
        marginLeft: 0,
        marginRight: 0
    },
    checkbox: {
        display: 'flex'
    },
    defaultIcon: {
        fill: theme.palette.common.black
    },
    errorIcon: {
        fill: theme.palette.error.main
    },
    checkedIcon: {
        fill: theme.palette.primary.main
    }
})));

const RenderCheckbox = props => {
    const {
        className, input: {value, onChange, onBlur}, validate, label, disabled, noMargin,
        meta: {touched, error, warning}, ...rest
    } = props;
    const classes = useStyles();

    return (
        <FormGroup>
            <FormControlLabel
                {...rest}
                className={classnames(className, noMargin && classes.noMarginFormControl)}
                label={label}
                control={
                    <Checkbox
                        checked={Boolean(value)}
                        onChange={(event, value) => {
                            onChange(value);
                            onBlur()
                        }}
                        icon={<CheckBoxOutlineBlankIcon
                            className={touched && error ? classes.errorIcon : classes.defaultIcon}/>}
                        checkedIcon={<CheckBoxIcon
                            className={touched && error ? classes.errorIcon : classes.checkedIcon}/>}
                        validate={validate}
                        className={classes.checkbox}
                        disabled={disabled}/>
                }/>

            {touched && (
                (error && (
                    <Typography
                        color="error"
                        variant="caption"
                        align="left">
                        {error}
                    </Typography>
                ))
                || (warning && <span>{warning}</span>))}
        </FormGroup>
    )
};

RenderCheckbox.defaultProps = {
    disabled: false
};

export default RenderCheckbox
