import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import ConsumerCompleteInformationForm from "./ConsumerCompleteInformationForm";
import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import Dialog from "../../../common/components/common/Dialog";
import Button from "../../../common/components/common/Button";

import {fetchHomeLocation, fetchPointsOfSale, setPOSAsHomeLocation} from "../../actions/consumer-actions";
import {fetchUserDetails, updateUser} from "../../../common/actions/user-actions";
import {getCurrentSubdomain} from "../../actions/kundklubb-utils-actions";

const styles = theme => ({
    subtitle: {
        paddingLeft: theme.spacing(6),
        fontStyle: 'italic'
    },
    salePointsList: {
        listStyleType: 'none',
        paddingLeft: theme.spacing(1)
    }
});

class ConsumerCompleteInformation extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        app: PropTypes.object,
        user: PropTypes.object,
        commercialChain: PropTypes.object,
        homeLocation: PropTypes.object,
        profile: PropTypes.object,
        pointsOfSale: PropTypes.array,
        fetchUserDetails: PropTypes.func,
        fetchPointsOfSale: PropTypes.func,
        fetchHomeLocation: PropTypes.func,
        setPOSAsHomeLocation: PropTypes.func,
        updateUser: PropTypes.func
    };

    componentDidMount() {
        const {fetchUserDetails, fetchHomeLocation, fetchPointsOfSale} = this.props;
        fetchUserDetails();
        fetchPointsOfSale();
        fetchHomeLocation();
    }

    state = {
        openHomeLocationSelection: false,
        selectedHomeLocation: undefined
    };

    handleCloseLocationSelection = () => {
        this.setState({openHomeLocationSelection: false});
    };

    handleConfirmLocationSelection = () => {
        const {selectedHomeLocation} = this.state;
        this.props.setPOSAsHomeLocation(selectedHomeLocation);
        this.setState({openHomeLocationSelection: false});
    };

    togglePosCheckbox(pointOfSaleId) {
        this.setState({selectedHomeLocation: pointOfSaleId});
    }

    render() {
        const {classes, profile, history, app, pointsOfSale, homeLocation} = this.props;
        const {openHomeLocationSelection, selectedHomeLocation} = this.state;

        if (!profile || !profile.membershipId || !pointsOfSale || !homeLocation) {
            return <Spinner app={app}/>
        }

        if (homeLocation.name === 'Non Existing' && !selectedHomeLocation && !openHomeLocationSelection) {
            this.setState({openHomeLocationSelection: true});
        }

        const subdomain = getCurrentSubdomain();

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <ConsumerCompleteInformationForm
                        onSubmit={this.handleUpdateAndMoveToHome.bind(this, history)}
                        subdomain={subdomain}
                        initialValues={profile}
                        history={history}/>
                </Paper>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={this.handleCloseLocationSelection}
                    open={openHomeLocationSelection}
                    title={<FormattedMessage
                        id="homeLocationSelection"
                        defaultMessage="Choose your home location"/>}
                    actions={[
                        <Button
                            key="confirm"
                            label={<FormattedMessage
                                id="confirm"
                                defaultMessage="Confirm"/>}
                            onClick={this.handleConfirmLocationSelection.bind(this)}/>
                    ]}
                    content={
                        <ul className={classes.salePointsList}>
                            {pointsOfSale.map((pointOfSale, index) =>
                                <li key={index}>
                                    <Checkbox
                                        checked={selectedHomeLocation === pointOfSale.id}
                                        onChange={this.togglePosCheckbox.bind(this, pointOfSale.id)}/>
                                    {pointOfSale.commercialName} <br/>
                                    <Typography
                                        variant="caption"
                                        className={classes.subtitle}>
                                        {pointOfSale.address.street}, {pointOfSale.address.city}
                                    </Typography>
                                </li>
                            )}
                        </ul>
                    }/>
            </Container>
        );
    }

    handleUpdateAndMoveToHome(history, values) {
        const {updateUser} = this.props;
        updateUser(values, history, '/');
    }

}

function mapStateToProps(state) {
    return {
        profile: state.user.profile,
        pointsOfSale: state.consumer.pointsOfSale,
        homeLocation: state.consumer.homeLocation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateUser,
        fetchUserDetails,
        fetchPointsOfSale,
        fetchHomeLocation,
        setPOSAsHomeLocation
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConsumerCompleteInformation));
