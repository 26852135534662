export default {
    "exportData": "Exportera uppgifter",
    "appIcon": "App ikon",
    "1kr1Point": "1 kr = 1 poäng",
    "startGuide": "Start-guide",
    "account": "Konto",
    "automatic": "Automatiskt",
    "activateSms": "Aktivera SMS",
    "active": "Aktivt",
    "activeRewards": "Aktiva belöningar",
    "addReward": "Skapa belöningar",
    "addedStamps": "På detta köp",
    "activeCards": "Påbörjade och aktiva kort",
    "activate": "Aktivera",
    "activateSmsService": "Aktivera SMS-tjänst",
    "Activation of SMS service": "Aktivering av SMS-tjänst",
    "activateConfirmation": "Bekräfta aktivering",
    "activeWelcomeOffering": "Aktivera välkomsterbjudande",
    "add": "Lägg till",
    "addSmartSelection": "Lägg till Smart Urval",
    "atSinglePurchaseTransaction": "Vid minst ett och samma köptillfälle",
    "addCard": "Lägg till kort",
    "addParagraph": "Lägg till Paragraf",
    "address": "Adress",
    "after": "Efter",
    "assignmentAndDisplay": "Tilldelning och visning av poäng",
    "and": "och",
    "areYouSureWithdrawStampcard": "Är du säker att du vill återkalla kortet?",
    "areYouSureStopSharingStampcard": "Är du säker att du vill stoppa utdelning av nya kort?",
    "animals": "Djur",
    "areYouSureText": "Är du säker att du vill inaktivera automatiskt påfyllning av SMS-krediter?",
    "areYouSureRemoveCardText": "Är du säker att du vill ta bort kort?",
    "areYouSureRemoveMemberText": "Är du säker att du vill ta bort Medlem?",
    "areYouSureRemovePurchaseText": "Är du säker att du vill ta bort köp?",
    "areYouSureRemoveUserText": "Är du säker på att du vill ta bort användaren?",
    "att": "ATT",
    "attract-members.WelcomeOfferingIsVisableText": "Ett Välkomsterbjudande är synligt för alla besökare på din medlemssida och erbjuds till alla nya medlemmar under gilltighetstiden (t.ex. XXX på köpet)",
    "attract-members.makeSureAsManyAsPossibleEnrollText": "Se därför till att så många som möjligt ansluter till din kundklubb!",
    "attract-members.toBecomeAMemberText": "Att bli medlem är värdefullt för både dig och dina kunder!",
    "attractMembers": "Få nya medlemmar",
    "amountStamps": "{stampsAmount} stämplar",
    "accountHasBeenActivated": "Kontot har aktiverats.",
    "balanceForCredits": "Saldo: {credits} SMS-krediter",
    "birthday/age": "Födelsedatum / Ålder",
    "banner": "Banner",
    "balance": "Saldo",
    "background": "Bakgrunder",
    "background.lowercase": "bakgrund",
    "becameMember": "Blev medlem",
    "notBecameMember": "Inte blev medlem",
    "bonusFixedValueInSEK": "Kupongens fasta värde i kronor",
    "before": "Före",
    "beforeActivateClubPoints": "Du måste skapa din första belöning innan du kan aktivera poänginsamlingen.",
    "beforeCreatingClubPointsYouHaveToPublish": "Innan du aktiverar Klubbpoäng behöver du publicera dina" +
        " medlemsvillkor.",
    "buttonLabel": "Knappens titel",
    "backgroundTextStamps": "Bakgrund, text och ståmplar",
    "beforeCreatingStampcardYouHaveToPublish": "Innan du aktiverar Stämpelkort behöver du publicera dina" +
        " medlemsvillkor.",
    "beforeSmartSelectionInviteMOreMembers": "Bjud in fler medlemmar för att använda Smart Urval",
    "beforeInviteMemberYouHaveToDefine": "Innan du bjuder in en medlem måste du publicera",
    "billingAddress": "Fakturaaddress",
    "billingDetails": "Faktureringsuppgifter",
    "billingEmail": "E-post faktura",
    "brieflyDescribeReward": "Beskriv kortfattat den belöning kunden får efter att ha samlat {stampsAmount} stämplar.",
    "campaign-form.campaignDateText": "Vilken period ska kampanjen vara synlig i appen?",
    "campaign-form.welcomeOfferIsValidText": "Välkomsterbjudandet är synligt för varje medlem i {durationInMonths}" +
        " månader från registreringsdatum, eller till dess att ni registrerar ett medlemsköp på medlemmen och samtidigt markerar att medlemmen utnyttjar Välkomsterbjudandet.",
    "campaignConfirmation": "Kampanjbekräftelse",
    "campaignEmailPreview": "Förhandsgranska e-postkampanj",
    "campaignMessage": "Kampanjmeddelande",
    "campaignName": "Kampanjnamn",
    "campaignSendout": "Kampanjutskick",
    "campaignSendout.afterSendoutMessage": "Din kampanjutskick är nu skickat och utvalda medlemmar kommer att få ett SMS och/eller E-postmeddelande.",
    "campaign.toWhichMembersSendout": "Till vilka medlemmar vill du göra ett kampanjutskick?",
    "campaign.sendoutToAllMembers": "Utskicka sker till alla medlemmar.",
    "campaign": "Kampanj",
    "campaigns": "Kampanjer",
    "congratulations": "Grattis!",
    "canInviteAndDeleteOtherLocalAdmin": "Kan bjuda in och radera andra lokala admin?",
    "campaign.defaultAudience": "Kampanjen visas för alla medlemmar.",
    "campaign.forWhichMembersCampaignVisible": "För vilka medlemmar ska kampanjen visas?",
    "chooseWhichSumYouWantToUse": "Välj vilken summa du vill använda som urval",
    "cancel": "Avbryt",
    "cardNumber": "Kortnummer",
    "changeSelection": "Ändra urval",
    "cashier": "Kassör",
    "categories": "Kategorier",
    "category": "Kategori",
    "categoryName": "Kategorinamn",
    "chains": "Kedjor",
    "changeCard": "Byt kort",
    "change": "Ändra",
    "clubPoints": "Klubbpoäng",
    "countedPointsFrom": "Beräkna poäng på köp gjorda fr.o.m.",
    "club-points-activation.pointsIsCalculated": "Poäng beräknas på köp gjorda från och med nu.",
    "club-points-activation.ProvideADate": "Vill du istället räkna tidigare registrerade köpesummor anger du ett eget" +
        " datum bakåt i tiden.",
    "club-points-activation.pointsIsCalculatedFromTheSameDate": "Poäng beräknas på köp gjorda från samma datum som vid senaste användning av klubbpoäng.",
    "club-points-activation.provideADateAnotherTime": "Ange valfritt datum om du vill tillgodoräkna registrerade köpesummor från annan tidpunkt.",
    "chargedQuarterlyInAdvance": "debiteras kvartalsvis i förskott",
    "christmas": "Jul",
    "chooseBackgroundForRewardCoupon": "Välj bakgrund för belöningskupongen",
    "chooseHomeLocationForMember": "Välj Hembutik för medlem (frivilligt)",
    "city": "Stad",
    "clickToSelectTemplateOrUploadImageText": "Klicka för välja en mall eller ladda upp en egen bild.",
    "clickToUploadOwnImageText": "Klicka för ladda upp en egen bild.",
    "close": "Stäng",
    "clear": "Rensa",
    "commercial-chain-form.subdomain": "Önskad webb-adress",
    "commercial-chain-form.webAddressForMembersClub": "Webb-adress för kundklubb",
    "commercialChain": "Kedja",
    "commercialChains": "Kedjor",
    "commercialName": "Kommersiellt namn",
    "companies": "Företag",
    "company": "Företag",
    "currentCartCreatedAfterPurchase": "Aktuellt kort skapat efter detta köp.",
    "changeBackground": "Byt bakgrund",
    "coupon": "Belöningskupong",
    "createCoupon": "Skapa Värdekupong",
    "club-points.forEach1Krona": "För varje krona dina kunder handlar för tjänar  de 1 poäng  som är giltiga den period du bestämmer.",
    "club-points.pointBalanceIsCalculated": "Poängsaldo beräknas och visas automatiskt för kunderna i appen efter varje registrerat köp.",
    "club-points.customerAutomatically": "Kunden får sedan ta del av de belöningar du erbjuder baserat på antal intjänade poäng.",
    "club-points.YouCanOffer": "Du kan erbjuda en stående belöning som klassisk värdecheck eller variera och ändra över tid.",
    "club-points.formulateYourTerms": "Formulera villkoren för poäng att lägga till i medlemsvillkoren.",
    "club-points.youCanEitherFormulate": "Du kan antingen formulera egna eller utgå från det färdiga exemplet.",
    "club-points.noThankYou": "Nej tack, medlemsvillkoren är redan anpassade för poäng.",
    "couponIsAutomaticallyGenerated": "Värdekupong tilldelas automatiskt",
    "coupon-form-page-2.example": "Till exempel ”100 kr bonus” eller ”Fri vara X",
    "companyName": "Företagsnamn",
    "companyNumber": "Organisationsnummer",
    "complete": "Slutför",
    "confirmDeactivatingText": "Bekräfta inaktivering av automatiskt påfyllning av SMS-krediter",
    "completeRegistration": "Slutför registrering",
    "confirm": "Bekräfta",
    "chooseWhichTypeOfSelectionYouWantToMake": "Välj vilken typ av urval du vill göra",
    "chooseWhichDatesYouWantToUse": "Välj vilka datum du vill använda som urval",
    "chooseWhichQuantityYouWantToUse": "Välj vilka antal du vill använda som urval",
    "confirmAndBecomeMember": "Bekräfta och bli medlem",
    "confirmationLinkText": "Meddelande med aktiveringslänk har skickats till {contactData} du angav.",
    "confirmActivation": "Bekräfta aktivering",
    "confirmPassword": "Bekräfta lösenord",
    "congrats": "Gratulerar!",
    "consumer-export-data.exportDataText": "För att exportera uppgifter, vänligen skicka oss ett mail.",
    "consumer-onboarding-form.copyLinkText": "Kopiera länk och klistra in i din webbläsare",
    "consumer-onboarding-verification-form.chooseYourPasswordText": "Välj lösenord och fyll i dina uppgifter.",
    "consumer-terminate.terminateAccountText": "För att avsluta ditt konto och ditt medlemsskap, vänligen skicka oss ett mail.",
    "consumerData": "Kunduppgifter",
    "consumers": "Kunder",
    "changeStampsLook": "Byt stämpelns utseende",
    "changeStampsBackground": "Byt stämpelns bakgrund",
    "customerReceivesRewardFor": "Kunden får belöning efter",
    "cropImage": "Skala bilden",
    "TheCustomersPointsIsValidFor": "Kundens insamlade poäng är giltiga",
    "customerIsRewardedAsYouProfit": "Kunden belönas allt eftersom du tjänar",
    "contact": "Kontakt",
    "contactDataConfirmation": "{yourContactData} har bekräftats!",
    "contactPeople": "Nå ut till dina kunder",
    "create": "Skapa",
    "createSendout": "Skapa utskick",
    "credit": "SMS-kredit",
    "credits": "SMS-krediter",
    "createCouponReward": "Skapa belöningskupong",
    "creditCardSuccessfullyRegistered": "Betalkort registrerat",
    "continueToSmsServiceSettings": "fortsätt till inställningar för SMS-tjänst ",
    "crop": "Beskär",
    "coupons": "Värdekuponger",
    "currentSelection": "Nuvarande urval",
    "customizeTerms": "Skräddarsy villkor",
    "cvc": "CVC",
    "dashboard": "Översikt",
    "deselectAll": "Avmarkera alla",
    "day": "Dag",
    "dateRange": "Skickat perioden:",
    "dayLowercase": "dag",
    "dayOfMonth": "i månaden",
    "dataProtectionCenter": "Dataskyddcenter",
    "dataProtectionIntroduction": "Introduktion till dataskydd",
    "dataProcessingAgreement": "Personuppgiftsbiträdesavtalet",
    "dateOfBirth": "Födelsedatum",
    "dateOfPurchase": "Köpdatum",
    "deactivate": "Avaktivera",
    "define": "Beskriv",
    "defaultMemberLanguage": "Förvalt medlemsspråk:",
    "designAndText": "Design och text",
    "date": "Datum",
    "days": "Dagar",
    "description": "Beskrivning",
    "draft": "utkast",
    "draftUppercase": "Utkast",
    "draftRewards": "Utkast belöningar",
    "dropFileHereClickToSelectFile": "Beskrivning",
    "doYouWantToAddLink": "Vill du lägga till en länk? (frivilligt)",
    "doYouRatherWantToLet": "Vill du istället låta de som har påbörjat att samla stämplar få möjlighet att slutföra kortet och få sin sista belöning ska du välja ”Stoppa utdelning av nya kort”",
    "doYouWantToAddSubtitle": "Vill du lägga till en underrubrik",
    "dontHaveAnAccountYet": "Har du inget konto?",
    "defaultButtonLabelIsReadMore": 'Knappens standardtitel är "Läs mer", men du kan även ange en egen titel.',
    "edit": "Redigera",
    "easy": "Enkelt",
    "enterAShortAndAttractiveHeadingOnYourStampCard": "Ange en kortfattad och tilltalande rubrik på ditt stämpelkort.",
    "earnedPoints": "Intjänade poäng",
    "editProfile": "Redigera profil",
    "excludeMembersThatHaveNotMadePurchaseTheLast12Months": "Exkludera medlemmar som ej gjort köp på 12 månader",
    "excludeMembersThatAlreadyReceivedSendout": "Exkludera medlemmar som redan fått utskick för denna kampanj",
    "excludeMembersThatHaveUsedThisCampaignAlready": "Exkludera medlemmar som gjort köp med denna kampanj",
    "email": "E-post",
    "example": "Exempel",
    "emailAddress": "E-postadress",
    "emailSendout": "Smarta E-postutskick",
    "endDate": "Slutdatum",
    "enterYourEmailOrPhoneNumber": "Ange din e-postadress eller telefonnummer",
    "enterYourPassword": "Ange din lösenord",
    "expirationDate": "Utgångsdatum",
    "errors.minCannotBeBiggerThanMax": "Får inte vara högre än maxvärdet",
    "errors.minCannotBeLessThanDefaultValue": "Får inte vara lägre än det minsta tillgängliga värdet",
    "errors.maxCannotBeLessThanMin": "Får inte vara lägre än minimum",
    "errors.maxCannotBeBiggerThanDefaultValue": "Får inte vara högre än det största tillgängliga värdet",
    "fashion": "Mode",
    "female": "Kvinna",
    "find": "Sök",
    "findAudience": "Hitta mottagare",
    "findConsumer": "Sök medlem",
    "findMemberToCheckout": "Sök medlem",
    "first-pos-dialog.AChainWithMultipleSalesLocation": "En kedja med flera butiker",
    "first-pos-dialog.SetupYourFirstPointOfSale": "Skapa din första butik",
    "first-pos-dialog.SingleSaleLocation": "En enskild butik",
    "first-pos-dialog.setUpLoyaltyProgramFor": "Jag skapar ett lojalitetsprogram för:",
    "firstName": "Förnamn",
    "forgotPassword": "Glömt lösenord",
    "required": "Obligatoriskt",
    "free14DaysTrail": "Prova på gratis i 14 dagar",
    "from": "Från",
    "friday": "Fredag",
    "generateAutomatically": "Generera automatiskt",
    "giveATitleToYourStampCard": "Ge en rubrik till ditt stämpelkort",
    "getTemplate": "Till mallen",
    "getOut": "Ta fram",
    "getStarted": "Kom igång",
    "gender": "Kön",
    "generateTemplate": "Generera färdigt exempel",
    "haveReadAndAgreeText": "Jag har läst och godkänner",
    "haveMadeBelowNumberOfPurchases": "Har handlat nedan antal gånger",
    "haveNotMadeBelowNumberOfPurchases": "Inte har handlat nedan antal gånger",
    "hi": "Hej",
    "header": "Rubrik",
    "home": "Hem",
    "hideSubtitle": "Dölj undertext",
    "hideTitle": "Dölj rubrik",
    "hereWeGo": "Ok, nu kör vi!",
    "howManyStampsShouldTheCustomerCollectForReward": "Hur många stämplar ska kunden samla för att blir belönad",
    "howLongDoesTheCustomerTakeToCollectTheStampsToBeRewarded": "Hur länge ska kunden ha på sig att samla stämplarna för att få belöning",
    "havePoints": "Har poängsaldo",
    "haveNotPoints": "Har inte poängsaldo",
    "haveSpent": "Har handlat för",
    "haveNotSpent": "Har inte handlat för",
    "howManyPoints": "Hur mång poäng behöver kunden lösa in för att ta del av erbjudandet?",
    "HowLongTimeDoesTheCustomerHaveToCollectPoints": "Hur lång tid har kunden på sig att samla poäng",
    "HowShouldTheClubPointsBeDescribedToYourCustomers": "Hur vill du att klubbpoängen beskrivas för dina kunder",
    "howSmartItIs": "Så smart är det",
    "image": "bild",
    "isTheRewardInSwedishKrona": "Är belöningen en värdecheck med fast värde i svenska kronor som kunden kan handla för?",
    "incidentReport": "Incidentrapport",
    "including": "Inklusive",
    "inTotal": "Totalt",
    "internalName": "Internt namn",
    "inactive": "Inaktivt",
    "inactiveRewards": "Inaktivt belöningar",
    "invitationLink": "Inbjudningslänk",
    "invite": "Bjud in",
    "invite-members.inviteMemberText": "Bjud in ny medlem med Mobilnummer eller E-post.",
    "invite-members.memberGetsLink": "kunden får en länk för att slutföra registreringen",
    "inviteMember": "Bjud in medlem",
    "inviteMerchant": "Bjud in Företag",
    "invite-merchant.inviteMerchantText": "För att bjuda in Användare skriv in e-postadress.",
    "itIsYou": "Det är du",
    "justToBeSure": "Bara för att vara på säkra sidan",
    "later": "Senare",
    "lastTimePointsWereCountedFrom": "Senast räknades poäng fr.o.m.:",
    "lastChosenLocation": "Senast valda plats",
    "lastName": "Efternamn",
    "lastPurchase": "Senaste köp",
    "loadDefault": "Ladda standard",
    "location": "Plats",
    "locationSelection": "Välj nuvarande plats",
    "login": "Logga in",
    "logo": "Logotyp",
    "localAdmin": "Lokal admin",
    "tradeInFor": "Löses in för ",
    "logout": "Logga ut",
    "loginToAccessAccount": "Logga in eller Registrera dig för att komma åt ditt konto och ta del av erbjudanden!",
    "manageMembership": "Hantera Medlemsskap",
    "madePurchase": "Har handlat",
    "notMadePurchase": "Inte har handlat",
    "marketingMessages": "Marknadsutskick",
    "madePurchaseInAnyOfTheseCategories": "Har handlat i någon av dessa kategorier",
    "madePurchaseInAllOfTheseCategories": "Har handlat i samtliga av dessa kategorier",
    "notMadePurchaseInAnyOfTheseCategories": "Inte har handlat i någon av dessa kategorier",
    "noMadePurchaseInAllOfTheseCategories": "Inte har handlat i samtliga av dessa kategorier",
    "max": "Max",
    "male": "Man",
    "memberOnboarding": "Medlemsanslutning",
    "mediumAmount": "Kanal (antal)",
    "member": "Medlem",
    "memberId": "Medlems-ID",
    "members": "Medlemmar",
    "membership": "Medlemsskap",
    "membership-conditions-home.membershipConditionsFirstPartText": "Innan du bjuder in medlemmar till din kundklubb behöver ni formulera era medlemsvillkor.",
    "membership-conditions-home.membershipConditionsSecondPartText": "Du kan antingen formulera dessa på egen hand" +
        " eller utgå från det färdiga exemplet som är anpassat utifrån era uppgifter.",
    "membershipCard": "Medlemskort",
    "membershipConditions": "Medlemsvillkor",
    "membershipId": "Medlems-Id",
    "membershipNumber": "Medlemsnummer",
    "membershipProgram": "Kundklubbsprogram",
    "merchant-home-start-guide-not-completed.startGuideCongratulationsText": "Grattis till ett Smart beslut!",
    "merchant-home-start-guide-not-completed.startGuideWillHelpText": "Start-guiden kommer att hjälpa dig komma igång. När du slutfört stegen är du 100% redo att bjuda in dina kunder till din egna digitala kundklubb och bygga vidare på er relation!",
    "merchant-home-start-guide-not-completed.youWillFindTheStartGuideOnTheRightText": "Start-guiden hittar du här till höger tills dess att du har kommit igång.",
    "merchant-incident-report.pleaseDescribe": "Vänligen beskriv ärendet och skicka till {email}.",
    "merchant-onboarding-verification-page-one.adminPasswordText": "Välj ditt lösenord för",
    "merchant-onboarding-verification-page-one.emailConfirmedText": "Din e-post har bekräftats!",
    "merchant-onboarding-verification-page-two.membersIncluded": "Upp till {memberNumber} medlemmar inkluderad",
    "merchant-onboarding-verification-page-two.smsSendoutsText": "Smarta SMS-utskick*",
    "merchant-onboarding-verification-page-two.thereafterText": "Prova på gratis i {daysFree} dagar (därefter {price} SEK /månad/säljställe)",
    "merchant-onboarding-verification-page-two.smsCostSeparate": "SMS debiteras separat*",
    "merchantDetails": "Företagsuppgifter",
    "merchants": "Användare",
    "membersWithValidStamps": "Medlemmar som har stämplar kvar på de klippkort du avmarkerar kommer även att se detta klippkort samtidigt.",
    "membersAlreadyStartedAStopped": "För medlemmar som redan påbörjat ett klippkort som stoppats så visas detta klippkortet först när dessa klippkort stämplats fullt eller förfallit (”löpt ut”).",
    "min": "Min",
    "mySubscription": "Mitt abonnemang",
    "myHomeLocation": "Min Hembutik",
    "monday": "Måndag",
    "months": "Månader",
    "month": "Månad",
    "mobileNumber": "Mobilnummer",
    "mobileMembershipId": "Mobil/Medlems-Id",
    "memberFound": "medlemmar funna",
    "passwordResetFailNoUser": "Inget meddelande för återställning av lösenord har skickats. Det finns ingen användare med matchande kontaktuppgift.",
    "name": "Namn",
    "numberOfUsages": "Använd (antal gånger)",
    "new-chain-or-pos-dialog.createNewPointOfSale": "Skapa nytt Säljställe",
    "new-chain-or-pos-dialog.newChainOrPosDialogText": "Delar det nya Säljstället samma medlemsvillkor? \n    Om inte kommer det skapas en separat kedja med egna villkor",
    "next": "Nästa",
    "no": "Nej",
    "notPossibleToRedeemOrReturnCoupons": "Det är inte möjligt att lösa in eller returnera kuponger.",
    "nowYouCanInvite": "Nu kan du bjuda in och skicka kampanjer till dina medlemmar via SMS.",
    "noteAnyAdditionalSelectionPart1": "OBS! Ytterliga urval av typen ",
    "noteAnyAdditionalSelectionPart2": "kommer att valideras inom samma period angiven för köpdatum.",
    "noBindingPeriod": "Ingen bindningstid",
    "notifications": "Aviseringar",
    "number": "Antal",
    "numberOfStamps": "Antal stämplar",
    "noneDefault": "Ingen (frivilligt)",
    "numberOfPurchases": "Antal köp",
    "onboarding": "Inbjudning av kund: Smartkundklubb.se ",
    "onboardingMerchant": "Registrera dig för tjänsten: Smartkundklubb.se ",
    "oneTimeOffer": "Engångserbjudande",
    "openingHours": "Öppettider",
    "optional": "Frivilligt",
    "or": "eller",
    "okLetsStart": "Ok, nu kör vi!",
    "order": "Bekräfta",
    "orderConfirmation": "Bekräfta beställning",
    "orderConfirmationText": "Jag bekräftar köp och att jag läst, förstår och godkänner",
    "smsActivationText": "Jag bekräftar aktivering av SMS-tjänsten och att jag läst, förstår och godkänner",
    "other": "Annan",
    "otherOptions": "Välj annat",
    "own": "egen",
    "paragraph": "Paragraf",
    "paragraphContent": "Paragrafinnehåll",
    "paragraphTitle": "Paragraftitel",
    "password": "Lösenord",
    "passwordChanged": "Lösenord ändrat",
    "password-reset-form.typeYourNewPasswordTwice": "Skriv ditt nya lösenord två gånger",
    "password-reset-request.sendMessageWithFurtherInstructions": " och vi skickar dig ett meddelande med mer" +
        " information.",
    "payment": "Betalning",
    "paymentCard": "Betalkort",
    "paymentType": "Betalsätt",
    "perMonth": "{amount} SEK per månad",
    "phone": "Telefon",
    "phoneNumber": "Telefonnummer",
    "phoneOrEmail": "Telefon eller e-post",
    "pleaseCheck": "Vänligen kolla",
    "pleaseCheckText": "Vänligen kolla {contactType} och klicka på länken för slutföra registreringen.",
    "pleaseProvideAdditionalData": "Vänligen skriv in mer information",
    "preview": "Förhandsgranska",
    "pointsRequiredDisabledOnUpdatePart1": "Antal poäng för att lösa in belöning kan inte ändras efter publicering.",
    "pointsRequiredDisabledOnUpdatePart2": "Du kan avaktivera denna belöning och skapa en ny som löses in mot det antal poäng du önskar.",
    "point-of-sale-form.subpage": "Undersida",
    "pointsAppreciatedWayToEnsureAndKeepLoyalAndProfitableCustomers": "Poäng är ett uppskattat sätt att skapa och behålla lojala och lönsamma kunder.",
    "pointOfSale": "Säljställe",
    "pointsOfSale": "Säljställe",
    "pointsValidFor": "Poäng är giltiga i",
    "phoneNumberWithoutPrefix": "Telefonnummer utan prefix",
    "previous": "Tillbaka",
    "provideNumberOfDays": "Ange antal dagar",
    "provideWebAddress": "Om du anger en webbadress nedan visas en knapp (på kampanjsidan) som länkar till denna adress.",
    "privacy-policy-home.privacyPolicyFirstPartText": "Innan du lanserar ditt lojalitetsprogram, måste du formulera din Personuppgiftspolicy.",
    "privacy-policy-home.privacyPolicySecondPartText": "Med personuppgiftspolicyn informerar du den som registrerar sig om syftet till att du hanterar deras personuppgifter, och hur du hanterar dessa på ett säkert sätt.",
    "privacy-policy-home.privacyPolicyThirdPartText": "Du kan antingen formulera den på egen hand eller utgå från det färdiga exemplet som är anpassat utifrån era uppgifter och tjänstens funktionalitet.",
    "privacyPolicy": "Personuppgiftspolicy",
    "profile": "Profil",
    "pointsSetToLowestPossibleWarning": "! Poäng sätts till lägsta möjliga = {minEarnedPoints}p.",
    "points": "Poäng",
    "pointsBalance": "Poängsaldo",
    "pointsEarnedCantBeLowered": "Intjänade poäng kan inte sänkas mer än medlemmens aktuella poängsaldo som är {currentBalance}",
    "pointsAreCountedFrom": "Poäng räknas från och med:",
    "publish": "Publicera",
    "publishToAll": "Publicera för alla",
    "publishToSelected": "Publicera för urval",
    "published": "Publicerat",
    "purchaseDetails": "Köpinformation",
    "purchases": "Köp",
    "purchase": "köp",
    "publishOnceTheseCardsFallDue": "Publicera när dessa klippkort “löpt ut”",
    "purchaseAmount": "Köpesumma",
    "purchaseDate": "Köpdatum",
    "purchaseCapital": "Köp",
    "profitable": "Lönsamt",
    "provideMaximum60Characters": "Ange upp till 60 tecken.",
    "provideInternalName": "Ange ett internt namn för stämpelkortet",
    "provideSuitableTitle": "Ange en lämplig titel på din belöning som kunden kan lösa in.",
    "provideADescriptionThatWillDisplayed": "Ange en beskrivning som visas tillsammans med kupongen. Exempelvis eventuella begräsningar m.m",
    "provideMinimum3Characters": "Ange minst 3 tecken",
    "provideMinimum5Characters": "Ange minst 5 tecken",
    "provideNumberOfMonths": "Ange antal månader",
    "provideFullMemberId": "Ange komplett medlems-ID",
    "provideMembershipIdOrFullMobileNumber": "Ange komplett medlems-ID eller komplett mobilnummer",
    "provideFullMobileNumber": "Ange komplett mobilnummer",
    "purchaseOfSMSCreditsWorth": "Köp av SMS-krediter för {amount} SEK",
    "purchaseIsMadeInEvenText": "köp sker med jämna {amount} SEK",
    "pleaseBeAwareText": "Observera att om du inaktiverar automatiskt påfyllning av SMS-krediter innebär det att dina kunder inte kan registrera sig med sitt mobilnummer om du glömt att ladda på innan dina krediter tar slut.",
    "recipients": "Mottagare",
    "publishStampcard": "Publicera stämpelkort",
    "provideMaximumCharactersPerTitleRow2": "Ange maximalt {numberOfCharacters} tecken per rad. Tryck Enter för att skapa en ny rad. (Max två rader.)",
    "provideMaximumCharactersPerTitleRow3": "Ange maximalt {numberOfCharacters} tecken per rad. Tryck Enter för att skapa en ny rad. (Max tre rader.)",
    "purchaseOutsidePointsValidity": "Detta köp är utanför aktuell giltighetstid för poäng.",
    "publish-status.new": "Ny",
    "publish-status.stopped": "Stoppad",
    "publish-status.completed": "Slutfört",
    "publish-status.suspended": "Suspenderad",
    "publish-status.active": "Aktiv",
    "publish-status.expired": "Förfallet",
    "publish-status.withdrawn": "Återkallat",
    "rewardYourFaithfulCustomers": "Belöna dina trogna kunder",
    "rewardsIsGivenAfter": "Belöning ges efter {stampsAmount} stämplar",
    "recreate": "Gör om",
    "removeBackground": "Ta bort bakgrund",
    "recordList": "Registerförteckning",
    "redeemedCoupons": "Inlöst värdekupong",
    "rejected": "nekad",
    "receivedAnySendout": "Har fått något av de markerade utskicken",
    "receivedAllSendout": "Har alla något av de markerade utskicken",
    "notReceivedAnySendout": "Inte fått något av de markerade utskicken",
    "notReceivedAllSendout": "Inte fått alla de markerade utskicken",
    "removedMember": "Borttagen medlem",
    "rewardIsAwardedAutomatically": "Belöning tilldelas automatiskt",
    "reward": "Belöning",
    "remainingPoints": "Kvarvarande poäng",
    "remove": "Ta bort",
    "reset": "Återställ",
    "rewardYourProfitableCustomers": "Belöna dina lönsamma kunder",
    "removeCard": "Ta bort kort",
    "resetPassword": "Återställa lösenord",
    "reference": "Referens",
    "register": "Registrera",
    "rewardIsTradedFor": "Belöningen löses in mot",
    "register-purchase-form.consumer": "Kund",
    "render-image-picker.other": "Övrigt",
    "registerCard": "Registrera kort",
    "registerPurchase": "Registrera köp",
    "removeFile": "Ta bort fil",
    "quantity": "Antal",
    "save": "Spara",
    "saveNewBackground": "Spara ny bakgrund",
    "saveNewCardDesign": "Spara ny kortdesign",
    "sendoutReceived": "Utskick mottaget",
    "salesAmount": "Försäljning (kronor)",
    "sendoutHistory": "Meddelandehistorik",
    "saturday": "Lördag",
    "select": "Välj",
    "stopSharingOutNewCards": "Stoppa utdelning av nya kort",
    "seeMenu": "Se menyn",
    "skip": "Hoppa över",
    "selectMemberToCheckout": "Välj medlem att check ut",
    "selectedMembers": "Utvalda medlemmar",
    "selectMemberWho": "Välj medlemmar som",
    "sentAs": "Skickat som",
    "searchUsing": "Sök med",
    "showHistory": "Visa historik",
    "selectedImageIsSmallerThanDesiredBannerSize": "Vald bild är mindre än önskad storlek",
    "selectAll": "Markera alla",
    "seeCampaignSelection": "Visa kampanjens urval",
    "start": "START",
    "supportedFileFormat": "Godkänt filformat",
    "subheader": "Undertext",
    "sendout": "Utskick",
    "sendoutDate": "Utskicksdatum",
    "sendoutSelection": "Urval för utskick",
    "sendout-form-page-2.defineTheTextForSMS": "Bestäm vad som ska stå i ditt SMS-utskick. Var försiktig så du inte" +
        " ändrar länken, då blir det tokigt.",
    "sendout-form-page-one.noMembersMatchingCriteriaText": "Det finns ingen medlem som matchar dessa kriterier.",
    "sendout-form-page-three.thereIsNoPotentialReceivers": "Det finns inga potentiella mottagare",
    "sendout-form-page-three.yourMessageWillBeSendTo": "Ditt meddelande kommer skickas till {receiversNumber}" +
        " medlemmar, inkluderat {smsReceiversNumber} SMS och {emailReceiversNumber} mail mottagare.",
    "sendout-form.smsPlaceholderText": "Hej, besök medlemssidan för att läsa mer om",
    "sendout-form-page-three.totalCreditsFor": "Totalt Credits för SMS: {smsReceiversNumber} Credits.",
    "setMembershipConditions": "Ställ in medlemsvillkor",
    "setPrivacyPolicy": "Ställ in Personuppgiftspolicy",
    "setAsHomeLocation": "Ange som Hemmabutik",
    "signIn": "Logga in",
    "signUp": "Registrera",
    "sport": "Sport",
    "smartCampaignCreation": "Smart Kampanjverktyg",
    "smartClubCard": "Smart Klubbkort",
    "smartCommunication": "Smart kommunikation",
    "smartCommunicationFull": "Smart medlemsanslutning (SMS*+E-post)",
    "smartSelection": "Smart Urval",
    "smsOrEmail": "SMS eller E-post",
    "sms-credits-settings-form.autoCharge": "Fyll på mitt SMS-saldo automatiskt när det är för lågt.",
    "sms-credits-settings-form.maxCreditsPerMonth": "Max SMS-krediter per månad",
    "sms-service.needToHaveACreditCardText": "För att aktivera SMS-funktionen behöver du ha ett betalkort registrerat som betalmetod.",
    "sms-service.whenYouActivatePart1": "När du aktiverar bjuder vi på",
    "sms-service.whenYouActivatePart2": "{freeSMS} gratis SMS-krediter",
    "sms-service.whenYouActivatePart3": "så du kan testa hur smidigt det är med SMS!",
    "sms-service.youChoose": "Du väljer sedan själv om du vill ladda på fler SMS-krediter.",
    "sms-service.whenYourCardIsVerified": "När ditt kort verifieras görs en tillfällig reservation på {cardVerifiedCost} SEK som släpps senast efter 7 dagar.",
    "sms-service.smsLotOfValueText": "SMS är ett mycket effektivt sätt att kommunicera med dina kunder!",
    "smsMessage": "SMS-meddelande",
    "smsService": "SMS-tjänst",
    "smsSenderName": "SMS-avsändarens namn",
    "smsServiceNotActivated": "SMS-tjänst ej aktiverad",
    "smsServiceActivated": "SMS-tjänst aktiverad",
    "smsServicePricing": "Pris SMS-tjänst",
    "smsUsage": "SMS-användning",
    "start-guide.CompleteStepsText": "Följ stegen nedan så är du igång!",
    "start-guide.WeHavePreparedText": "Vi har förberett - för att göra det så enkelt som möjligt för dig!",
    "start-guide.defineYourWelcomeOffer": "Skapa ditt Välkomsterbjudande",
    "start-guide.greatStuffText": "Snyggt jobbat! Nu kan du fortsätta bygga på relationen med dina kära kunder digitalt!",
    "startDate": "Startdatum",
    "subdomain": "webbadress",
    "submit": "Bekräfta",
    "subtitle": "Underrubrik",
    "sum": "Summa",
    "support": "Support",
    "stopSharingStampcard": "Stoppa ny utdelning",
    "summer": "Sommar",
    "sunday": "Söndag",
    "startDateMembership": "Startdatum medlemskap",
    "stopped": "Stoppat",
    "stampcard": "Stämpelkort",
    "stampcardInfo": "Kortinformation",
    "stampcardFormPage2.longText": "Om kunden inom {monthValidation} månader från första stämpeln inte samlat tillräckligt många stämplar för nå belöningen så nollställs stämpelkortet.\n" +
        "Om stämpelkortet fortfarande är aktivt kan kunden börja om att samla stämplar på ett nytt stämpelkort.",
    "stampcardIsValidFor": "Stämpelkortet är giltigt i",
    "stampCardWillBeValidFor": "Stämpelkortet är giltigt i {stampcardValidationDate} månader fr.o.m. datumet för din första stämpel.",
    "stampcardCouponFormPage1.example": "”1 gratis kaffe/lunch/klippning”, ”50% rabatt på X”, eller ”Bonuscheck: 100 kr”",
    "rewardCoupon": "Belöningskupong",
    "stampCardStatus": "Stämpelkortets status",
    "stampcardWillContinue": "Klippkortet kommer att fortsätta visas och kunna användas för medlemmar som redan påbörjat att samla stämplar till dess att kortet löper ut.",
    "stampcardDesign": "Stämpelkortets design",
    "stampcardFallDue": "Kortet löper ut {monthValidation} månader efter att de fick den första stämpeln, eller när de har fyllt kortet med stämplar och får en sista belöning.",
    "stampcardIsValidForMonths": "Stämpelkortet är giltig i {monthValidation} månader",
    "stamp-card.noThankYou": "Nej tack, medlemsvillkoren är redan" +
        " anpassade för stämpelkort",
    "stamp-card.formulateYourTerms": "Formulera villkoren för stämpelkort att lägga till i medlemsvillkoren.",
    "stampcardFormPage4.example": "”Stämpel fås vid köp av X. Samla {stampsAmount} stämplar så bjuder vi på nästa X.”",
    "stampcardFormPage4.longText": "Du kan lägga till en underrubrik som t.ex. beskriver när stämpelkortet gäller och eventuella begränsningar för belöningen.",
    "stampcardFormPage3.example": "”Var {stampsAmountPlusOne}:e vara/tjänst X gratis”, ”Samla {stampsAmount} stämplar och få X ” eller helt enkelt ”Stämpelkort”",
    "stampcardFormPage1.longText": "Om du som belöning ger bort eller rabatterar samma vara/tjänst  som kunden köper för att få en stämpel." +
        " Då innebär belöning efter {stampsAmount} stämplar att",
    "stampCardsAreAppreciatedWayOfCreatingAndMaintainingRecurringAndProfitableCustomers": "Stämpelkort är ett uppskattat sätt att skapa och behålla återkommande och lönsamma kunder.",
    "template": "mall",
    "stampcardWillBeRemoved": "Klippkortet kommer tas bort för alla medlemmar, även för de som redan påbörjat att samla stämplar.",
    "stampsCanBeAddedInNewPurchase": "Stämpel kan läggs tilli nytt köp.",
    "tailorTerms": "Skäddarsy villkor",
    "taste": "Smak",
    "to": "Till",
    "termsForStampCard": "Villkor för stämpelkort",
    "thankYouForYourRegistration": "Tack för din registrering!",
    "thisFillWillBe": "Denna fil kommer vara",
    "toContinueYouHaveToCropBackgroundProperly": "För att fortsätta måste du skala bakgrunden rätt",
    "terminateAccount": "Avsluta medlemskap",
    "tradedPoints": "Inlösta Poäng",
    "termsAndConditions": "Villkor",
    "termsOfService": "Villkor för tjänsten",
    "termsOfTheMembership": "Medlemsvillkor",
    "termsForPoints": "Villkor för poäng",
    "till": "till",
    "title": "Titel",
    "total": "Totalt",
    "totalAmountOfMembers": "Totalt antal medlemmar",
    "times": "gånger",
    "typeEmailOrPhone": "Skriv in e-postadress eller mobilnummer",
    "typeEmail": "Skriv in e-postadress",
    "totalAmountPaid": "Totalt betalat belopp",
    "thereAutomaticallyPurchaseText": "köp därefter automatiskt nödvändigt antal krediter när saldot är för lågt.",
    "tuesday": "Tisdag",
    "thursday": "Torsdag",
    "thisIsHowItsDisplayedToYourMembers": "Så visas det för dina medlemmar",
    "totalAmountOfSale": "Försäljning",
    "toBeUsedWithin": "Måste användas inom",
    "type": "Typ",
    "theCustomerReceivesAStampDirectlyOnTheMobilePhone": "Kunden får stämpel direkt i mobilen",
    "update": "Uppdatera",
    "usages": "Använd",
    "updateSendoutDates": "Uppdatera utskicksdatum",
    "unsubscribe": "Avregistrera",
    "undo": "Ångra",
    "upload": "ladda upp",
    "uploadYourOwnImage": "Ladda upp egen bild",
    "use": "använd",
    "usefulLinks": "Nyttiga länkar",
    "users": "Användare",
    "userRole": "Användarens roll",
    "user": "Användare",
    "usedAnyOfTheseCampaigns": "Har använt någon av dessa kampanjer",
    "usedAllOfTheseCampaigns": "Har använt samtliga av dessa kampanjer",
    "notUsedAnyOfTheseCampaigns": "Inte har använt någon av dessa kampanjer",
    "notUsedAllOfTheseCampaigns": "Inte har använt samtliga av dessa kampanjer",
    "visitingAddress": "Besöksadress",
    "visibleToEachMember": "Kampanjen är synlig för alla medlemmar",
    "validToDate": "Giltig till {date}",
    "validTo": "Giltig t.o.m.",
    "valid": "(giltiga {pointsValidationDate})",
    "validityPeriod": "Giltighetstid",
    "warning": "Varning",
    "weHaveNowAddedSms": "Vi har nu laddat på ditt konto med {freeSMS} SMS-krediter helt gratis!",
    "whatDoesCustomerGetForReward": "Vad får kunden för belöning?",
    "whatKindOfPurchaseHistoryYouWantToUse": "Vilken typ av köphistorik du vill använda för ditt urval",
    "wasBorn": "Har födelsedagsdatum enligt nedan",
    "wasNotBorn": "Inte har födelsedagsdatum enligt nedan",
    "website": "Hemsida",
    "webAddressToLink": "Länkens webbadress",
    "welcomeOffering": "Välkomsterbjudande",
    "wednesday": "Onsdag",
    "withdrawTheCard": "Återkalla kortet",
    "withdrawn": "Återkallat",
    "whenVisible": "Synlig när",
    "yes": "Ja",
    "youHaveSelectedMembersWho": "Du har valt medlemmar som",
    "year": "År",
    "yearLowercase": "år",
    "youHaveToBuyCreditsToUseSmsSendout": "Ladda på SMS-krediter för att göra SMS-utskick",
    "youHaveCardsInStatus": "Du har andra klippkort med status ”Stoppad utdelning av ny kort”.",
    "youCanAlsoDragAndDropYourOwnImageText": "Du kan även dra och släppa en egen bild här.",
    "youCanSkip": "Du kan hoppa över detta samt ändra senare.",
    "your": "din",
    "withdrawStampcard": "Återkalla kortet",
    "yourEmail": "din e-post",
    "yourPhone": "ditt telefon",
    "yourEmailAddress": "Din e-postadress",
    "yourPhoneNumber": "Ditt telefonnumer",
    "zipCode": "Postnummer",
    "passwordResetSuccess": "Meddelande med länk för återställning av lösenord har skickats. Öppna meddelandet och klicka på länken för återställa ditt lösenord.",
    "passwordResetError": "Inget meddelande för återställning av lösenord har skickats.",
    "selectedAgeBetweenAnd": "Just nu: ålder mellan {from} och {to}",
    "pleaseWaitRegistrationIsProcessed": "Vänligen vänta, registreringen behandlas",
    "dontShowMeThisMessageAgain": "Visa inte detta meddelande igen",
    "addToHomeScreenTitle": "Lägg till bland appar i telefonen!",
    "addToHomeScreenSafariContent": "Klicka på {shareIcon} och välj sedan {addIcon}",
    "addToHomeScreenSamsungContent": "Öppna meny {menuIcon} och välj sedan {addIcon}",
    "purchaseUpdateTitle": "Köp - {date}",
    "firstRegistration": "Första registrering",
    "updateDate": "Uppdatering",
    "stamps": "stämplar",
    "usedCampaign": "Nyttjad kampanj",
    "reviewPurchaseUpdates": "Granska uppdateringar för detta köp",
    "hideUpdates": "Dölj uppdateringar",
    "couponPoints": "poäng",
    "currentPointsBalanceMadeChanges": "Aktuellt poängsaldo: {currentBalance} (gjorda ändringar: {madeChanges})",
    "changePointsSeeDetailedHistory": "Se detaljerad  historik",
    "changeEarnedPointsDialogTitle": "Ändra intjänade poäng för detta köp",
    "usedPoints": "Förbrukade",
    "usedPointsCantBeDeducted": "Du kan inte ta bort förbrukade poäng.",
    "thusPointsEarnedForThisPurchase": "Intjänade poäng kan därför {cantBeSetLessThan}",
    "cantBeSetLessThan": "inte vara minde {minAcceptablePoints} poäng för detta köp.",
    "stampcards": "Stämpelkort",
    "collectedStamps": "Stämplar totalt",
    "addedStamps:": "På detta köp",
    "stampcardsRewards": "Stämpelkortskuponger",
    "adjustmentPointsUpdate": "Justering",
    "earnedPointsUpdate": "Intjänade",
    "usedPointsUpdate": "Förbrukade",
    "returnedPointsUpdate": "Återförda",
    "eventPointsUpdate": "Händelse",
    "amountPointsUpdate": "Summa",
    "whenPointsUpdate": "När",
    "showPurchaseHistory": "Visa köphistorik",
    "pointsAmount": "poäng",
    "validUntil": "Giltiga t.o.m.",
    "redeemedPoints": "Inlösta poäng",
    "whereofUsed": "varav förbrukade",
    "whereofFallenDue": "varav förfallna",
    "usedBy": "Förbrukade när",
    "totalAmountPaidPurchaseHistory": "Totalt betalt belopp",
    "purchaseHistory": "Köphistorik",
    "pointsFallDueDate": "Poäng förfaller {date}",
    "tradeForPointsAmount": "Lös in för {pointsAmount} poäng",
    "changePurchaseDateDialogTitle": "Efterregistrera köp",
    "changePurchaseDateDialogSubtitle": "Ange datum för att efterregistrera köp",
    "time": "Tidpunkt",
    "registerPurchaseMade": "Efterregistrera köp",
    "thisCardDoesNotShowForMembersWithBelowCardActive": "Detta kort visas inte för medlemmar som har nedan stämpelkort aktivt",
    "areYouSureStampcardUncheckTitle": "Är du säker?",
    "areYouSureStampcardUncheckSubtitle": "Är du säker att du publicera detta kort även för medlemmar som har börjat samla stämplar men ännu inte slutfört kortet ”{title}”?",
    "areYouSureStampcardUncheckInfo": "De båda korten kommer att visas samtidigt.",
    "areYouSureStampcardUncheckWarning": "OBS ! Åtgärden går inte att ångra!",
    "historic": "Historik"
}
