import {
    FETCH_MAX_POINTS_BALANCE,
    FETCH_MERCHANT_COMMERCIAL_CHAIN,
    FETCH_MERCHANT_COMMERCIAL_CHAINS,
    UPDATE_COMMERCIAL_CHAIN
} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case UPDATE_COMMERCIAL_CHAIN:
            return {...state, updateCommercialChain: action.payload};
        case FETCH_MERCHANT_COMMERCIAL_CHAINS:
            return {...state, commercialChains: action.payload};
        case FETCH_MERCHANT_COMMERCIAL_CHAIN:
            return {...state, current: action.payload};
        case FETCH_MAX_POINTS_BALANCE:
            return {...state, maxPointsBalance: action.payload};
    }

    return state;
}
