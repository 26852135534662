import React from "react";

import {makeStyles} from "@material-ui/styles";
import Select from '@material-ui/core/Select'
import MenuItem from "@material-ui/core/MenuItem";

import {COUNTRY_CODES} from "../../../utils/country-codes";

const useStyles = makeStyles((theme => ({
    root: {
        marginRight: theme.spacing(2)
    },
    selectField: {
        width: 145
    }
})));

const CountrySelect = props => {
    const {input: {value, onChange}, onSelect, initialCountry, ...rest} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Select
                {...rest}
                onChange={(event) => {
                    onChange(event.target.value);
                    onSelect(event.target.value)
                }}
                value={value && value.includes("+")
                    ? value.slice(1).toString()
                    : value ? value : initialCountry}
                className={classes.selectField}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    }
                }}>
                {Object.values(COUNTRY_CODES).map(v =>
                    <MenuItem
                        key={v.code}
                        value={v.code}>
                        {v.name} (+{v.code})
                    </MenuItem>
                )}
            </Select>
        </div>
    )
};

export default CountrySelect
