import React from "react";
import {Field, formValueSelector} from "redux-form";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";

import RenderTextField from "../RenderTextField";
import CountrySelect from "./CountrySelect";
import {setFormValue} from "../../../../merchant/actions/merchant-forms-actions";
import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../../../merchant/utils/formats";
import {DEFAULT_COUNTRY_CODE} from "../../../utils/country-codes";

const PHONE_PREFIX_FIELD_NAME = 'phonePrefix';

const styles = {
    root: {
        display: 'flex'
    },
    countrySelectField: {
        display: 'flex',
        alignItems: 'flex-end',
        width: 'auto',
        height: 55
    },
    phoneSelectField: {
        width: '90%'
    }
};

class PhoneInput extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        initialValues: PropTypes.object,
        form: PropTypes.string,
        defaultCountryCode: PropTypes.string,
        phonePrefix: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        setFormValue: PropTypes.func
    };

    state = {
        initialCountry: null
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const {initialValues: nextInitialValues, phonePrefix, defaultCountryCode, setFormValue, form} = nextProps;
        const {initialValues: prevInitialValues} = prevState;

        if ((nextInitialValues !== prevInitialValues)
            && nextInitialValues
            && nextInitialValues.phonePrefix) {
            return {
                initialCountry: nextInitialValues.phonePrefix.slice(1).toString()
            }
        } else if (!phonePrefix) {
            let initialCountry = defaultCountryCode ? defaultCountryCode : DEFAULT_COUNTRY_CODE;
            const countryCodeWithPrefix = '+' + initialCountry;
            setFormValue(form, PHONE_PREFIX_FIELD_NAME, countryCodeWithPrefix);
            return {
                initialCountry: initialCountry
            }
        }
        return null
    }

    render() {
        const {classes, onChange, disabled} = this.props;
        const {initialCountry} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.countrySelectField}>
                    <Field
                        disabled={disabled}
                        name={PHONE_PREFIX_FIELD_NAME}
                        type="text"
                        component={CountrySelect}
                        onSelect={this.setCountryCodeOnForm.bind(this)}
                        initialCountry={initialCountry}/>
                </div>

                <div className={classes.phoneSelectField}>
                    <Field
                        disabled={disabled}
                        name="phone"
                        type="number"
                        formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                        inputMode="numeric"
                        component={RenderTextField}
                        onChange={onChange}
                        hintText={<FormattedMessage
                            id="mobileNumber"
                            defaultMessage="Mobile number"/>}/>
                </div>
            </div>
        )
    }

    setCountryCodeOnForm(countryCode) {
        const {setFormValue, form} = this.props;
        const countryCodeWithPrefix = '+' + countryCode;
        setFormValue(form, PHONE_PREFIX_FIELD_NAME, countryCodeWithPrefix)
    }

}

const mapStateToProps = (state, initialProps) => {
    const selector = formValueSelector(initialProps.form);
    const phonePrefix = selector(state, PHONE_PREFIX_FIELD_NAME);
    return {
        phonePrefix
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PhoneInput));
