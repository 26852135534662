import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Spinner from "../Spinner";
import StampcardCouponPage from "./StampcardCouponPage";
import Container from "../Container";

import {fetchStampcardCoupon} from "../../../../kundklubb/actions/consumer-actions";
import {fetchUserDetails} from "../../../actions/user-actions";

// TODO remove ?
class StampcardCouponContainer extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        match: PropTypes.object,
        stampcardCoupon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        fetchStampcardCoupon: PropTypes.func,
        fetchUserDetails: PropTypes.func
    };

    componentDidMount() {
        const stampcardCouponId = this.props.match.params.stampcardCouponId;
        this.props.fetchStampcardCoupon(stampcardCouponId);
        this.props.fetchUserDetails();

    }

    render() {
        const {app, stampcardCoupon, user} = this.props;

        if (!stampcardCoupon || !user) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <StampcardCouponPage stampcardCoupon={stampcardCoupon} user={user}/>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        stampcardCoupon: state.consumer.stampcardCoupon,
        user: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchStampcardCoupon, fetchUserDetails}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StampcardCouponContainer);
