import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    wrapper: {
        height: 'inherit',
        minHeight: theme.spacing(3),
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        whiteSpace: 'pre-line',
        border: `1px dashed ${theme.palette.common.black}`,
        borderRadius: theme.shape.borderRadius
    }
});

const DashedWrapper = (props) => {
    const {classes, children} = props;

    return (
        <div className={classes.wrapper}>
            {children}
        </div>
    )

};

DashedWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
};

export default withStyles(styles)(DashedWrapper)
