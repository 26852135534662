import {
    FETCH_CAMPAIGN_WITHOUT_AUTH,
    FETCH_CAMPAIGNS_WITHOUT_AUTH,
    FETCH_CLUB_POINTS_WITHOUT_AUTH,
    FETCH_COMMERCIAL_CHAIN_WITHOUT_AUTH,
    FETCH_COMPANY,
    FETCH_COMPANY_WITHOUT_AUTH,
    FETCH_COUPON_WITHOUT_AUTH,
    FETCH_MEMBERSHIP_CONDITIONS,
    FETCH_POINT_OF_SALE_WITHOUT_AUTH,
    FETCH_PRIVACY_POLICY,
    FETCH_SMS_ACCOUNT_WITHOUT_AUTH,
    FETCH_STAMPCARDS_WITHOUT_AUTH
} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_COMPANY:
            if (!action.payload) {
                return {...state, data: {name: 'Non Existing'}};
            }
            return {...state, data: action.payload};
        case FETCH_COMPANY_WITHOUT_AUTH:

            if (!action.payload) {
                return {...state, data: {name: 'Non Existing'}};
            }
            return {...state, company: action.payload};
        case FETCH_COMMERCIAL_CHAIN_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, commercialChain: {name: 'Non Existing'}};
            }
            return {...state, commercialChain: action.payload};
        case FETCH_CLUB_POINTS_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, clubPoints: {name: 'Non Existing'}};
            }
            return {...state, clubPoints: action.payload};
        case FETCH_POINT_OF_SALE_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, pointOfSale: {name: 'Non Existing'}};
            }
            return {...state, pointOfSale: action.payload};
        case FETCH_CAMPAIGN_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, campaign: {name: 'Non Existing'}};
            }
            return {...state, campaign: action.payload};
        case FETCH_COUPON_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, coupon: {name: 'Non Existing'}};
            }
            return {...state, coupon: action.payload};
        case FETCH_CAMPAIGNS_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, campaigns: {name: 'Non Existing'}};
            }
            return {...state, campaigns: action.payload};
        case FETCH_STAMPCARDS_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, stampcards: {name: 'Non Existing'}};
            }
            return {...state, stampcards: action.payload};
        case FETCH_SMS_ACCOUNT_WITHOUT_AUTH:
            if (!action.payload) {
                return {...state, smsAccount: {name: 'Non Existing'}};
            }
            return {...state, smsAccount: action.payload};
        case FETCH_PRIVACY_POLICY:
            return {...state, policy: action.payload};
        case FETCH_MEMBERSHIP_CONDITIONS:
            return {...state, conditions: action.payload};
    }

    return state;
}
