import React from "react";

const SUBDOMAIN_INDEX = 1;
const CAMPAIGN_ID_INDEX = 2;

export const emailAsLink = (email) => {
    return (
        <a href={`mailto:${email}`}>{email}</a>
    )
};

export function isSubpageInPathname(potentiallySubpage) {
    return potentiallySubpage.length > 0 && potentiallySubpage !== "guest" && potentiallySubpage !== "login" && potentiallySubpage !== "auth";
}

// FIXME
export function isCampaignIdInPathname(potentiallyCampaignId) {
    return potentiallyCampaignId && potentiallyCampaignId.length > 0 && !isNaN(potentiallyCampaignId);
}

export function getPotentiallySubpageFromPathname(pathname) {
    return pathname.split('/')[SUBDOMAIN_INDEX];
}

export function getPotentiallyCampaignIdFromPathname(pathname) {
    return pathname.split('/')[CAMPAIGN_ID_INDEX];
}