import axios from "axios";
import {FETCH_CAMPAIGN_WITHOUT_AUTH, FETCH_COMPANY_WITHOUT_AUTH, FETCH_COUPON_WITHOUT_AUTH,} from "./types";
import {apiError} from "./utils-actions";
import {ROOT_URL} from "../config/karming-links";

const ENDPOINT_URL = `${ROOT_URL}/public`;

export function fetchCompanyWithoutAuth(companyId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/company/${companyId}`)
            .then(response => {
                dispatch({
                    type: FETCH_COMPANY_WITHOUT_AUTH,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch company data', response));
            });
    }
}

export function fetchCampaignWithoutAuth(campaignId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/campaign/${campaignId}`)
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_CAMPAIGN_WITHOUT_AUTH,
                        payload: response.data
                    })
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch campaign', response));
        });
    }
}

export function fetchCouponWithoutAuth(couponId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/coupon/${couponId}`)
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_COUPON_WITHOUT_AUTH,
                        payload: response.data
                    })
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch coupon', response));
        });
    }
}
