import axios from "axios";
import {FETCH_USER, UPDATE_USER} from "./types";
import {apiError, hasToken, headerConfig} from "./utils-actions";
import {logout} from "./auth-actions";
import {ROOT_URL} from "../config/karming-links";

const ENDPOINT_URL = `${ROOT_URL}/user`;

export function fetchUserDetails() {
    return dispatch => {
        if (hasToken()) {
            axios.get(`${ENDPOINT_URL}/profile`, headerConfig())
                .then(response => {
                    dispatch({
                        type: FETCH_USER,
                        payload: response.data
                    });
                })
                .catch(response => {
                    dispatch(logout());
                    dispatch(apiError('Unable to fetch profile', response));
                });
        }
    }
}

export function updateUser(values, history, redirectAfterUpdateUrl) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/profile`, values, headerConfig())
            .then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', response.data.role);
                dispatch({
                    type: UPDATE_USER,
                    payload: response.data
                });
                dispatch(fetchUserDetails());
                if (history) {
                    history.push(redirectAfterUpdateUrl)
                }
            })
            .catch(response => {
                dispatch(apiError('Unable to update profile', response));
            });
    }
}

export function setUserLanguage(language) {
    return dispatch => {
        axios.put(`${ENDPOINT_URL}/language`, {language: language}, headerConfig())
            .then(() => {
                dispatch(fetchUserDetails());
            })
            .catch(response => {
                dispatch(apiError('Unable to update language', response));
            });
    }
}
