import React from "react";
import axios from "axios";
import {AUTH_USER, LOADING, RESET_PASSWORD, SEND_PASSWORD_RESET_MAIL, UNAUTH_USER} from "./types";
import {apiError, apiSuccessMessage, headerConfig} from "./utils-actions";
import {ROOT_URL} from "../config/karming-links";
import {ROLE_CONSUMER} from "../components/auth/roles";
import {fetchCommercialChain} from "../../kundklubb/actions/consumer-actions";
import {FormattedMessage} from "react-intl";

export function login(values, history, redirectAfterLoginUrl) {
    return dispatch => {
        dispatch({type: LOADING});

        const {username, password} = values

        axios.post(`${ROOT_URL}/auth`, {username, password})
            .then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', response.data.role);

                dispatch({
                    type: AUTH_USER,
                    payload: response.data.role
                });

                if (response.data.role === ROLE_CONSUMER) {
                    dispatch(fetchCommercialChain())
                }

                history.push(redirectAfterLoginUrl || '');
            })
            .catch(response => dispatch(apiError('Unable to login', response)));
    };
}

export function logout(currentPoSId) {
    if (currentPoSId) {
        axios.post(`${ROOT_URL}/merchant/locations/last/${currentPoSId}`, null, headerConfig())
            .then()
            .catch(response => dispatch(apiError('Unable to save last used PoS', response)));
    }

    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('currentChainId');
    localStorage.removeItem('currentPoSId');
    localStorage.removeItem('chosenLocation');
    localStorage.clear();

    return {type: UNAUTH_USER};
}

export function sendPasswordResetMessage(subdomain, contactData) {
    return dispatch => {

        axios.post(`${ROOT_URL}/auth/password/request/${subdomain}`, contactData)
            .then(response => {
                dispatch({
                    type: SEND_PASSWORD_RESET_MAIL,
                    payload: response.data
                });
                dispatch(
                    apiSuccessMessage(
                        <FormattedMessage
                            id="passwordResetSuccess"
                            defaultMessage="Password reset message have been successfully sent. Click the link provided in the message to reset your password."/>
                    ))
            })
            .catch(error => {
                if (error.response.status && error.response.status === 500) {
                    dispatch(apiError(
                        <FormattedMessage
                            id="passwordResetFailNoUser"
                            defaultMessage="No password reset message has been sent. There are no users with matching contact information."/>));
                } else {
                    dispatch(apiError('Inget meddelande för återställning av lösenord har skickats.'));
                }
            })
    }
}

export function resetPassword(password, passwordResetId, history) {
    return dispatch => {
        axios.post(`${ROOT_URL}/auth/password/reset/${passwordResetId}`, password)
            .then(response => {
                dispatch({
                    type: RESET_PASSWORD,
                    payload: response.data
                });
                dispatch(apiSuccessMessage(
                    <FormattedMessage
                        id="passwordChanged"
                        defaultMessage="Password changed"/>
                ));
                history.push('/')
            })
            .catch(response => dispatch(apiError('Unable to reset password', response)));
    }
}


