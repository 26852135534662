export const FLOAT_FORMAT_CONFIG = {
    // thousandSeparator: " " // FIXME enabling this option causes invalid integer format onBlur within forms
};

export const UNSIGNED_FLOAT_FORMAT_CONFIG = {
    ...FLOAT_FORMAT_CONFIG,
    allowNegative: false
};

export const INTEGER_FORMAT_CONFIG = {
    ...FLOAT_FORMAT_CONFIG,
    decimalScale: 0
};

export const UNSIGNED_INTEGER_FORMAT_CONFIG = {
    ...INTEGER_FORMAT_CONFIG,
    allowNegative: false
};